import React from 'react';
import Info from '@material-ui/icons/Info';
import { useIntl } from 'react-intl';
import Pagination from './Pagination';
import messages from './messages';

const TableWrapper = ({
  children,
  data,
  params,
  setCurrentPage,
  meta,
  isInline,
  isVisiblePagination,
  type
}) => {
  const length = data.length;
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="table">
        {length ? (
          children
        ) : (
          <p className="no_result">
            <Info /> {formatMessage(messages.table_no_result)}
          </p>
        )}
      </div>
      {isVisiblePagination &&
        (length && meta ? (
          <Pagination
            type={type}
            params={params}
            setCurrentPage={setCurrentPage}
            meta={meta && meta}
            isInline={isInline}
          />
        ) : null)}
    </>
  );
};

export default TableWrapper;
