import types from './constants';

// Get
export const getRecommendedPrices = () => ({
  type: types.GET_RECOMMENDED_PRICES
});

export const setRecommendedPrices = payload => ({
  type: types.SET_RECOMMENDED_PRICES,
  payload
});

// Get Single
export const getRecommendedPrice = payload => ({
  type: types.GET_RECOMMENDED_PRICE,
  payload
});

export const setRecommendedPrice = payload => ({
  type: types.SET_RECOMMENDED_PRICE,
  payload
});

export const clearRecommendedPrice = () => ({
  type: types.CLEAR_RECOMMENDED_PRICES
});

export const getRecommendedPriceError = error => ({
  type: types.GET_RECOMMENDED_PRICE_ERROR,
  error
});

// Add
export const addRecommendedPrice = (payload, setErrors) => ({
  type: types.ADD_RECOMMENDED_PRICE_REQUEST,
  payload,
  meta: { setErrors }
});

export const addRecommendedPriceSuccess = () => ({
  type: types.ADD_RECOMMENDED_PRICE_SUCCESS
});

export const addRecommendedPriceError = () => ({
  type: types.ADD_RECOMMENDED_PRICE_ERROR
});

// Update
export const updateRecommendedPrice = (id, payload, setErrors) => ({
  type: types.UPDATE_RECOMMENDED_PRICE_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateRecommendedPriceSuccess = () => ({
  type: types.UPDATE_RECOMMENDED_PRICE_SUCCESS
});

export const updateRecommendedPriceError = () => ({
  type: types.UPDATE_RECOMMENDED_PRICE_ERROR
});

// Delete
export const deleteRecommendedPrice = id => ({
  type: types.DELETE_RECOMMENDED_PRICE_REQUEST,
  id
});

export const deleteRecommendedPriceSuccess = () => ({
  type: types.DELETE_RECOMMENDED_PRICE_SUCCESS
});

// Params
export const setCurrentPage = payload => ({
  type: types.SET_CURRENT_PAGE,
  payload
});

export const setPerPage = payload => ({
  type: types.SET_PER_PAGE,
  payload
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});
