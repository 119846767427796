import React from 'react';

import Menu from './Menu';
import MiniNav from './MiniNav';
import { activeClass } from 'helpers/activeClass';
import { Link } from 'react-router-dom';

const BottomBar = ({ scrolled, categories }) => (
  <div className={activeClass('bottom_nav', scrolled)}>
    <div className="container">
      <div className="bottom_nav_content">
        <div className="left_side">
          <Menu categories={categories} />
          <MiniNav categories={categories} />
          <Link className="right_link" to="#">
            Pomoć i saveti
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default BottomBar;
