import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectApp = state => state.app || initialState;

const makeSelectUser = () =>
  createSelector(selectApp, substate => substate.user);

const makeSelectAddresses = () =>
  createSelector(selectApp, substate => substate.addresses);

const makeSelectOrders = () =>
  createSelector(selectApp, substate => substate.orders);

const makeSelectIsAuthenticated = () =>
  createSelector(selectApp, substate => !!substate.token);

const makeSelectError = () =>
  createSelector(selectApp, substate => substate.error);

const makeSelectToken = () =>
  createSelector(selectApp, substate => substate.token);

const makeSelectModules = () =>
  createSelector(selectApp, substate => substate.modules);

const makeSelectBanners = () =>
  createSelector(selectApp, substate => substate.banners);

const makeSelectCartCookie = () =>
  createSelector(selectApp, substate => substate.cart_cookie);

const makeSelectWishListCookie = () =>
  createSelector(selectApp, substate => substate.wish_list_cookie);

export {
  makeSelectUser,
  makeSelectAddresses,
  makeSelectOrders,
  makeSelectError,
  makeSelectIsAuthenticated,
  makeSelectToken,
  makeSelectModules,
  makeSelectBanners,
  makeSelectCartCookie,
  makeSelectWishListCookie
};
