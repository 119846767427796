import React from 'react';

const Quantity = ({ quantity, setQuantity }) => {
  const handleDecrement = () => quantity > 1 && setQuantity(quantity - 1);
  const handleIncrement = () => setQuantity(quantity + 1);

  return (
    <div className="quantity_row">
      <p>Količina:</p>
      <div className="quantity">
        <div className="button" onClick={handleDecrement}>
          -
        </div>
        <div className="selected">{quantity}</div>
        <div className="button" onClick={handleIncrement}>
          +
        </div>
      </div>
    </div>
  );
};

export default Quantity;
