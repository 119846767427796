import types from './constants';

export const getData = payload => ({
  type: types.GET_DATA,
  payload
});

export const getCategories = payload => ({
  type: types.GET_CATEGORIES,
  payload
});

export const setData = payload => ({
  type: types.SET_DATA,
  payload
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const addData = (payload, setErrors, resetForm) => ({
  type: types.ADD_DATA_REQUEST,
  payload,
  meta: { setErrors, resetForm }
});

export const addDataSuccess = () => ({
  type: types.ADD_DATA_SUCCESS
});

export const addDataError = () => ({
  type: types.ADD_DATA_ERROR
});

export const updateData = (id, payload) => ({
  type: types.UPDATE_DATA_REQUEST,
  id,
  payload
});

export const setActive = (id, payload) => ({
  type: types.SET_ACTIVE,
  id,
  payload
});

export const updateDataSuccess = () => ({
  type: types.UPDATE_DATA_SUCCESS
});

export const updateDataError = () => ({
  type: types.UPDATE_DATA_ERROR
});
