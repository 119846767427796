import React from 'react';
import { Form, Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Photo from 'assets/images/chatbubbles-outline.png';

import { validationSchema } from './validations';
import { askQuestion } from '../redux/actions';
import FormikError from 'components/FormikError';
import { OpenAccount } from '../Details/styles';

const AskForm = ({ slug }) => {
  const dispatch = useDispatch();

  return (
    <div className="ask_form">
      <Formik
        initialValues={{ name: '', question: '' }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) =>
          dispatch(askQuestion(slug, values, resetForm))
        }
      >
        {({ errors, touched }) => (
          <Form>
            <div className="field">
              <label>Ime</label>
              <Field placeholder="Vaše ime" type="text" name="name" />
            </div>
            <FormikError name="name" errors={errors} touched={touched} />
            <div className="field">
              <label>Pitanje</label>
              <Field
                component="textarea"
                placeholder="Napišite piranje..."
                name="question"
              />
            </div>
            <FormikError name="question" errors={errors} touched={touched} />
            <OpenAccount type="submit">Pošalji pitanje</OpenAccount>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AskForm;
