import React, { useState, useEffect } from 'react';
import Categories from './Categories';
import Suppliers from './Suppliers';
import BrandsSelect from './Brands';
import Buttons from './Buttons';

const FiltersSideBar = ({
  close,
  params,
  setParams,
  categoriesSelectVisible,
  setCategoriesSelectVisible,
  setSelectCategoryId
}) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    if (params) setValues(params);
  }, [params]);

  const handleSubmit = e => {
    e.preventDefault();
    setParams(values);
    close();
  };

  return (
    <div className="filters_slide_bar_wrapper" onClick={close}>
      <div
        className="filters_slide_bar_holder"
        style={{ width: '600px', overflow: 'inherit' }}
        onClick={e => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="dropdowns_row">
              <Categories
                params={values}
                setParams={setValues}
                isVisible={categoriesSelectVisible}
                setIsVisible={setCategoriesSelectVisible}
                satValue={setSelectCategoryId}
              />
              <br />
              <br />
              <Suppliers params={values} setParams={setValues} />
              <br />
              <br />
              <BrandsSelect params={values} setParams={setValues} />
              <br />
            </div>

            <br />
            <Buttons
              setValues={setValues}
              setParams={setParams}
              close={close}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FiltersSideBar;
