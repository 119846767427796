import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import Breadcrumb from 'components/Breadcrumb';

const CategoriesBreadcrumb = ({ name, categories }) => (
  <>
    <Helmet>
      <title>{name} | Jakov – Vaš dom</title>
      <meta property="og:url" content={window.location.href} />
    </Helmet>
    <Breadcrumb active={name}>
      {categories &&
        categories.length &&
        categories.map(item => (
          <div key={item.id} className="breadcrumb_item">
            <Link to={`/kategorije/${item.slug}`}>
              <h3>
                {item.name} <span>{`  /  `}</span>
              </h3>
            </Link>
          </div>
        ))}
    </Breadcrumb>
  </>
);

export default CategoriesBreadcrumb;
