import React from 'react';
import General from './General';
import Contact from './Contact';
import UsefulLinks from './UsefulLinks';
import Newsletter from './Newsletter';
import BottomSide from './BottomSide';

const Footer = () => (
  <footer id="footer">
    <div className="container">
      <div className="footer_top_side">
        <General />
        <Contact />
        <UsefulLinks />
        <Newsletter />
      </div>
    </div>
    <div className="footer_bottom_side">
      <BottomSide />
    </div>
  </footer>
);

export default Footer;
