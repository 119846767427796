/* eslint-disable require-yield */
import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import config from '../../../config';
import {
  getData,
  getCategories,
  setData,
  setCategories,
  addDataSuccess,
  addDataError,
  updateDataSuccess,
  updateDataError
} from './actions';
import types from './constants';

const baseURL = config.api.baseUrl.replace('/api/v1/', '/api/');

export function* fetchData() {
  try {
    const res = yield call(request, {
      url: baseURL + 'api-clients',
      method: 'get'
    });
    yield put(setData(res.data));
  } catch (error) {}
}
export function* fetchCategories() {
  try {
    const res = yield call(request, {
      url: baseURL + 'api-client-categories',
      method: 'get'
    });
    let result = [];
    for (const i in res) {
      result.push(res[i]);
    }
    yield put(
      setCategories(
        Array.from(new Set(result.map(JSON.stringify))).map(JSON.parse)
      )
    );
  } catch (error) {}
}

export function* addData({ payload }) {
  console.log(payload);

  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    const categories = Object.entries(payload.categories);

    categories.forEach((category, index) => {
      console.log(index);
      formData.append(`categories[${index}][id]`, category[0]);
      formData.append(`categories[${index}][percentage]`, category[1]);
    });

    const res = yield call(request, {
      url: baseURL + 'api-clients',
      method: 'post',
      data: formData
    });
    console.log(res);
    yield put(getData());
  } catch (error) {
    console.log(error);
  }
}

export function* updateData({ id, payload }) {
  try {
    const data = [];
    const categories = Object.entries(payload.categories);

    categories.forEach((category, index) => {
      console.log(index);
      if (!(category[1] == null) && category[1] !== 0 && category[1] !== '') {
        data.push({
          id: category[0],
          percentage: category[1]
        });
      }
    });

    console.log(data);

    const res = yield call(request, {
      url: baseURL + 'api-clients/' + id,
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        categories: data
      })
    });
    console.log(res);
    yield put(getData());
    yield put(enqueueSnackbar({ message: res.message }));
  } catch (error) {
    console.log(error);
  }
}

export function* setActive({ id, payload }) {
  let active = payload ? 0 : 1;
  console.log(active);
  try {
    const res = yield call(request, {
      url: baseURL + 'api-client-status/' + id,
      method: 'patch',
      params: {
        active: active
      }
    });

    yield put(enqueueSnackbar({ message: res.message }));

    yield put(getData());
  } catch (error) {
    console.log(error);
  }
}

export default function* apiClientTypesSaga() {
  yield takeLatest(types.GET_DATA, fetchData);
  yield takeLatest(types.GET_CATEGORIES, fetchCategories);
  yield takeLatest(types.ADD_DATA_REQUEST, addData);
  yield takeLatest(types.UPDATE_DATA_REQUEST, updateData);
  yield takeLatest(types.SET_ACTIVE, setActive);
}
