import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { fetchDiscountsTypes } from './redux/actions';
import { makeSelectDiscountsTypes } from './redux/selectors';
import messages from './messages';
import { searched } from 'helpers/search';
import useUserKeyDown from 'helpers/useUserKeyDown';

const key = 'selects';
const name = 'discount_type_id';

const DiscountsTypesSelect = ({ value, setValue, errors, touched }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const results = useSelector(makeSelectDiscountsTypes());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && value && results.length) {
      let activeItem = results.map(item => item.id).indexOf(value.id);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, value]);

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, data], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(data.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, data], () => {
    if (isVisible) {
      if (activeIndex < data.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, data], () => {
    if (isVisible && data.length) handleClick(data[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchDiscountsTypes());
  }, [isVisible, dispatch]);

  useEffect(() => {
    if (results.length) setData(results);
  }, [results]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    setData(searched(e, results, 'name'));
    setActiveIndex(0);
  };

  const handleClick = item => {
    setValue(item);
    toggle();
  };

  const handleClear = () => {
    setValue(null);
  };

  return (
    <div className={activeClass('search_select_wrapper', isVisible)}>
      <div className="search_select_holder">
        <div className="root" onClick={toggle}>
          <p>
            {value ? (
              <span>
                {value.name}
                <FaTimes onClick={handleClear} />
              </span>
            ) : (
              formatMessage(messages.choose_action_type)
            )}
          </p>
          <span className="icon">
            <FaSortDown />
          </span>
        </div>
        <label>{formatMessage(messages.action_type)}</label>
        {isVisible && (
          <div className="dropdown_holder" ref={modal}>
            <div className="dropdown_content">
              <input
                type="text"
                placeholder={formatMessage(messages.search_placeholder)}
                onChange={handleChange}
                autoFocus
              />
              {data.length ? (
                <ul>
                  {data.map((item, index) => (
                    <li
                      key={item.id}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() => setActiveIndex(index)}
                      className={activeClass('', activeIndex === index)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no_result">{formatMessage(messages.no_result)}</p>
              )}
            </div>
          </div>
        )}
      </div>
      {errors[name] && touched[name] && (
        <p className="error_msg">
          {typeof errors[name] === 'object'
            ? formatMessage(errors[name])
            : errors[name]}
        </p>
      )}
    </div>
  );
};

export default DiscountsTypesSelect;
