import React from 'react';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import messages from './messages';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
const headerItems = [
  messages.name,
  messages.connected_products,
  messages.unconnected_products,
  messages.updated_at
];

const Table = ({ data }) => {
  const history = useHistory();
  const handleOnClick = async (type, item) => {
    // let response = null;
    // if (type === 'connected') response = await API.connectedSuppliers(item.id);
    // else response = await API.unconnectedSuppliers(item.id);
    // if (response.data && response.data !== '\n') {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute(
    //     'download',
    //     `${item.name}-${moment(new Date()).format('DDMMYYYY')}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // }
    history.push(
      `/admin-panel/naši-dobavljači/${item.id}?type=${
        type === 'connected' ? 'povezani' : 'nepovezani'
      }`
    );
  };
  return (
    <TableHelper tableName="ourSuppliers" tableHeaders={headerItems}>
      {data.map(item => (
        <TableRow key={item.id}>
          <TableCell>{item.name}</TableCell>
          <TableCell>
            <Button onClick={() => handleOnClick('connected', item)}>
              {item.connected_products}
            </Button>
          </TableCell>
          <TableCell>
            <Button onClick={() => handleOnClick('unconnected', item)}>
              {item.unconnected_products}
            </Button>
          </TableCell>
          <TableCell>
            {moment(item.updated_at).format('DD.MM.YYYY HH:mm')}
          </TableCell>
        </TableRow>
      ))}
    </TableHelper>
  );
};

export default Table;
