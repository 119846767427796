import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';

import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/Products/redux/reducer';
import saga from 'cms/Products/redux/saga';
import { getProducts } from 'cms/Products/redux/actions';
import { makeSelectProducts } from 'cms/Products/redux/selectors';
import messages from '../messages';

const key = 'products';

const ProductsDropdown = ({ value, setValue }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [delay, setDelay] = useState('');
  const results = useSelector(makeSelectProducts());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data } = results;

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useEffect(() => {
    if (isVisible) dispatch(getProducts());
  }, [dispatch, isVisible]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(getProducts({ search: text }));
    }, 500);
    setDelay(copyState);
  };

  const handleClick = item => {
    let find = value.find(el => el.id === item.id);
    if (!find) {
      setValue([...value, item]);
      toggle();
    }
  };

  const listOfAddedItems = () => (
    <div className="list_of_updated_items">
      {value.map(item => (
        <div key={item.id} className="item">
          {item.name}
          <FaTimes
            onClick={() => setValue(value.filter(el => el.id !== item.id))}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="home_screen_sections">
      <div className={activeClass('search_select_wrapper', isVisible)}>
        <div className="search_select_holder">
          <div className="root" onClick={toggle}>
            <p>{formatMessage(messages.dropdown_placeholder)}</p>
            <span className="icon">
              <FaSortDown />
            </span>
          </div>
          {isVisible && (
            <div className="dropdown_holder" ref={modal}>
              <div className="dropdown_content">
                <input
                  type="search"
                  placeholder={formatMessage(
                    messages.dropdown_search_placeholder
                  )}
                  onChange={handleChange}
                  autoFocus
                />
                {data && data.length ? (
                  <ul>
                    {data.map(item => (
                      <li
                        key={item.id}
                        onClick={() => handleClick(item)}
                        className={activeClass(
                          '',
                          value.find(el => el.id === item.id)
                        )}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="no_result">
                    {formatMessage(messages.no_result)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {value.length ? listOfAddedItems() : null}
    </div>
  );
};

export default ProductsDropdown;
