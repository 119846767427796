import React from 'react';
import PropTypes from 'prop-types';
import Shimmer from './Shimmer';

const SkeletonElement = ({ type }) => (
  <div className={`skeleton ${type}`}>
    <Shimmer />
  </div>
);

SkeletonElement.propTypes = {
  type: PropTypes.string
};

export default SkeletonElement;
