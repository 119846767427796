import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

const DefaultDropdown = ({ close }) => (
  <>
    <div className="top_side">
      <p>Već ste se registrovali?</p>
      <Link to={routes.LOGIN} onClick={close}>
        Ulogujte se
      </Link>
    </div>
    <div className="bottom_side">
      <p>Nemate nalog?</p>
      <Link to={routes.REGISTER} onClick={close}>
        Registrujte se
      </Link>
    </div>
  </>
);

export default DefaultDropdown;
