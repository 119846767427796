import React from 'react';
import { useDispatch } from 'react-redux';
import { RiShoppingCartLine, FiHeart } from 'react-icons/all';

import { addItem } from 'views/ShoppingCart/redux/actions';
import { addFavoriteItem } from 'views/WishList/redux/actions';
import Quantity from './Quantity';

const Buttons = ({ product_id, quantity, setQuantity }) => {
  const dispatch = useDispatch();

  const addToCart = () => {
    dispatch(addItem({ product_id, quantity }));
  };

  const addToWishList = () =>
    dispatch(addFavoriteItem({ product_id, quantity }));

  return (
    <div className="buttons">
      <Quantity quantity={quantity} setQuantity={setQuantity} />
      <button className="cart_button" onClick={addToCart}>
        <span>
          <RiShoppingCartLine />
        </span>
        Dodaj u korpu
      </button>
      <button className="wish_list_button" onClick={addToWishList}>
        <span>
          <FiHeart />
        </span>
        Dodaj u listu želja
      </button>
    </div>
  );
};

export default Buttons;
