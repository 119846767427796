import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from 'helpers/useOutsideClick';

const Calculator = ({ product }) => {
  const { retail_price } = product;
  const [isVisible, setIsVisible] = useState(false);
  const [repayment, setRepayment] = useState(6);
  const [participation, setParticipation] = useState('');
  const [total, setTotal] = useState('');
  const dropdown = useRef(null);
  const toggle = () => setIsVisible(prevState => !prevState);

  useOutsideClick(dropdown, () => {
    if (isVisible) toggle();
  });

  const handleSetRepayment = num => {
    setRepayment(num);
    toggle();
  };

  useEffect(() => {
    if (participation !== '') {
      let calculate = (retail_price - participation) / repayment;
      setTotal(calculate.toFixed(2));
    } else {
      setTotal('');
    }
  }, [retail_price, participation, repayment]);

  return (
    <div className="calculator_tab">
      <p className="subtitle">
        Kalkulacija kredita je informativnog tipa. I kao takva ne može se
        koristiti u druge svrhe.
      </p>
      <div className="row">
        <div className="label">Glavnica (RSD):</div>
        <input type="number" readOnly value={retail_price} />
      </div>
      <div className="row">
        <div className="label">Učešće (RSD):</div>
        <input type="number" onChange={e => setParticipation(e.target.value)} />
      </div>
      <div className="row">
        <div className="label">Kamata (%):</div>
        <input type="number" disabled />
      </div>
      <div className="row">
        <div className="label">Rok otplate (meseci):</div>
        <div className="right">
          <input type="number" readOnly value={repayment} onClick={toggle} />
          {isVisible && (
            <ul className="dropdown" ref={dropdown}>
              <li onClick={() => handleSetRepayment(6)}>6</li>
              <li onClick={() => handleSetRepayment(12)}>12</li>
              <li onClick={() => handleSetRepayment(24)}>24</li>
            </ul>
          )}
        </div>
      </div>
      <div className="row">
        <div className="label">Mesečna rata (rsd):</div>
        <input type="number" readOnly value={total} />
      </div>
    </div>
  );
};

export default Calculator;
