import * as React from 'react';

function Favicon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63.88 66.28"
      className="favicon"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M45.25 66.28c-.34-4.59-.69-9.13-1-13.68-13.73 4-24.89.76-33-11.06C6.09 34 4.48 20.28 13.59 9.45L0 .75.07.6c.62.28 1.24.54 1.85.85q6.17 3.11 12.33 6.26a.92.92 0 001.25-.16 27.15 27.15 0 0144.79 11.89 26.61 26.61 0 01-3.61 23 1.09 1.09 0 00-.08 1.36 83.5 83.5 0 017.17 16.59c.18.56.24.91-.51 1.11-5.81 1.5-11.61 3.13-17.41 4.71a3.16 3.16 0 01-.6.07zm-1-14.16c3.67-1.16 8.3-4.64 11.23-8.34a1.08 1.08 0 00.09-1.48A73.71 73.71 0 0036.8 21.86a155.9 155.9 0 00-20.67-13c-1.27-.68-1.43-.64-2.15.71l.87.6A96.77 96.77 0 0131 23.55a48.74 48.74 0 0111.79 21.78c.55 2.23.98 4.48 1.48 6.79z"
          fill="#3188c5"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default Favicon;
