import React from 'react';
import Image from 'components/Image/Loadable';
import Logo from 'assets/images/som.png';
import etrustLogo from 'assets/images/etrustlogo.png';
import routes from 'routes';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const General = () => (
  <aside className="column general">
    <Image src={Logo} />
    <p>
      Jakov Smart Solutions je IT kompanija sa sedištem u gradu Nišu, 
      centru Jugoistočne Srbije. Naš tim sačinjavaju iskusni profesionalci 
      koji su udružili snage kako bi oblikovali Vašu pametnu budućnost. 
      Naš cilj je da povežemo ljude koji vole tehnologiju, jer čvrsto 
      verujemo da tehnologija pripada svima i ne poznaje granice.
    </p>
    <p>Bulevar Nemanjića 25a, PC Zona III, lokal 69, 18000 Niš, Srbija</p>
    <br></br>
    <Link to={routes.E_TRUST}>
      <Image src={etrustLogo} />
    </Link>
  </aside>
);

export default General;
