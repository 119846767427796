const APP = `[App]`;

const types = {
  FETCH_AUTHENTICATED_USER_REQUEST: `${APP} FETCH_AUTHENTICATED_USER_REQUEST`,
  FETCH_AUTHENTICATED_USER_SUCCESS: `${APP} FETCH_AUTHENTICATED_USER_SUCCESS`,

  SET_USERS_ADDRESSES: `${APP} SET_USERS_ADDRESSES`,
  SET_USERS_ORDERS: `${APP} SET_USERS_ORDERS`,

  LOGOUT_REQUEST: `${APP} LOGOUT_REQUEST`,
  LOGOUT_SUCCESS: `${APP} LOGOUT_SUCCESS`,

  SET_TOKEN: `${APP} SET_TOKEN`,

  GET_MODULES: `${APP} GET_MODULES`,
  SET_MODULES: `${APP} SET_MODULES`,

  GET_BANNERS: `${APP} GET_BANNERS`,
  SET_BANNERS: `${APP} SET_BANNERS`,

  GET_CART_COOKIE: `${APP} GET_CART_COOKIE`,
  SET_CART_COOKIE: `${APP} SET_CART_COOKIE`,

  GET_WISH_LIST_COOKIE: `${APP} GET_WISH_LIST_COOKIE`,
  SET_WISH_LIST_COOKIE: `${APP} SET_WISH_LIST_COOKIE`,

  TRANSFER_CART_ON_AUTH: `${APP} TRANSFER_CART_ON_AUTH`
};

export default types;
