import React from 'react';

const Truck = ({ fromProduct = false }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="70.000000pt"
    height="50.000000pt"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={fromProduct ? '#0787ea' : '#000000'}
      stroke="none"
    >
      <path
        d="M0 410 c0 -6 53 -10 145 -10 l145 0 0 -140 0 -140 -46 0 c-37 0 -52
5 -70 25 -29 32 -59 32 -88 0 -13 -14 -33 -25 -45 -25 -19 0 -21 6 -21 50 0
28 -5 50 -10 50 -7 0 -10 -22 -8 -57 3 -56 4 -58 33 -61 16 -2 39 -15 52 -28
28 -31 58 -31 87 1 20 22 32 25 91 25 59 0 71 -3 91 -25 29 -31 58 -32 87 -2
12 13 30 26 40 30 14 5 17 19 17 76 0 66 -2 72 -40 120 -40 50 -41 51 -95 51
-53 0 -54 0 -57 33 l-3 32 -152 3 c-100 2 -153 -1 -153 -8z m362 -137 l3 -58
58 -3 57 -3 0 -44 c0 -25 -3 -45 -6 -45 -4 0 -17 11 -30 25 -29 32 -59 32 -88
0 -40 -43 -46 -34 -46 80 0 105 0 105 25 105 22 0 24 -4 27 -57z m65 35 c11
-13 28 -35 38 -50 l17 -28 -51 0 -51 0 0 50 c0 56 14 64 47 28z m-267 -173
c26 -32 -13 -81 -47 -59 -10 6 -19 19 -21 28 -8 38 43 61 68 31z m270 0 c26
-32 -13 -81 -47 -59 -23 14 -28 41 -13 59 16 19 44 19 60 0z"
      />
      <path
        d="M0 370 c0 -6 37 -10 90 -10 53 0 90 4 90 10 0 6 -37 10 -90 10 -53 0
-90 -4 -90 -10z"
      />
      <path
        d="M0 330 c0 -6 32 -10 75 -10 43 0 75 4 75 10 0 6 -32 10 -75 10 -43 0
-75 -4 -75 -10z"
      />
      <path
        d="M0 290 c0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -27 10 -60 10 -33 0
-60 -4 -60 -10z"
      />
      <path
        d="M0 250 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25 0
-45 -4 -45 -10z"
      />
    </g>
  </svg>
);

export default Truck;
