import { defineMessages } from 'react-intl';

export const scope = 'cms_api_clients';

export default defineMessages({
  page_title: {
    id: `${scope}.page_title`,
    defaultMessage: 'API Client'
  },
  delete_modal_text: {
    id: `${scope}.delete_modal_text`,
    defaultMessage: 'tip akcije'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Ime'
  },
  text: {
    id: `${scope}.text`,
    defaultMessage: ' '
  },
  shockAction: {
    id: `${scope}.shockAction`,
    defaultMessage: 'Prikazati u šok akcijama'
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Akcije'
  },
  created: {
    id: `${scope}.created`,
    defaultMessage: 'Uspešno ste dodali tip akcije.'
  },
  updated: {
    id: `${scope}.updated`,
    defaultMessage: 'Uspešno ste izmenili tip akcije.'
  },
  deleted: {
    id: `${scope}.deleted`,
    defaultMessage: 'Uspešno ste izbrisali tip akcije.'
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Dodaj'
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Kreiraj'
  },
  yup_validation_name_min: {
    id: `${scope}.yup_validation_name_min`,
    defaultMessage: 'Polje mora sadržati minimum 2 karaktera'
  },
  yup_validation_name_max: {
    id: `${scope}.yup_validation_name_max`,
    defaultMessage: 'Polje može sadržati maksimum 255 karaktera'
  },
  yup_validation_name_required: {
    id: `${scope}.yup_validation_name_required`,
    defaultMessage: 'Polje je obavezno'
  },
  min0: {
    id: `${scope}.min0`,
    defaultMessage: 'Broj mora biti veći od 0'
  },
  max100: {
    id: `${scope}.max100`,
    defaultMessage: 'Broj mora biti manji od 100'
  }
});
