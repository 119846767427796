const KEY = '[WEBSITE_SINGLE_PRODUCT]';

const types = {
  GET_PRODUCT: `${KEY} GET_PRODUCT`,
  SET_PRODUCT: `${KEY} SET_PRODUCT`,
  GET_PRODUCT_ERROR: `${KEY} GET_PRODUCT_ERROR`,

  RATING_PRODUCT: `${KEY} RATING_PRODUCT`,

  ASK_QUESTION: `${KEY} ASK_QUESTION`,

  GET_DECLARATION: `${KEY} GET_DECLARATION`,
  SET_DECLARATION: `${KEY} SET_DECLARATION`,

  PRODUCT_AVAILABLE_AGAIN: `${KEY} PRODUCT_AVAILABLE_AGAIN`,

  SEND_OFFER: `${KEY} SEND_OFFER`
};

export default types;
