import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectApiClient = state => state.apiClient || initialState;

const makeSelectData = () =>
  createSelector(selectApiClient, substate => substate.data);

const makeSelectCategories = () =>
  createSelector(selectApiClient, substate => substate.categories);

export { makeSelectData, makeSelectCategories };
