import React from 'react';

const LoginIcon = ({ fromProduct = false }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="70.000000pt"
    height="50.000000pt"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={fromProduct ? '#0787ea' : '#000000'}
      stroke="none"
    >
      <path
        d="M155 456 c-52 -24 -98 -69 -110 -107 -11 -33 2 -34 19 -1 48 94 174
    137 271 93 128 -58 164 -223 72 -328 -96 -110 -277 -89 -343 39 -17 33 -30 32
    -19 -1 20 -65 123 -131 205 -131 52 0 134 35 167 71 34 37 63 110 63 159 0 52
    -35 134 -71 167 -37 34 -110 63 -159 63 -26 0 -66 -10 -95 -24z"
      />
      <path
        d="M250 333 c0 -4 14 -22 32 -40 l32 -33 -147 0 c-91 0 -147 -4 -147
    -10 0 -6 56 -10 147 -10 l147 0 -34 -35 c-18 -19 -30 -38 -26 -42 3 -4 28 14
    54 40 l46 47 -44 45 c-42 42 -60 54 -60 38z"
      />
    </g>
  </svg>
);

export default LoginIcon;
