import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image/Loadable';

import LeftBannerPhoto from 'assets/images/banner-left.webp';
import RightBannerPhoto from 'assets/images/banner-right.webp';
import MobileLeftBannerPhoto from 'assets/images/mobile-banner-left.webp';
import MobileRightBannerPhoto from 'assets/images/mobile-banner-right.webp';
import { activeClass } from 'helpers/activeClass';
import routes from 'routes';

const Banner = ({ scrolled }) => (
  <div className="banner_wrapper">
    <div className="container">
      <div className="banner">
        <a href="tel:" className="border">
          Pozovite nas
        </a>
        <a href="mailto:ecommerce@smartofficemanager.com">ecommerce@smartofficemanager.com</a>
      </div>
    </div>
  </div>
);

export default Banner;
