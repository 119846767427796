import React, { useState } from 'react';
import { activeClass } from 'helpers/activeClass';
import Specifications from './Specifications';
import Description from './Description';
import Declaration from './Declaration';
import Calculator from './Calculator';
import { BiChevronDown } from 'react-icons/bi';

const Tabs = ({ product, onStock }) => {
  const [activeTab, setActiveTab] = useState(0);

  const renderComponents = () => {
    /* eslint-disable default-case */
    switch (activeTab) {
      case 0:
        return (
          <Specifications attributes={product.attributes} onStock={onStock} />
        );
      case 1:
        return <Description description={product.description} />;
      case 2:
        return <Declaration product={product} />;
      case 3:
        return <Calculator product={product} />;
    }
  };

  return (
    <div className="product_tabs_section">
      <nav>
        <p
          className={activeClass('', activeTab === 0)}
          onClick={() => setActiveTab(0)}
        >
          Specifikacije <BiChevronDown />
        </p>
        <div className="mobile">
          <Specifications attributes={product.attributes} onStock={onStock} />
        </div>
        <p
          className={activeClass('', activeTab === 1)}
          onClick={() => setActiveTab(1)}
        >
          Opis <BiChevronDown />
        </p>
        <div className="mobile">
          <Description description={product.description} />
        </div>
        <p
          className={activeClass('', activeTab === 2)}
          onClick={() => setActiveTab(2)}
        >
          Deklaracija <BiChevronDown />
        </p>
        <div className="mobile">
          <Declaration product={product} />
        </div>
        <p
          className={activeClass('', activeTab === 3)}
          onClick={() => setActiveTab(3)}
        >
          Kalkulator rata <BiChevronDown />
        </p>
        <div className="mobile">
          <Calculator product={product} />
        </div>
      </nav>
      <div className="box">{renderComponents()}</div>
    </div>
  );
};

export default Tabs;
