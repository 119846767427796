import produce from 'immer';
import types from './constants';

export const initialState = {
  items: []
};

/* eslint-disable default-case */
const shoppingCartReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_ITEMS:
        draft.items = action.payload;
        break;
    }
  });

export default shoppingCartReducer;
