import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FaSortDown, FaTimes } from 'react-icons/fa';
import useOutsideClick from 'helpers/useOutsideClick';
import { activeClass } from 'helpers/activeClass';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'cms/components/Selects/redux/reducer';
import saga from 'cms/components/Selects/redux/saga';
import { fetchSuppliers } from 'cms/components/Selects/redux/actions';
import { makeSelectSuppliers } from 'cms/components/Selects/redux/selectors';
import messages from '../messages';
import useUserKeyDown from 'helpers/useUserKeyDown';

const key = 'selects';

const SuppliersSelect = ({ params, setParams }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [delay, setDelay] = useState('');
  const results = useSelector(makeSelectSuppliers());
  const modal = useRef(null);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isVisible && params && results.length) {
      let activeItem = results.map(item => item.name).indexOf(params.supplier);
      if (activeItem > -1) setActiveIndex(activeItem);
      else setActiveIndex(0);
    }
  }, [isVisible, results, params]);

  useOutsideClick(modal, () => {
    if (isVisible) toggle();
  });

  useUserKeyDown('ArrowUp', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex > 0) setActiveIndex(prev => prev - 1);
      else setActiveIndex(results.length - 1);
    }
  });

  useUserKeyDown('ArrowDown', [activeIndex, isVisible, results], () => {
    if (isVisible) {
      if (activeIndex < results.length - 1) setActiveIndex(prev => prev + 1);
      else setActiveIndex(0);
    }
  });

  useUserKeyDown('Enter', [activeIndex, isVisible, results], () => {
    if (isVisible && results.length) handleClick(results[activeIndex]);
  });

  useEffect(() => {
    if (isVisible) dispatch(fetchSuppliers(''));
  }, [isVisible, dispatch]);

  const toggle = () => setIsVisible(!isVisible);

  const handleChange = e => {
    let text = e.target.value;
    if (delay) clearTimeout(delay);
    let copyState = delay;
    copyState = setTimeout(() => {
      dispatch(fetchSuppliers(text));
    }, 500);
    setDelay(copyState);
    setActiveIndex(0);
  };

  // const handleClick = item => {
  //   setParams({ ...params, supplier: item.name });
  //   toggle();
  // };
  const handleClick = item => {
    // const prev = params.supplier ? [...params.supplier] : []
    // if(!prev.find(p=> p.name === item.name)){
    //   setParams({ ...params, supplier: [...prev,{name:item.name,id:item.id}] });
    //   // toggle();
    // }
    let prev = null;
    if (typeof params.suppliers === 'string') {
      let data = [];
      const suppliers = params.suppliers.split(',');
      suppliers.forEach(man => {
        const item = results.find(res => String(res.id) === man);
        if (item) data.push(item);
      });
      prev = [...data];
    } else {
      prev = params.suppliers ? [...params.suppliers] : [];
    }
    if (!prev.find(p => p.value === item.name)) {
      setParams({
        ...params,
        suppliers: [...prev, { value: item.name, id: item.id }]
      });
    }
  };
  // const handleDelete = () => {
  //   const { supplier: tmp, ...rest } = params;
  //   setParams(rest);
  // };
  const handleDelete = supplier => () => {
    // const rest = params.supplier.filter(m=>m.name!==supplier.value)
    // setParams({...params,supplier:rest});
    const rest = params.suppliers.filter(m => m.value !== supplier.value);
    setParams({ ...params, suppliers: rest });
  };
  const getSuppliers = () => {
    const mans = params.suppliers.split(',');

    let data = [];
    mans.forEach(m => {
      const el = results.find(res => String(res.id) === m);
      if (el) data.push({ ...el, value: el.name });
    });
    return data;
  };
  return (
    <div className="group">
      <h3>{formatMessage(messages.supplier)}</h3>
      <div className={activeClass('search_select_wrapper', isVisible)}>
        <div className="search_select_holder">
          <div className="root" onClick={toggle}>
            <p>
              {params.suppliers && params.suppliers.length
                ? typeof params.suppliers !== 'string'
                  ? params.suppliers.map((supplier, i) => {
                      return (
                        <span key={i}>
                          {supplier.value}
                          <FaTimes onClick={handleDelete(supplier)} />
                        </span>
                      );
                    })
                  : getSuppliers().map((supplier, i) => {
                      return (
                        <span key={i}>
                          {supplier.value}
                          <FaTimes onClick={handleDelete(supplier)} />
                        </span>
                      );
                    })
                : formatMessage(messages.choose_supplier)}
            </p>
            <span className="icon">
              <FaSortDown />
            </span>
          </div>
          <label>{formatMessage(messages.suppliers)}</label>
          {isVisible && (
            <div className="dropdown_holder" ref={modal}>
              <div className="dropdown_content">
                <input
                  type="text"
                  placeholder={formatMessage(messages.search_placeholder)}
                  onChange={handleChange}
                  autoFocus
                />
                {results.length ? (
                  <ul>
                    {results.map((item, index) => (
                      <li
                        key={item.id}
                        onClick={() => handleClick(item)}
                        onMouseEnter={() => setActiveIndex(index)}
                        className={activeClass('', activeIndex === index)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="no_result">
                    {formatMessage(messages.no_result)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuppliersSelect;
