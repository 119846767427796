import produce from 'immer';
import types from './constants';

export const initialState = {
  products: [],
  catalogs: [],
  landing_pages: [],
  models: [],
  tmp_landing_page: [],
  categories: [],
  editor_success: false
};

/* eslint-disable default-case */
const cmsBannersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_CATALOG:
        draft.catalogs = action.payload;
        break;
      case types.SET_PRODUCTS:
        draft.products = action.payload;
        break;
      case types.SET_LANDING_PAGES:
        draft.landing_pages = action.payload;
        break;
      case types.SET_LANDING_PAGE_MODELS:
        draft.models = action.payload;
        break;
      case types.SET_LANDING_PAGE:
        draft.tmp_landing_page = action.payload;
        break;
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
      case types.SUCCESS_EDITOR:
        draft.editor_success = action.payload;
        break;
    }
  });

export default cmsBannersReducer;
