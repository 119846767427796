import types from './constants';

export const getOrders = params => ({
  type: types.GET_ORDERS,
  params
});

export const setOrders = payload => ({
  type: types.SET_ORDERS,
  payload
});

export const setParams = params => ({
  type: types.SET_PARAMS,
  params
});

export const removeParams = () => ({
  type: types.REMOVE_PARAMS
});

export const getOrder = id => ({
  type: types.GET_SINGLE_ORDER,
  id
});

export const setOrder = payload => ({
  type: types.SET_SINGLE_ORDER,
  payload
});

export const clearOrderData = () => ({
  type: types.CLEAR_SINGLE_ORDER
});

export const getOrderError = error => ({
  type: types.GET_SINGLE_ORDER_ERROR,
  error
});

export const updateDeliveryStatus = (id, payload) => ({
  type: types.UPDATE_DELIVERY_STATUS,
  id,
  payload
});

export const updateDeliveryStatusSuccess = () => ({
  type: types.UPDATE_DELIVERY_STATUS_SUCCESS
});

export const sendCustomerNotes = (order_id, payload, resetForm) => ({
  type: types.SEND_CUSTOMER_NOTES,
  order_id,
  payload,
  meta: { resetForm }
});

export const getInternalNotes = id => ({
  type: types.GET_INTERNAL_NOTES,
  id
});

export const setInternalNotes = payload => ({
  type: types.SET_INTERNAL_NOTES,
  payload
});

export const createInternalNote = (id, payload, resetForm) => ({
  type: types.CREATE_INTERNAL_NOTE_REQUEST,
  id,
  payload,
  meta: { resetForm }
});

export const createInternalNoteSuccess = () => ({
  type: types.CREATE_INTERNAL_NOTE_SUCCESS
});

export const updateInternalNote = (id, note_id, payload, close) => ({
  type: types.UPDATE_INTERNAL_NOTE_REQUEST,
  id,
  note_id,
  payload,
  close
});

export const updateInternalNoteSuccess = () => ({
  type: types.UPDATE_INTERNAL_NOTE_SUCCESS
});

export const deleteInternalNote = (id, note_id) => ({
  type: types.DELETE_INTERNAL_NOTE_REQUEST,
  id,
  note_id
});

export const deleteInternalNoteSuccess = () => ({
  type: types.DELETE_INTERNAL_NOTE_SUCCESS
});

export const getStatusList = params => ({
  type: types.GET_STATUS_LIST,
  params
});

export const setStatusList = payload => ({
  type: types.SET_STATUS_LIST,
  payload
});

export const uploadFile = (id, payload, resetForm) => ({
  type: types.UPLOAD_FILE,
  id,
  payload,
  meta: { resetForm }
});
