import { Link } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Categories = ({ data, closeModal, title }) => {
  const handleDoubleSlash = link => {
    const fixedUrl = link.replace(/\/\//g, '/');

    const absoluteUrl = 'https://' + fixedUrl.split('https:/')[1]; // Convert to absolute URL

    return absoluteUrl;
  };
  return (
    <>
      {data && (
        <div className="group categories">
          <h3>{title}</h3>
          {data.map(item => {
            return (
              <Link
                href={handleDoubleSlash(item.link)}
                onClick={() => {
                  closeModal();
                }}
                key={item.id}
              >
                <p
                  key={item.id}
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: item.highlighted_name
                      ? item.highlighted_name
                      : item.name
                  }}
                ></p>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Categories;
