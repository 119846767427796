import React from 'react';
import { useIntl } from 'react-intl';

const FormikError = ({ errors, touched, name }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {errors && errors[name] && touched[name] && (
        <p className="error_msg">
          {typeof errors[name] === 'object'
            ? formatMessage(errors[name])
            : errors[name]}
        </p>
      )}
    </>
  );
};

export default FormikError;
