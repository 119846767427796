import { defineMessages } from 'react-intl';

export const scope = 'cms_side_bar';

export default defineMessages({
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Korisnici'
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'Proizvodi'
  },
  categories: {
    id: `${scope}.categories`,
    defaultMessage: 'Kategorije'
  },
  attributes: {
    id: `${scope}.attributes`,
    defaultMessage: 'Atributi'
  },
  brands: {
    id: `${scope}.brands`,
    defaultMessage: 'Brendovi'
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Dobavljači'
  },
  upload_price_list: {
    id: `${scope}.upload_price_list`,
    defaultMessage: 'Ažuriraj Cenovnik'
  },
  our_suppliers: {
    id: `${scope}.our_suppliers`,
    defaultMessage: 'Naši Dobavljači'
  },
  catalog_stickers: {
    id: `${scope}.catalog_stickers`,
    defaultMessage: 'Katalog nalepnice'
  },
  status_price_list: {
    id: `${scope}.status_price_list`,
    defaultMessage: 'Status Cenovnika'
  },
  connected_products: {
    id: `${scope}.connected_products`,
    defaultMessage: 'Povezivanje Proizvoda'
  },
  connected_categories: {
    id: `${scope}.connected_categories`,
    defaultMessage: 'Povezivanje Kategorija'
  },
  recommended_prices: {
    id: `${scope}.recommended_prices`,
    defaultMessage: 'Preporučene Cene'
  },
  web_marge: {
    id: `${scope}.web_marge`,
    defaultMessage: 'Web marže'
  },
  web_marge_by_category: {
    id: `${scope}.web_marge_by_category`,
    defaultMessage: 'Grupna promena web marži'
  },
  activity_log: {
    id: `${scope}.activity_log`,
    defaultMessage: 'Aktivnosti Korisnika'
  },
  auth_discounts: {
    id: `${scope}.auth_discounts`,
    defaultMessage: 'Ulogovani Popusti'
  },
  discounts_types: {
    id: `${scope}.discounts_types`,
    defaultMessage: 'Tipovi Akcija'
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: 'Porudžbine'
  },
  invoices: {
    id: `${scope}.invoices`,
    defaultMessage: 'Profakture'
  },
  data: {
    id: `${scope}.data`,
    defaultMessage: 'Podaci'
  },
  emails: {
    id: `${scope}.emails`,
    defaultMessage: 'Emailovi'
  },
  banks: {
    id: `${scope}.banks`,
    defaultMessage: 'Banke'
  },
  visible_on_site: {
    id: `${scope}.visible_on_site`,
    defaultMessage: 'Vidljivi na sajtu'
  },
  banners: {
    id: `${scope}.banners`,
    defaultMessage: 'Baneri'
  },
  home_screen: {
    id: `${scope}.home_screen`,
    defaultMessage: 'Početna strana'
  },
  sections: {
    id: `${scope}.sections`,
    defaultMessage: 'Sekcije'
  },
  news: {
    id: `${scope}.news`,
    defaultMessage: 'Vesti'
  },
  authorization: {
    id: `${scope}.authorization`,
    defaultMessage: 'Autorizacija'
  },
  newsletter: {
    id: `${scope}.newsletter`,
    defaultMessage: 'Newsletter'
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifikacije'
  },
  ratings: {
    id: `${scope}.ratings`,
    defaultMessage: 'Ocene'
  },
  questions_and_answers: {
    id: `${scope}.questions_and_answers`,
    defaultMessage: 'Pitanja i odgovori'
  },
  products_export: {
    id: `${scope}.products_export`,
    defaultMessage: 'Export Proizvoda'
  },
  prices: {
    id: `${scope}.prices`,
    defaultMessage: 'Cene'
  },
  frontend: {
    id: `${scope}.frontend`,
    defaultMessage: 'Frontend'
  },
  linking: {
    id: `${scope}.linking`,
    defaultMessage: 'Povezivanje'
  },
  products_import: {
    id: `${scope}.products_import`,
    defaultMessage: 'Import proizvoda'
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Akcije'
  },
  eOffer: {
    id: `${scope}.eOffer`,
    defaultMessage: 'E-ponuda - export'
  },
  eOfferImport: {
    id: `${scope}.eOfferImport`,
    defaultMessage: 'E-ponuda - import'
  },
  api_client: {
    id: `${scope}.apiClient`,
    defaultMessage: 'API Klijenti'
  },

  landing_page: {
    id: `${scope}.landing_page`,
    defaultMessage: 'Landing Page'
  }
});
