import { defineMessages } from 'react-intl';

export const scope = 'cms_banners';

export default defineMessages({
  page_title: {
    id: `${scope}.page_title`,
    defaultMessage: 'Baneri'
  },
  add_banner: {
    id: `${scope}.add_banner`,
    defaultMessage: 'Dodaj Baner'
  },
  edit_banner: {
    id: `${scope}.edit_banner`,
    defaultMessage: 'Izmeni baner'
  },
  banner: {
    id: `${scope}.banner`,
    defaultMessage: 'Baner'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Ime'
  },
  link: {
    id: `${scope}.link`,
    defaultMessage: 'Link'
  },
  position: {
    id: `${scope}.position`,
    defaultMessage: 'Pozicija'
  },
  yup_name_min: {
    id: `${scope}.yup_name_min`,
    defaultMessage: 'Ime mora sadržati najmanje 1 karakter'
  },
  yup_name_max: {
    id: `${scope}.yup_name_max`,
    defaultMessage: 'Ime može sadržati najviše 255 karaktera'
  },
  yup_name_required: {
    id: `${scope}.yup_name_required`,
    defaultMessage: 'Ime je obavezno'
  },
  yup_banner_position_id_required: {
    id: `${scope}.yup_banner_position_id_required`,
    defaultMessage: 'Pozicija je obavezna'
  },
  yup_image_required: {
    id: `${scope}.yup_image_required`,
    defaultMessage: 'Slika je obavezna'
  },
  photo_size_limit: {
    id: `${scope}.photo_size_limit`,
    defaultMessage: 'Maksimalni limit slike je 2MB'
  },
  choose_photo: {
    id: `${scope}.choose_photo`,
    defaultMessage: 'Izaberite sliku banera'
  },
  choose_mobile_photo: {
    id: `${scope}.choose_mobile_photo`,
    defaultMessage: 'Izaberite sliku banera za telefon'
  },
  banner404: {
    id: `${scope}.banner404`,
    defaultMessage: 'Izabrani baner ne postoji ili je izbrisan.'
  },
  dropdown_placeholder: {
    id: `${scope}.dropdown_placeholder`,
    defaultMessage: 'Izaberi proizvode'
  },
  dropdown_search_placeholder: {
    id: `${scope}.dropdown_search_placeholder`,
    defaultMessage: 'Pretraži'
  },
  no_result: {
    id: `${scope}.no_result`,
    defaultMessage: 'Nema rezultata'
  },
  add_product: {
    id: `${scope}.add_product`,
    defaultMessage: 'Dodaj proizvod'
  },
  edit_desktop_image: {
    id: `${scope}.edit_desktop_image`,
    defaultMessage: 'Izmenite sliku za desktop:'
  },
  edit_mobile_image: {
    id: `${scope}.edit_mobile_image`,
    defaultMessage: 'Izmenite sliku za mobilni:'
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'kategoriju'
  },
  choose_category: {
    id: `${scope}.choose_category`,
    defaultMessage: 'Kategorija'
  }
});
