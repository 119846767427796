import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FiSearch, CgMenu, FiUser, FiShoppingCart } from 'react-icons/all';

import { makeSelectItems } from 'views/ShoppingCart/redux/selectors';
import { getItem } from 'utils/localStorage';
import { activeClass } from 'helpers/activeClass';
import Image from 'components/Image/Loadable';
import Logo from 'assets/images/jakov-logo.webp';
import routes from 'routes';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/all';
import { makeSelectFavoriteItems } from 'views/WishList/redux/selectors';

const MobileBar = ({
  toggleNavbar,
  toggleSearchDialog,
  scrolled,
  setScrolled
}) => {
  const { push } = useHistory();
  const { items } = useSelector(makeSelectItems());
  const favoriteItems = useSelector(makeSelectFavoriteItems());
  const handleScroll = useCallback(() => {
    if (window.scrollY > 200) setScrolled(true);
    else setScrolled(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleUser = () => {
    const token = getItem('token');
    const link = token ? routes.MY_ACCOUNT_ACCOUNT_DETAILS : routes.LOGIN;
    push(link);
  };

  return (
    <div className={activeClass('mobile_bar', scrolled)}>
      <div className="container">
        <div className="mobile_bar_holder">
          <div className="left">
            {/* <CgMenu onClick={toggleNavbar} /> */}
            <Image src={Logo} onClick={() => push(routes.HOME)} />
          </div>
          <div className="right">
            <div className="item">
              <FiUser onClick={handleUser} /> <p>Nalog</p>
            </div>

            <div
              className="cart_icon item"
              onClick={() => push(routes.SHOPPING_CART)}
            >
              <FiShoppingCart />
              <p className="cart_badge">{items ? items.length : 0}</p>
              <p>Korpa</p>
            </div>
            <Link to={routes.WISH_LIST} className="favorite_link item">
              {favoriteItems?.items?.length ? (
                <MdFavorite />
              ) : (
                <MdFavoriteBorder style={{ fill: '#333e48' }} />
              )}
              <p>Lista želja</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBar;
