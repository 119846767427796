import React from 'react';

import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import { priceToFixed } from 'helpers/priceToFixed';

const TablePrice = ({ data }) => {
  return (
    <div className="cms_product_attributes_table" id="oknok-table">
      <TableHelper
        tableName="ok-nok2"
        tableHeaders={[
          'Ime',
          'Ulazna cena (sa PDV)',
          'Web cena',
          //   'Ime konkurenta 1',
          //   'Cena konkurenta 1',
          //   'Ime konkurenta 2',
          //   'Cena konkurenta 2',
          //   'Ime konkurenta 3',
          //   'Cena konkurenta 3',
          //   'Ime konkurenta 4',
          //   'Cena konkurenta 4',
          //   'Ime konkurenta 5',
          //   'Cena konkurenta 5',
          'Ime dobavljača'
        ]}
      >
        {data.length ? (
          data.map(item => (
            <TableRow key={item.id} style={{ padding: '8px !important' }}>
              <TableCell classes="p15-0">{item.name}</TableCell>
              <TableCell classes="p15-0">
                {priceToFixed(item.entry_price)}
              </TableCell>
              <TableCell classes="p15-0">
                {priceToFixed(item.web_price)}
              </TableCell>
              {/* <TableCell classes="p15-0">{item.competitor_name_1}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_1)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_2}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_2)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_3}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_3)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_4}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_4)}
                </TableCell>
                <TableCell classes="p15-0">{item.competitor_name_5}</TableCell>
                <TableCell classes="p15-0">
                  {priceToFixed(item.competitor_price_5)}
                </TableCell> */}
              {(() => {
                if (item.supplier) {
                  return (
                    <TableCell classes="p15-0">{item.supplier.name}</TableCell>
                  )
                } else {
                  return (
                    <TableCell classes="p15-0"></TableCell>
                  )
                }
              })()}          
            </TableRow>
          ))
        ) : (
          <p className="m-5">Nema podataka</p>
        )}
      </TableHelper>
    </div>
  );
};

export default TablePrice;
