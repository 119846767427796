import React, { useState } from 'react';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import ActionIcons from 'cms/components/Table/ActionIcons';
import messages from './messages';
import { Checkbox } from '@material-ui/core';
import { FaEdit, FaLink } from 'react-icons/fa';
import { GrEdit, GrView } from 'react-icons/gr';
import Modal from './Modal';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { put } from 'redux-saga/effects';
import CopyCell from './CopyCell';
import Edit from './Edit';

import { Edit as EditIcon } from '@material-ui/icons';

const Table = ({
  data,
  handleDelete,
  handleUpdate,
  handleChangeActive,
  categories
}) => {
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);

  const handleView = item => {
    setCurrentClient(item);
    setModal(true);
  };

  const handleEdit = item => {
    setCurrentClient(item);
    setEdit(true);
  };

  return (
    <>
      <TableHelper
        tableName="api_client"
        tableHeaders={[
          'Klijent',
          'API URL',
          messages.text,
          'Status',
          ' ',
          'Akcije'
        ]}
      >
        {data.map(item => {
          return (
            <>
              <TableRow key={item.name}>
                <TableCell classes="api_cell" onClick={() => handleView(item)}>
                  <span style={{ color: 'lightgray' }}>#{item.id} </span>{' '}
                  {item.name}
                </TableCell>

                <CopyCell url={item.api_url} />

                <TableCell>{item.label_text}</TableCell>
                {(() => {
                  if (item.active) {
                    return (
                      <TableCell style={{ color: 'green' }}>Aktivan</TableCell>
                    );
                  } else {
                    return (
                      <TableCell style={{ color: 'red' }}>Neaktivan</TableCell>
                    );
                  }
                })()}

                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={Boolean(item.active)}
                    onClick={() => {
                      handleChangeActive(item.id, item.active);
                    }}
                  />
                </TableCell>

                <TableCell style={{ cursor: 'pointer' }}>
                  <EditIcon
                    style={{ marginLeft: '5%', color: 'gray' }}
                    onClick={() => {
                      handleEdit(item);
                    }}
                  />
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableHelper>

      <Modal open={modal} client={currentClient} setOpen={setModal} />
      <Edit
        open={edit}
        client={currentClient}
        setOpen={setEdit}
        update={handleUpdate}
        categories={categories}
      />
    </>
  );
};

export default Table;
