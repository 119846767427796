import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

const MiniNav = ({ categories }) => {
  return (
    <div className="mini_nav">
      {/* <Link to={routes.CREDITS}>24 rate</Link>
      <Link to={routes.EQUIP_OFFICE}>Pravna Lica</Link>
      <Link to={routes.SERVICE}>Servis</Link> */}
      <Link to={routes.ACTIONS}>Šok Akcije</Link>
      {categories.slice(0, 6).map(category => (
        <Link to={`/kategorije/${category.slug}`}>{category.name}</Link>
      ))}
    </div>
  );
};

export default MiniNav;
