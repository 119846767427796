import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { BiChevronDown } from 'react-icons/all';
import { activeClass } from 'helpers/activeClass';

const Dropdown = ({ icon, label, children }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(curr => !curr);

  return (
    <div className="sidebar_dropdown">
      <div className={activeClass('dropdown_label', isOpen)} onClick={toggle}>
        <div>
          {icon}
          <span>{formatMessage(label)}</span>
        </div>
        <BiChevronDown className="arrow" />
      </div>
      <div className="dropdown_items_wrap">
        <div className="dropdown_items">{children}</div>
      </div>
    </div>
  );
};

export default Dropdown;
