import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectProducts = state => state.landing_page || initialState;

const makeSelectProducts = () =>
  createSelector(selectProducts, substate => substate.products);
const makeSelectCatalogs = () =>
  createSelector(selectProducts, substate => substate.catalogs);
const makeSelectLandingPages = () =>
  createSelector(selectProducts, substate => substate.landing_pages);
const makeSelectModels = () =>
  createSelector(selectProducts, substate => substate.models);
const makeSelectCurrentLandingPage = () =>
  createSelector(selectProducts, substate => substate.tmp_landing_page);
const makeSelectCategories = () =>
  createSelector(selectProducts, substate => substate.categories);

const makeSelectSuccess = () =>
  createSelector(selectProducts, substate => substate.editor_success);

export {
  makeSelectProducts,
  makeSelectCatalogs,
  makeSelectLandingPages,
  makeSelectModels,
  makeSelectCurrentLandingPage,
  makeSelectCategories,
  makeSelectSuccess
};
