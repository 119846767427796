import React from 'react';
import Icons from './Icons';

const ShowNotificationModal = ({ data, close }) => (
  <div className="notifications_modal_wrapper" onClick={close}>
    <div
      className="notifications_modal_holder"
      onClick={e => e.stopPropagation()}
    >
      <Icons notification_type={data.type} />
      <h2>{data.data.pricelist}</h2>
      <h3>{data.data.message}</h3>
    </div>
  </div>
);

export default ShowNotificationModal;
