import React from 'react';
import { Link } from 'react-router-dom';
import { MdFavorite } from 'react-icons/all';

import AccountPart from './AccountPart';
import CartPart from './CartPart';
import routes from 'routes';

const Icons = () => (
  <div className="right_side">
    <Link to={routes.WISH_LIST} aria-label="favorite" className="favorite_link">
      <MdFavorite />
      <p>Lista želja</p>
    </Link>
    <AccountPart />
    <CartPart />
  </div>
);

export default Icons;
