import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { fetchCategories } from './redux/actions';
import { makeSelectCategories } from './redux/selectors';
import Banner from './TopBar/Banner';
import MiddleBar from './MiddleBar';
import BottomBar from './BottomBar';
import MobileBar from './MobileBar';
import MobileNavbar from './MobileNavbar';
import MobileSearchDialog from './MobileSearchDialog';
import Results from './MiddleBar/Search/Results';
import IconsNav from './IconsNav';

const key = 'appBar';

const AppBar = ({ token, logout }) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [searchResults, setSearchResults] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledMobile, setScrolledMobile] = useState(false);
  const [mobileNavbarStatus, setMobileNavbarStatus] = useState(false);
  const [mobileSearchDialogStatus, setMobileSearchDialogStatus] = useState(
    false
  );
  const dispatch = useDispatch();
  const categories = useSelector(makeSelectCategories());

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const toggleNavbar = () => {
    setMobileNavbarStatus(prevState => !prevState);
    const body = document.querySelector('body');
    const mobile_bar = document.querySelector('.mobile_bar');

    if (mobileNavbarStatus) {
      body.removeAttribute('style');
      mobile_bar.removeAttribute('style');
    } else {
      body.setAttribute('style', 'left: 250px; overflow: hidden');
      mobile_bar.setAttribute('style', 'left: 250px');
    }
  };

  const toggleSearchDialog = () =>
    setMobileSearchDialogStatus(prevState => !prevState);

  useEffect(() => {
    const body = document.querySelector('body');
    if (searchResults) {
      body.style.overflow = 'hidden';
    } else body.removeAttribute('style');
  }, [searchResults]);

  return (
    <div id="app_bar">
      <Banner scrolled={scrolledMobile} />
      <MiddleBar
        scrolled={scrolled}
        setScrolled={setScrolled}
        setSearchResults={setSearchResults}
      />
      <BottomBar
        scrolled={scrolled}
        token={token}
        logout={logout}
        categories={categories}
      />
      <IconsNav />
      <MobileBar
        scrolled={scrolledMobile}
        setScrolled={setScrolledMobile}
        toggleNavbar={toggleNavbar}
        toggleSearchDialog={toggleSearchDialog}
      />
      <MobileNavbar
        open={mobileNavbarStatus}
        close={toggleNavbar}
        categories={categories}
      />
      <MobileSearchDialog
        close={toggleSearchDialog}
        setSearchResults={setSearchResults}
        toggleNavbar={toggleNavbar}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Results open={searchResults} close={() => setSearchResults(false)} />
      </Suspense>
    </div>
  );
};

export default AppBar;
