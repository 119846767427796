import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { MoreHoriz } from '@material-ui/icons';
import useOutsideClick from 'helpers/useOutsideClick';
import messages from './messages';

const ShowMore = ({
  item,
  isVisible,
  setIsVisible,
  user,
  readNotification,
  deleteNotification
}) => {
  const toggle = () => setIsVisible(current => !current);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => isVisible && toggle());
  const { formatMessage } = useIntl();

  const handleRead = () => readNotification(user.id, item.id);
  const handleDelete = () => deleteNotification(user.id, item.id);

  return (
    <div className="show_more_wrapper" onClick={e => e.stopPropagation()}>
      <MoreHoriz className="icon" onClick={toggle} />
      <div className="show_more">
        {isVisible && (
          <div className="dropdown" ref={dropdownRef}>
            {!item.read_at && (
              <div className="row" onClick={handleRead}>
                {formatMessage(messages.mark_as_read)}
              </div>
            )}
            <div className="row" onClick={handleDelete}>
              {formatMessage(messages.remove)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowMore;
