import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectSuppliers } from './redux/selectors';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { getSuppliers } from './redux/actions';
import Table from './Table';
import Skeleton from './Skeleton';
import messages from './messages';
import Wrap from 'cms/components/Wrap';
const key = '_suppliers';

const OurSuppliers = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const suppliers = useSelector(makeSelectSuppliers());
  const [pagination, setPagination] = useState(null);
  const [search, setSearch] = useState('');
  useEffect(() => {
    dispatch(getSuppliers({ pagination, search }));
  }, [dispatch, pagination, search]);
  const handleSetPagination = data => {
    setPagination(state => {
      return {
        ...state,
        page: data.page || (state && state.page) || meta.current_page,
        pagination:
          (!data.page && data) || (state && state.pagination) || meta.per_page
      };
    });
  };
  const handleSetSearch = useCallback(
    (...args) => setSearch(...args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const { data, meta } = suppliers;

  return (
    <Wrap
      pageTitle={messages.page_title}
      data={data}
      type="cms"
      meta={meta}
      isInline={false}
      params={meta?.per_page}
      setSearch={handleSetSearch}
      setParams={handleSetPagination}
      skeleton={<Skeleton className="web_margins" />}
      suppliers={true}
      tableContent={<Table data={data} />}
    />
  );
};

export default OurSuppliers;
