export default {
  api: {
    // baseUrl: 'https://api.jakov.rs/api/v1/',
    // cdn: 'https://api.jakov.rs/api/v1/'

    baseUrl: 'https://api.ecommerce.smartofficemanager.com/api/v1/',
    cdn: 'https://api.ecommerce.smartofficemanager.com/api/v1/'
  },
  social: {
    facebookAppId: 3400912853493263,
    googleClientId:
      '294165968228-d6a402hdd083j53thkdsgubvjpvp52hk.apps.googleusercontent.com'
  }
};
