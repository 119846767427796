import React from 'react';
import AskForm from './AskForm';
import Answers from './Answers';

const QuestionsAndAnswers = ({ slug, questions }) => (
  <div className="questions_and_answers">
    <h5 style={{ fontSize: '18px', marginBottom: '20px' }}>
      Pitanja i odgovori
    </h5>
    <Answers questions={questions} />
    <AskForm slug={slug} />
  </div>
);

export default QuestionsAndAnswers;
