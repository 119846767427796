import types from './constants';

export const getItems = () => ({
  type: types.GET_ITEMS
});

export const setItems = payload => ({
  type: types.SET_ITEMS,
  payload
});

export const addItem = payload => ({
  type: types.ADD_ITEM,
  payload
});

export const updateItem = (product_id, quantity) => ({
  type: types.UPDATE_ITEM,
  product_id,
  quantity
});

export const deleteItem = product_id => ({
  type: types.DELETE_ITEM,
  product_id
});

export const clearCart = () => ({
  type: types.CLEAR_CART
});
