import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSingleProduct = state => state._singleProduct || initialState;

const makeSelectProduct = () =>
  createSelector(selectSingleProduct, substate => substate.product);

const makeSelectProductError = () =>
  createSelector(selectSingleProduct, substate => substate.productError);

const makeSelectDeclaration = () =>
  createSelector(selectSingleProduct, substate => substate.declaration);

export { makeSelectProduct, makeSelectDeclaration, makeSelectProductError };
