import { Link } from '@material-ui/core';
import Image from 'components/Image/Loadable';
import React from 'react';
import { formatCurrency } from 'utils/formatCurrency';
const SearchedProducts = ({ data }) => {
  return (
    <div style={{ height: '100%', flex: 1 }}>
      <h2 className="product_title" style={{ backgroundColor: 'white' }}>
        Proizvodi
      </h2>
      <div className="products">
        {data.map(item => {
          return (
            <Link href={item.link} key={item.id}>
              <div style={{ width: '290px', height: '95%' }}>
                <div className="product_item" key={item.id}>
                  <div className="mobile">
                    <p
                      className="name"
                      dangerouslySetInnerHTML={{
                        __html: item.highlighted_name
                          ? item.highlighted_name
                          : item.name
                      }}
                    ></p>
                    <p className="price">
                      {formatCurrency(item.price.regular_price)}
                    </p>
                  </div>
                  <Image src={item.image} style={{ width: '100%' }} />
                  <p className="price">
                    {formatCurrency(item.price.regular_price)}
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SearchedProducts;
