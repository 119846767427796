import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image/Loadable';
import variables from 'utils/variables';
import routes from 'routes';

import PhoneIcon from 'assets/images/icons/phone.png';
import MobilePhoneIcon from 'assets/images/icons/mobile-phone.png';
import MailIcon from 'assets/images/icons/mail.png';
import MapPinIcon from 'assets/images/icons/map-pin.png';

const ContactColumn = () => (
  <>
    <div className="row">
      <Image src={PhoneIcon} />
      <a href={`tel:${variables.phone1}`}>{variables.phone1}</a>
      <span>|</span>
      <a href={`tel:${variables.phone2}`}>{variables.phone2}</a>
    </div>
    <div className="row">
      <Image src={MobilePhoneIcon} />
      <a href={`tel:${variables.phone3}`}>{variables.phone3}</a>
    </div>
    <div className="row">
      <Image src={MailIcon} />
      <a href={`mailto:${variables.email}`}>{variables.email}</a>
    </div>
    <div className="row">
      <Image src={MapPinIcon} />
      <Link to={routes.CONTACT}>Kontakt</Link>
    </div>
  </>
);

export default ContactColumn;
