/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import config from '../../../config';

export default function Choose({ options, setOption }) {
  const [checked, setChecked] = useState(1);
  return (
    <>
      <h1>Izaberi raspored</h1>
      <>
        <div className="choose">
          {options.map(option => {
            return (
              <Card
                className="choose_option"
                option={option?.id}
                onClick={() => {
                  setChecked(option?.id);
                }}
              >
                <img
                  src={
                    config.api.cdn.replace('/api/v1/', '') +
                    '/storage/' +
                    option?.image
                  }
                  alt={option?.name}
                />
                <FormControlLabel
                  value={option?.id}
                  control={<Checkbox color="black" />}
                  checked={checked === option?.id}
                  onChange={() => {
                    setChecked(option?.id);
                  }}
                  className="radio_button"
                />
              </Card>
            );
          })}
        </div>

        <Button
          className="izaberi"
          onClick={() => {
            setOption(checked);
          }}
        >
          Izaberi
        </Button>
      </>
    </>
  );
}
