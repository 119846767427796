import produce from 'immer';
import { getItem } from 'utils/localStorage';
import types from './constants';

export const initialState = {
  token: getItem('token') || null,
  user: null,
  orders: [],
  modules: [],
  banners: [],
  addresses: {
    billing_address: {},
    shipping_address: {}
  },
  cart_cookie: '',
  wish_list_cookie: ''
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.FETCH_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.user;
        break;
      case types.LOGOUT_SUCCESS:
        draft.user = null;
        draft.token = null;
        break;
      case types.SET_TOKEN:
        draft.token = action.token;
        break;
      case types.SET_MODULES:
        draft.modules = action.payload;
        break;
      case types.SET_BANNERS:
        draft.banners = action.payload;
        break;
      case types.SET_USERS_ADDRESSES:
        draft.addresses = action.payload;
        break;
      case types.SET_USERS_ORDERS:
        draft.orders = action.payload;
        break;
      case types.SET_CART_COOKIE:
        draft.cart_cookie = action.payload;
        break;
      case types.SET_WISH_LIST_COOKIE:
        draft.wish_list_cookie = action.payload;
        break;
    }
  });

export default appReducer;
