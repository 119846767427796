const KEY = '[CMS_RECOMMENDED_PRICE]';

const types = {
  GET_RECOMMENDED_PRICES: `${KEY} GET_RECOMMENDED_PRICES`,
  SET_RECOMMENDED_PRICES: `${KEY} SET_RECOMMENDED_PRICES`,

  GET_RECOMMENDED_PRICE: `${KEY} GET_RECOMMENDED_PRICE`,
  SET_RECOMMENDED_PRICE: `${KEY} SET_RECOMMENDED_PRICE`,
  CLEAR_RECOMMENDED_PRICES: `${KEY} CLEAR_RECOMMENDED_PRICES`,
  GET_RECOMMENDED_PRICE_ERROR: `${KEY} GET_RECOMMENDED_PRICE_ERROR`,

  ADD_RECOMMENDED_PRICE_REQUEST: `${KEY} ADD_RECOMMENDED_PRICE_REQUEST`,
  ADD_RECOMMENDED_PRICE_SUCCESS: `${KEY} ADD_RECOMMENDED_PRICE_SUCCESS`,
  ADD_RECOMMENDED_PRICE_ERROR: `${KEY} ADD_RECOMMENDED_PRICE_ERROR`,

  UPDATE_RECOMMENDED_PRICE_REQUEST: `${KEY} UPDATE_RECOMMENDED_PRICE_REQUEST`,
  UPDATE_RECOMMENDED_PRICE_SUCCESS: `${KEY} UPDATE_RECOMMENDED_PRICE_SUCCESS`,
  UPDATE_RECOMMENDED_PRICE_ERROR: `${KEY} UPDATE_RECOMMENDED_PRICE_ERROR`,

  DELETE_RECOMMENDED_PRICE_REQUEST: `${KEY} DELETE_RECOMMENDED_PRICE_REQUEST`,
  DELETE_RECOMMENDED_PRICE_SUCCESS: `${KEY} DELETE_RECOMMENDED_PRICE_SUCCESS`,

  SET_CURRENT_PAGE: `${KEY} SET_CURRENT_PAGE`,
  SET_PER_PAGE: `${KEY} SET_PER_PAGE`,
  DELETE_PARAMS: `${KEY} DELETE_PARAMS`
};

export default types;
