import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import messages from '../messages';

const UpdateDescription = ({
  open,
  close,
  params,
  handleUpdate,
  ids,
  clear
}) => {
  const [description, setDescription] = useState('');
  const { formatMessage } = useIntl();

  const handleSubmit = () => {
    const { paginate, page: tmp, ...rest } = params;
    let data = { description };
    if (ids.length) data['ids[]'] = ids;
    handleUpdate(rest, data, close, clear);
  };

  return (
    <Dialog open={open} onClose={close} className="mass_update_desc_modal">
      <DialogTitle>{formatMessage(messages.change_description)}</DialogTitle>
      <DialogContent>
        <div className="area_field mt-0">
          <textarea
            onChange={e => setDescription(e.target.value)}
            autoFocus={true}
          />
          <label>{formatMessage(messages.description)}</label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="button default" onClick={close}>
          {formatMessage(messages.give_up)}
        </Button>
        <Button className="button" onClick={handleSubmit}>
          {formatMessage(messages.edit_product)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDescription;
