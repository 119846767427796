import { takeLatest, call, put } from 'redux-saga/effects';
import { setSuppliers } from './actions';
import types from './constants';
// import responseMock from './mockData.json'
import request from 'utils/request';
const BASE_URL = 'admin/suppliers';

export function* fetchSuppliers({ payload }) {
  try {
    let obj = {};
    if (payload?.pagination?.pagination)
      obj.paginate = payload.pagination.pagination;
    if (payload?.pagination?.page) obj.page = payload.pagination.page;
    if (payload?.search) {
      if (payload?.search?.search) obj.search = payload.search.search;
      else obj.search = payload.search;
    }
    const searchParams = new URLSearchParams(obj);
    const response = yield call(request, {
      url: `${BASE_URL}?${searchParams}`,
      method: 'get'
    });
    yield put(setSuppliers(response));
    // console.log("🚀 ~ file: saga.js ~ line 20 ~ function*fetchSuppliers ~ `${BASE_URL}?${searchParams}`", `${BASE_URL}?${searchParams}`)
    // yield put(setSuppliers(responseMock));
  } catch (error) {}
}

export default function* _suppliersSaga() {
  yield takeLatest(types.GET_SUPPLIERS, fetchSuppliers);
}
