const variables = {
  email: 'ecommerce@smartofficemanager.com',
  facebook_link: 'https://www.facebook.com/JakovSistemOnlineShop',
  instagram_link: 'https://www.instagram.com/jakovsistem',
  skype_link: 'skype:prodajajakovsistem?chat',
  phone1: '018 41 55 230',
  phone2: '018 41 55 230',
  phone3: '066 8 220 230'
};

export default variables;
