import React from 'react';
import { Formik, Form } from 'formik';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import DiscountsTypesSelect from 'cms/components/Selects/DiscountsTypes';
import Calendar from 'cms/components/Calendar';
import messages from '../../messages';
import Yup from 'utils/validations';
import StickerForProducts from 'cms/components/Selects/StickerForProducts';

const updateDiscountsSchema = Yup.object().shape({
  catalog_sticker_type_id: Yup.string().required(
    messages.yup_validate_discount_type_required
  ),
  from: Yup.date().required(messages.yup_validate_discount_from_required),
  to: Yup.date().required(messages.yup_validate_discount_to_required)
});

const ActionForm = ({ open, close, params, onSubmit, ids, clear }) => {
  const { formatMessage } = useIntl();

  const handleSubmit = (values, { setErrors }) => {
    let payload = {
      product_ids: ids,
      catalog_sticker_type_id: values.catalog_sticker_type_id.id,
      from: moment(values.from).format('YYYY-MM-DD'),
      to: moment(values.to).format('YYYY-MM-DD')
    };

    const { paginate, page: tmp, ...rest } = params;
    onSubmit(payload, rest, close, clear, setErrors);
  };

  return (
    <Dialog open={open} onClose={close} className="mass_update_discounts_modal">
      <DialogTitle>{formatMessage(messages.add_action_title)}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            catalog_sticker_type_id: '',
            from: new Date(),
            to: new Date()
          }}
          validationSchema={updateDiscountsSchema}
          onSubmit={(values, { setErrors }) =>
            handleSubmit(values, { setErrors })
          }
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form className="mass_update_discounts">
              <div className="form_group">
                <StickerForProducts
                  value={values.catalog_sticker_type_id}
                  setValue={arg =>
                    setFieldValue('catalog_sticker_type_id', arg)
                  }
                  errors={errors}
                  touched={touched}
                />
                <Calendar
                  name="from"
                  label={formatMessage(messages.valid_from)}
                  value={values.from}
                  setValue={arg => setFieldValue('from', arg)}
                  errors={errors}
                  touched={touched}
                />
                <Calendar
                  name="to"
                  label={formatMessage(messages.valid_to)}
                  value={values.to}
                  setValue={arg => setFieldValue('to', arg)}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="form_buttons_group jc_end">
                <Button className="button default ml_20" onClick={close}>
                  {formatMessage(messages.back)}
                </Button>
                <Button type="submit" className="button ml_20">
                  {formatMessage(messages.add_action)}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ActionForm;
