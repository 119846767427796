import React from 'react';
import { useIntl } from 'react-intl';
import Range from './Range';
import messages from '../messages';

const EntryPrice = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="group">
      <h3>{formatMessage(messages.entry_price)}</h3>
      <Range params={params} setParams={setParams} name="entry_price" />
    </div>
  );
};

export default EntryPrice;
