import React from 'react';
import ProductItem from 'components/ProductItem';
import { HiOutlineX } from 'react-icons/hi';

const Products = ({ products, remove }) => {
  return (
    <>
      {products.length
        ? products.map((product, index) => (
            <div className="item">
              <div
                className="remove_button"
                onClick={() => {
                  remove(index);
                }}
              >
                <HiOutlineX className="icon" />
              </div>
              <ProductItem key={product.id} product={product} />
            </div>
          ))
        : null}
    </>
  );
};

export default Products;
