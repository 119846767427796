import produce from 'immer';
import types from './constants';

export const initialState = {
  data: null,
  categories: []
};

/* eslint-disable default-case */
const apiClientReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_DATA:
        draft.data = action.payload;
        break;
      case types.SET_CATEGORIES:
        draft.categories = action.payload;
        break;
    }
  });

export default apiClientReducer;
