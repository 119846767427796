import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import routes from 'routes';

import NotFoundPage from 'containers/LandingPage/Loadable';
import Login from 'views/Auth/Login/Loadable';
import Register from 'views/Auth/Register/Loadable';
import ResetPassword from 'views/ResetPassword/Loadable';
import NewPassword from 'views/ResetPassword/NewPassword/Loadable';
import HomePage from 'views/HomePage/Loadable';
import ThanksPage from 'views/ThanksPage/Loadable';
import CompletionPurchase from 'views/CompletionPurchase/Loadable';
import NewsPage from 'views/NewsPage/Loadable';
import PreviewNews from 'views/NewsPage/PreviewNews/Loadable';
import ActionsPage from 'views/ActionsPage/Loadable';
import ActionsPreview from 'views/ActionsPage/ActionsPreview/Loadable';
import WishList from 'views/WishList/Loadable';
import ShoppingCart from 'views/ShoppingCart/Loadable';
import Product from 'views/Product/Loadable';
//delete me
import Product_copy from 'views/Product/index_backup';
import CategoryProducts from 'views/CategoryProducts/Loadable';
import ControlPanelAccountDetails from 'views/Auth/ControlPanel/AccountDetails/Loadable';
import ControlPanelAddresses from 'views/Auth/ControlPanel/Addresses/Loadable';
import AddressFormScreen from 'views/Auth/ControlPanel/Addresses/AddressFormScreen/Loadable';
import ControlPanelOrders from 'views/Auth/ControlPanel/Orders/Loadable';
import ControlPanelShowOrder from 'views/Auth/ControlPanel/Orders/ShowOrder/Loadable';
import About from 'views/StaticPages/About/Loadable';
import Help from 'views/StaticPages/Help/Loadable';
import Contact from 'views/StaticPages/Contact/Loadable';
import EquipOffice from 'views/StaticPages/EquipOffice/Loadable';
import EquipHome from 'views/StaticPages/EquipHome/Loadable';
import NewsletterUnsubscribe from 'views/NewsletterUnsubscribe/Loadable';
import ComparePage from 'views/ComparePage/Loadable';

import TermsAndPricesOfDelivery from 'views/StaticPages/TermsAndPricesOfDelivery/Loadable';
import Credits from 'views/StaticPages/PaymentsMethods/Credits/Loadable';
import DiscountedCash from 'views/StaticPages/PaymentsMethods/DiscountedCash/Loadable';
import InterestFreeAccount from 'views/StaticPages/PaymentsMethods/InterestFreeAccount/Loadable';
import PayCards from 'views/StaticPages/PaymentsMethods/PayCards/Loadable';
import RefundPDV from 'views/StaticPages/PaymentsMethods/RefundPDV/Loadable';
import TermsAndConditions from 'views/StaticPages/TermsAndConditions/Loadable';
import PrivacyPolicy from 'views/StaticPages/PrivacyPolicy/Loadable';
import Jobs from 'views/StaticPages/Jobs/Loadable';
import OpenPositions from 'views/StaticPages/OpenPositions/Loadable';
import Service from 'views/StaticPages/Service/Loadable';
import ServiceLaptop from 'views/StaticPages/Service/Laptop/Loadable';
import ServicePrinter from 'views/StaticPages/Service/Printer/Loadable';
import ServiceComputerNetwork from 'views/StaticPages/Service/ComputerNetwork/Loadable';
import ServiceMonitor from 'views/StaticPages/Service/Monitor/Loadable';
import ServicePC from 'views/StaticPages/Service/PC/Loadable';
import ServiceContact from 'views/StaticPages/Service/Contact/Loadable';
import SingleAction from 'views/ActionsPage/SingleAction/Loadable';
import SearchResult from 'views/SearchResult/Loadable';
import Loadable from 'views/StaticPages/WebCredit/Loadable';
import ETrust from 'views/Etrust/Loadable';

export default function FrontendRoutes() {
  return (
    <Switch>
      <PublicRoute exact path={routes.LOGIN} component={Login} />
      <PublicRoute exact path={routes.REGISTER} component={Register} />
      <PublicRoute
        exact
        path={routes.RESET_PASSWORD}
        component={ResetPassword}
      />
      <PublicRoute exact path={routes.NEW_PASSWORD} component={NewPassword} />
      <PrivateRoute
        exact
        path={routes.MY_ACCOUNT_ACCOUNT_DETAILS}
        component={ControlPanelAccountDetails}
      />
      <PrivateRoute
        exact
        path={routes.MY_ACCOUNT_ORDERS}
        component={ControlPanelOrders}
      />
      <PrivateRoute
        exact
        path={routes.MY_ACCOUNT_SHOW_ORDER}
        component={ControlPanelShowOrder}
      />
      <PrivateRoute
        exact
        path={routes.MY_ACCOUNT_ADDRESSES_FORM}
        component={AddressFormScreen}
      />
      <PrivateRoute
        exact
        path={routes.MY_ACCOUNT_ADDRESSES}
        component={ControlPanelAddresses}
      />
      <Route exact path={routes.HOME} component={HomePage} />
      <Route exact path={routes.COMPARE_PRODUCTS} component={ComparePage} />
      <Route
        exact
        path={routes.COMPLETION_PURCHASE}
        component={CompletionPurchase}
      />
      <Route exact path={routes.NEWS} component={NewsPage} />
      <Route exact path={routes.THANKS_PAGE} component={ThanksPage} />
      <Route exact path={routes.NEWS_PREVIEW} component={PreviewNews} />
      <Route exact path={routes.SEARCH_RESULT} component={SearchResult} />
      <Route exact path={routes.ACTIONS} component={ActionsPage} />
      <Route exact path={routes.ACTIONS_PREVIEW} component={ActionsPreview} />
      <Route exact path={routes.SINGLE_ACTION} component={SingleAction} />
      <Route exact path={routes.SHOPPING_CART} component={ShoppingCart} />
      <Route exact path={routes.WISH_LIST} component={WishList} />
      <Route exact path={routes.ABOUT} component={About} />
      <Route exact path={routes.CONTACT} component={Contact} />
      <Route exact path={routes.HELP} component={Help} />
      <Route exact path={routes.EQUIP_OFFICE} component={EquipOffice} />
      <Route exact path={routes.EQUIP_HOME} component={EquipHome} />
      <Route
        exact
        path={routes.NEWSLETTER_UNSUBSCRIBE}
        component={NewsletterUnsubscribe}
      />
      <Route
        exact
        path={routes.TERMS_AND_PRICES_OF_DELIVERY}
        component={TermsAndPricesOfDelivery}
      />
      <Route
        exact
        path={routes.TERMS_AND_CONDITIONS}
        component={TermsAndConditions}
      />
      <Route exact path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route exact path={routes.JOBS} component={Jobs} />
      <Route exact path={routes.OPEN_POSITIONS} component={OpenPositions} />
      <Route exact path={routes.CREDITS} component={Credits} />
      <Route exact path={routes.DISCOUNTED_CASH} component={DiscountedCash} />
      <Route
        exact
        path={routes.INTEREST_FREE_ACCOUNT}
        component={InterestFreeAccount}
      />
      <Route exact path={routes.PAY_CARDS} component={PayCards} />
      <Route exact path={routes.REFUND_PDV} component={RefundPDV} />
      <Route exact path={routes.WEB_CREDIT} component={Loadable} />
      <Route exact path={routes.SERVICE} component={Service} />
      <Route exact path={routes.SERVICE_LAPTOP} component={ServiceLaptop} />
      <Route exact path={routes.SERVICE_MONITORS} component={ServiceMonitor} />
      <Route
        exact
        path={routes.SERVICE_COMPUTER_NETWORK}
        component={ServiceComputerNetwork}
      />
      <Route exact path={routes.SERVICE_PRINTERS} component={ServicePrinter} />
      <Route exact path={routes.SERVICE_PC} component={ServicePC} />
      <Route exact path={routes.SERVICE_CONTACT} component={ServiceContact} />
      <Route exact path={routes.PRODUCT} component={Product} />
      {/* delete me */}
      <Route exact path={routes.PRODUCT_COPY} component={Product_copy} />
      <Route
        exact
        path={routes.PRODUCT_CATEGORIES}
        component={CategoryProducts}
      />

      <Route exact path={routes.E_TRUST} component={ETrust} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
