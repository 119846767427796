import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillPersonFill } from 'react-icons/all';
import useOutsideClick from 'helpers/useOutsideClick';
import { useInjectReducer } from 'utils/injectReducer';
import { logout } from 'containers/App/actions';
import reducer from 'containers/App/reducer';
import { makeSelectToken } from 'containers/App/selectors';
import DefaultDropdown from './DefaultDropdown';
import IsAuthDropdown from './IsAuthDropdown';

const key = 'app';

const AccountPart = () => {
  const [isVisible, setIsVisible] = useState(false);
  const modal = useRef(null);
  const dispatch = useDispatch();

  useInjectReducer({ key, reducer });

  useOutsideClick(modal, () => {
    if (isVisible) toggle(false);
  });

  const token = useSelector(makeSelectToken());

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const toggle = () => setIsVisible(!isVisible);

  return (
    <div className="account_part">
      <BsFillPersonFill onClick={toggle} className={token ? 'active' : ''} />
      <p>Nalog</p>
      {isVisible && (
        <div className="account_part_modal" ref={modal}>
          {token ? (
            <IsAuthDropdown close={toggle} logout={handleLogout} />
          ) : (
            <DefaultDropdown close={toggle} />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountPart;
