import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './redux/reducer';
import saga from './redux/saga';
import {
  setProduct,
  getProduct,
  setDeclaration,
  getProductError
} from './redux/actions';
import { makeSelectProduct, makeSelectProductError } from './redux/selectors';

import CategoriesBreadcrumb from './Bradcrumb';
import Photos from './Details/Photos';
import Details from './Details/index_backup';
import Tabs from './Tabs';
import QuestionsAndAnswers from './QuestionsAndAnswers';
import FavoriteIcon from './Details/FavoriteIcon';

const key = '_singleProduct';

const Product = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [onStock, setOnStock] = useState(false);
  const { web_product_slug } = useParams();
  const product = useSelector(makeSelectProduct());
  const error = useSelector(makeSelectProductError());
  const { name, images, categories, discount } = product;

  useEffect(() => {
    dispatch(getProduct(web_product_slug));

    return () => {
      dispatch(setProduct({}));
      dispatch(setDeclaration(null));
      dispatch(getProductError(null));
    };
  }, [dispatch, web_product_slug]);

  useEffect(() => {
    const { active, visible, web_price, mainCategory } = product;
    if (product) {
      if (!active || !visible || web_price < mainCategory[0].min_price)
        setOnStock(false);
      else setOnStock(true);
    }
  }, [product]);

  return (
    <article className="single_product_page">
      {error && <p className="product_not_found">{error}</p>}
      {product.id && (
        <>
          <CategoriesBreadcrumb name={name} categories={categories} />
          <div className="container">
            <div className="product_content">
              <div className="mobile_track">
                {discount && discount.type.percentage && (
                  <span>{discount.type.percentage} %</span>
                )}
                <FavoriteIcon product_id={product.id} quantity={quantity} />
              </div>
              <div className="product_data">
                <Photos images={images} />
                <Details
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  onStock={onStock}
                />
              </div>
            </div>
            <Tabs product={product} onStock={onStock} />
            <QuestionsAndAnswers
              slug={web_product_slug}
              questions={product.questions}
            />
          </div>
        </>
      )}
    </article>
  );
};

export default Product;
