import produce from 'immer';
import types from './constants';

export const initialState = {
  product: {},
  declaration: null,
  productError: null
};

/* eslint-disable default-case */
const _singleProductReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_PRODUCT:
        draft.product = action.payload;
        break;
      case types.SET_DECLARATION:
        draft.declaration = action.payload;
        break;
      case types.GET_PRODUCT_ERROR:
        draft.productError = action.error;
        break;
    }
  });

export default _singleProductReducer;
