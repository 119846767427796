import { LocationOnOutlined, Phone, Search } from '@material-ui/icons';
import styled, { css } from 'styled-components';
import {
  BiInfoCircle as Info,
  FiHeart,
  GiShop as FcShop,
  RiTruckLine,
  BiLogInCircle,
  AiFillCreditCard
} from 'react-icons/all';
import Login from '../Icons/LoginIcon';
import ShopingIcon from '../Icons/ShopIcon';
import TruckIcon from '../Icons/Truck';
import { Accordion, Select } from '@material-ui/core';

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const SearchIcon = styled(Search)`
  position: absolute;
  right: 0;
  color: #498fe2;
  top: 0;
  font-size: 35px !important;
  cursor: pointer;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ActionItem = styled.a`
  color: #fff;
  margin: 5px 0;
  font-size: 15px;
  display: flex;
  padding: 0 30px 0 10px;
  align-items: center;
  background: ${props => props && props.color};
  color: ${props =>
    props?.color === '#000000' || props?.color === '#0000FF' ? '#FFF' : '#000'};
  font-weight: 500;
  position: relative;
  height: 30px;
  line-height: 40px;
  margin-right: 10px;
  padding: 0 10px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 25%;
  margin-right: 30px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    right: -18px;
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid ${props => props && props.color};
    border-radius: 4px;
  }
  &::after {
    content: '';
    position: absolute;
    left: -1px;
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
    border-radius: 4px;
  }
  @media screen and (max-width: 425px) {
    width: 90%;
  }
`;
export const RetailPrice = styled.h3`
  font-weight: 100;
  text-decoration: line-through;
  color: #acacac;
  font-size: 15px;
`;

export const WebPrice = styled.h2`
  font-weight: 500;
  font-size: 40px;
  margin: 0 5px;
  line-height: 32px;
  margin: 10px 0;
`;

export const SavingPrice = styled.div`
  display: flex;
  color: #8bc34a;
  h3 {
    font-weight: 500;
    .label {
      color: #acacac;
    }
  }
`;
export const QuantityAndButtnosWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  gap: 0.5rem;
  @media screen and (max-width: 425px) {
    // flex-direction: column;
    // align-items: flex-start;
  }
`;
export const QuantityWrapper = styled.div`
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  border: 1px solid #bdbdbd;
  border-radius: 25px;
  color: #707070;
  padding: 0 15px;
  color: #000;
  padding: 0 15px;
  width: 264px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  .MuiInput-underline {
    &::before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
    }
  }
  .MuiSelect-select:focus {
    background: white;
  }
`;
export const ButtonIncDec = styled.div`
  cursor: pointer;
  font-size: 33px;
  @media screen and (max-width: 425px) {
    font-size: 20px;
  }
`;

export const QuantityWrapperBody = styled.div`
  width: 70%;

  @media screen and (max-width: 425px) {
    .quantity {
      margin-right: 15px;
    }
    display: flex;
    align-items: center;
  }
`;
export const AddToCartButton = styled.button`
  display: flex;
  width: 250px;

  height: 40px;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0 15px;
  background: #8bc34a;
  color: #fff;
  margin-left: 20px;
  @media screen and (max-width: 425px) {
    margin-left: 0;
    margin-top: 15px;
  }
  .icon {
    padding-top: 5px;
  }
`;
export const OpenAccount = styled.button`
  display: flex;
  height: 40px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0 15px;
  background: #8bc34a;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
`;

export const HeartIcon = styled(FiHeart)`
  font-size: 30px;
  margin-right: 20px;
  cursor: pointer;
`;

export const ConatactCenterText = styled.p`
  font-size: 19px;
  margin-top: 15px;
  color: #848484;
`;
export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 15px;
`;

export const PhoneWrapper = styled.div`
  margin-left: 20px;
  font-size: 19px;
  color: #707070;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 460px;

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

export const PhoneIcon = styled(Phone)`
  color: #0787ea;
  @media screen and (max-width: 425px) {
    width: 30px;
  }
`;
export const Row = styled.div`
  display: flex;
`;
export const CustomAccordion = styled(Accordion)`
  border: 1px solid #e7e7e7;
  box-shadow: none !important;
  margin: 10px 0;
  border-radius: 5px;
  &.MuiPaper-root {
    :before {
      display: none;
    }
  }
  &.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 15px;
    
  }
  .MuiAccordionSummary-root {
    //padding: ${props => (props?.fromProduct ? '0' : '0 20px')};
    height: 20px;
    @media screen and (max-width: 425px) {
      height: auto;
    }
    min-height: 35px !important;
    @include media-max(500px) {
      widht: 95% !important;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
  .MuiAccordionSummary-content {
    margin: 20px 0;
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;

export const AccordionHeader = styled.h2`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  @media screen and (max-width: 425px) {
    font-size: 15px;
  }
  svg {
    width: ${props => (props?.fromProduct ? '35px' : ' 35px')};
    margin-right: 5px;
    height: ${props => (props?.fromProduct ? '35px' : ' 25px')};
  }
`;
const icon = css`
  margin-right: 10px;
  @media screen and (max-width: 425px) {
    width: 50px;
  }
`;
export const ShopIcon = styled(FcShop)`
  ${icon}
`;
export const ShippingIcon = styled(TruckIcon)`
  ${icon};
  svg {
    g {
      fill: ${props => (props?.fromProduct ? '#3f51b5' : 'black')} !important;
    }
  }
`;
export const LoginIcon = styled(Login)`
  ${icon};
  color: ${props => (props?.fromProduct ? '#3f51b5' : 'black')};
  @media screen and (max-width: 425px) {
    margin-right: 20px;
  }
`;
export const LocationIcon = styled(ShopingIcon)`
  ${icon};
  color: ${props => (props?.fromProduct ? '#3f51b5' : 'black')};
  @media screen and (max-width: 425px) {
    margin-right: 20px;
  }
`;

export const LocationWrapper = styled.div`
  h3 {
    color: #0787ea;
    margin-top: 15px;
  }
`;
export const CardIcon = styled(AiFillCreditCard)`
  ${icon};
  fill: ${props => (props?.fromProduct ? '#0787ea' : 'black')};
  @media screen and (max-width: 425px) {
    margin-right: 5px;
  }
`;
export const AccordionText = styled.div`
  font-size: 15px;
  padding: 10px 20px;
  color: black;
  width: 100%;
`;
export const HeaderText = styled.span`
  display: flex;
  font-size: 17px;

  font-weight: 400;
  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;
export const SmallHeaderText = styled.span`
  font-weight: 400;
  margin: 0 5px;
  color: #848484;
`;

export const Margin = styled.div`
  margin-bottom: 15px;
`;

export const LeftWrapper = styled.div``;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  text-align: center;
  align-items: center;
  h2,
  h4 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  @media screen and (max-width: 425px) {
    height: auto;
    div {
      max-width: 50%;
    }
  }
`;
export const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`;
export const ItemValue = styled.h4``;
export const List = styled.div`
  width: 100%;
  color: #707070;
  .item {
    border-bottom: 1px solid #e7e7e7;
  }
`;
export const ListSpec = styled.ul`
  li {
    list-style: inside;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const Note = styled.div`
  font-size: 13px;
  color: lightgray;
`;

export const MontlyLoanInstalment = styled.div`
  background: #f7f7f7;
  height: 80px;
  @media screen and (max-width: 425px) {
    height: auto;
    width: 100%;
    div {
      display: flex;
      flex-direction: column;
    }
  }
  font-size: 20px;
  @media screen and (min-width: 1025px) {
    margin-top: 50px;
  }
  padding: 10px;
`;

export const InfoIcon = styled(Info)`
  ${icon}
`;

export const CustomSelectQuantity = styled(Select)``;
