import Yup from 'utils/validations';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Ime mora sadržati najmanje 2 karaktera')
    .max(50, 'Ime može sadržati najviše 50 karaktera')
    .required('Ime je obavezno'),
  question: Yup.string()
    .min(2, 'Pitanje mora sadržati najmanje 2 karaktera')
    .max(1200, 'Pitanje može sadržati najviše 1200 karaktera')
    .required('Pitanje je obavezno')
});
