import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Image from 'components/Image/Loadable';
import { showDesktopOrMobileImage } from 'helpers/showDesktopOrMobileImage';
import { makeSelectBanners } from 'containers/App/selectors';

const Banner = ({ product }) => {
  const [banner, setBanner] = useState(null);
  const allBanners = useSelector(makeSelectBanners());
  const { push } = useHistory();

  const handleClick = () => {
    if (banner.link) window.location.href = banner.link;
    else push(`/proizvodi-na-akciji/${banner.id}`);
  };

  useEffect(() => {
    if (allBanners.length) {
      let found = allBanners.find(item => {
        if (
          item.position.name === 'Proizvod' &&
          item.category_id === product.mainCategory[0].id &&
          item.brand_id === product.brand.id
        )
          return item;
        else return null;
      });

      setBanner(found);
    }
  }, [allBanners, product]);

  return (
    <>
      {banner && (
        <div className="product_banner" onClick={handleClick}>
          <Image src={showDesktopOrMobileImage(banner)} />
        </div>
      )}
    </>
  );
};

export default Banner;
