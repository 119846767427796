import React, { useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from '../../messages';
import { FaTimesCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

const Preview = styled.div`
  background:url("${prop => prop && prop.sticker}");
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 200px;
  display: flex;
  height: 130px;
  justify-content: flex-end;
  svg{
    color:rgb(234, 84, 85);
    cursor:pointer;
    margin-right: -5px;
    margin-top: -5px;
  }
`;
const Input = styled.input`
  display: none;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 15px;
  }
`;
const StickerForm = ({ open, close, params, onSubmit, ids, clear }) => {
  const [error, setError] = useState('');
  const [sticker, setSticker] = useState('');
  const inputRef = useRef();
  const { formatMessage } = useIntl();
  const uploadSticker = e => {
    let files = e.target.files;
    if (files[0].size > 2000000) {
      setError(messages.photo_size_limit);
    } else {
      setError('');
      setSticker(files[0]);
    }
  };
  const handleSubmit = () => {
    onSubmit({ ids, sticker });
    setSticker('');
  };

  const handleClose = () => {
    setSticker('');
    close();
  };

  const removePhoto = () => {
    setSticker(null);
    setError('');
  };

  const handleOnClick = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };
  return (
    <Dialog open={open} onClose={close} className="mass_update_discounts_modal">
      <DialogTitle>{formatMessage(messages.add_sticker_title)}</DialogTitle>
      <DialogContent>
        {/* <Preview className="photo" sticker={sticker && URL.createObjectURL(sticker)}>
          <FaTimesCircle onClick={() => removePhoto()} />
        </Preview> */}
        {sticker ? (
          <PreviewWrapper>
            <Preview
              className="photo"
              sticker={sticker && URL.createObjectURL(sticker)}
            >
              <FaTimesCircle onClick={() => removePhoto()} />
            </Preview>
            <Wrapper>
              <Button className="button mt_20" onClick={handleSubmit}>
                {formatMessage(messages.create_sticker)}
              </Button>
              <Button className="button default  ml_20" onClick={handleClose}>
                {formatMessage(messages.close)}
              </Button>
            </Wrapper>
          </PreviewWrapper>
        ) : (
          <Wrapper>
            <Input
              ref={inputRef}
              type="file"
              title=""
              onChange={uploadSticker}
            />
            <Button className="button" onClick={handleOnClick}>
              {formatMessage(messages.choose_sticker)}
            </Button>
            <Button className="button default  ml_20" onClick={handleClose}>
              {formatMessage(messages.close)}
            </Button>
          </Wrapper>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default StickerForm;
