const KEY = '[CMS_AUTH_DISCOUNTS]';

const types = {
  GET_AUTH_DISCOUNTS: `${KEY} GET_AUTH_DISCOUNTS`,
  SET_AUTH_DISCOUNTS: `${KEY} SET_AUTH_DISCOUNTS`,

  GET_AUTH_DISCOUNT: `${KEY} GET_AUTH_DISCOUNT`,
  SET_AUTH_DISCOUNT: `${KEY} SET_AUTH_DISCOUNT`,
  GET_AUTH_DISCOUNT_ERROR: `${KEY} GET_AUTH_DISCOUNT_ERROR`,
  CLEAR_AUTH_DISCOUNT_DATA: `${KEY} CLEAR_AUTH_DISCOUNT_DATA`,

  CREATE_AUTH_DISCOUNT_REQUEST: `${KEY} CREATE_AUTH_DISCOUNT_REQUEST`,
  CREATE_AUTH_DISCOUNT_SUCCESS: `${KEY} CREATE_AUTH_DISCOUNT_SUCCESS`,
  CREATE_AUTH_DISCOUNT_ERROR: `${KEY} CREATE_AUTH_DISCOUNT_ERROR`,

  UPDATE_AUTH_DISCOUNT_REQUEST: `${KEY} UPDATE_AUTH_DISCOUNT_REQUEST`,
  UPDATE_AUTH_DISCOUNT_SUCCESS: `${KEY} UPDATE_AUTH_DISCOUNT_SUCCESS`,
  UPDATE_AUTH_DISCOUNT_ERROR: `${KEY} UPDATE_AUTH_DISCOUNT_ERROR`,

  DELETE_AUTH_DISCOUNT: `${KEY} DELETE_AUTH_DISCOUNT`,
  DELETE_AUTH_DISCOUNT_SUCCESS: `${KEY} DELETE_AUTH_DISCOUNT_SUCCESS`,

  SET_CURRENT_PAGE: `${KEY} SET_CURRENT_PAGE`,
  SET_PER_PAGE: `${KEY} SET_PER_PAGE`,
  DELETE_PARAMS: `${KEY} DELETE_PARAMS`
};

export default types;
