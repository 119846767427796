import React from 'react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import { Info } from '@material-ui/icons';
import Tooltip from '../Tooltip';

const FormikFieldIcon = ({
  type,
  name,
  label,
  errors,
  touched,
  placeholder,
  focus = true
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="input_holder">
      <div className="form_field">
        {touched[name] && errors[name] && (
          <Tooltip
            title={
              typeof errors[name] === 'object'
                ? formatMessage(errors[name])
                : errors[name]
            }
          >
            <Info />
          </Tooltip>
        )}
        <Field
          type={type}
          name={name}
          placeholder={placeholder && placeholder}
          autoFocus={focus}
        />
        <label>{formatMessage(label)}</label>
      </div>
    </div>
  );
};

export default FormikFieldIcon;
