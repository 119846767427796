import React from 'react';

const ShopIcon = ({ fromProduct = false }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="70.000000pt"
    height="50.000000pt"
    viewBox="0 0 50.000000 50.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
      fill={fromProduct ? '#0787ea' : '#000000'}
      stroke="none"
    >
      <path
        d="M44 448 c-9 -14 -7 -385 2 -399 7 -13 224 -12 224 1 0 6 -42 10 -105
10 l-105 0 0 100 c0 93 1 100 20 101 11 0 88 1 170 1 83 0 158 -1 168 -1 10
-1 23 -13 28 -28 9 -25 11 -21 17 37 3 36 3 92 -1 125 l-7 60 -202 3 c-153 2
-203 -1 -209 -10z m396 -28 c0 -19 -7 -20 -190 -20 -183 0 -190 1 -190 20 0
19 7 20 190 20 183 0 190 -1 190 -20z m-342 -87 c-3 -40 -6 -48 -23 -48 -13 0
-21 8 -23 24 -6 40 7 71 29 71 18 0 20 -5 17 -47z m70 0 c-3 -40 -6 -48 -23
-48 -17 0 -20 8 -23 48 -3 45 -2 47 23 47 25 0 26 -2 23 -47z m70 0 c-3 -40
-6 -48 -23 -48 -17 0 -20 8 -23 48 -3 45 -2 47 23 47 25 0 26 -2 23 -47z m70
0 c-3 -40 -6 -48 -23 -48 -17 0 -20 8 -23 48 -3 45 -2 47 23 47 25 0 26 -2 23
-47z m70 0 c-3 -40 -6 -48 -23 -48 -17 0 -20 8 -23 48 -3 45 -2 47 23 47 25 0
26 -2 23 -47z m68 23 c10 -41 1 -71 -21 -71 -17 0 -20 8 -23 48 -3 42 -1 47
17 47 13 0 23 -8 27 -24z"
      />
      <path
        d="M70 420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M100 420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M130 420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path
        d="M362 218 c-7 -7 -12 -20 -12 -30 0 -12 -8 -18 -24 -18 -23 0 -25 -4
-31 -74 -4 -41 -4 -79 -1 -85 9 -15 193 -15 202 0 3 6 3 44 -1 85 -6 67 -9 74
-29 74 -15 0 -25 9 -33 30 -12 32 -48 41 -71 18z m56 -31 c3 -12 -3 -17 -22
-17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z m56 -83 c12 -89 16 -84 -79 -84
l-85 0 0 28 c0 15 3 44 6 65 l7 37 72 0 72 0 7 -46z"
      />
    </g>
  </svg>
);

export default ShopIcon;
