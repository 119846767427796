import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from './messages';
import useOutsideClick from 'helpers/useOutsideClick';
import EnFlag from 'assets/images/Flags/en';
import SrFlag from 'assets/images/Flags/sr';
import { setItem } from 'utils/localStorage';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { activeClass } from 'helpers/activeClass';

const Language = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdown = useRef(null);
  const dispatch = useDispatch();
  const activeLang = useSelector(makeSelectLocale());
  const { formatMessage } = useIntl();

  useOutsideClick(dropdown, () => {
    if (isVisible) toggle();
  });

  const toggle = () => setIsVisible(!isVisible);

  const changeLang = selected => {
    setItem('language', selected);
    dispatch(changeLocale(selected));
    toggle();
  };

  return (
    <div className="language_dropdown_wrap">
      <div className="language_dropdown_holder">
        <div className="root" onClick={toggle}>
          {activeLang === 'en' ? (
            <>
              <EnFlag /> {formatMessage(messages.lang_en)}
            </>
          ) : (
            <>
              <SrFlag /> {formatMessage(messages.lang_sr)}
            </>
          )}
        </div>
        {isVisible && (
          <ul className="dropdown_wrap" ref={dropdown}>
            <li
              onClick={() => changeLang('sr')}
              className={activeClass('', activeLang === 'sr')}
            >
              <SrFlag /> {formatMessage(messages.lang_sr)}
            </li>
            <li
              onClick={() => changeLang('en')}
              className={activeClass('', activeLang === 'en')}
            >
              <EnFlag /> {formatMessage(messages.lang_en)}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Language;
