import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { toggle } from 'helpers/beautifulDnd';

const Subcategories2 = ({ subcategory, close }) => {
  const { push } = useHistory();
  const [opened, setOpened] = useState([]);
  const handleClick = (item, haveSubcategories) => {
    if(haveSubcategories){
      return toggle(item.id, opened, setOpened)
    }
    push(`/kategorije/${item.slug}`);
    close();
    const body = document.querySelector('body');
    const mobile_bar = document.querySelector('.mobile_bar');
    body.removeAttribute('style');
    mobile_bar.removeAttribute('style');
  };

  return (
    <>
      {subcategory.subcategories.map(subcategory2 => (
        <div key={subcategory2.id}>
          <div
            className="item_holder level_3"
            onClick={() => handleClick(subcategory2,subcategory2.subcategories.length )}
          >
            <p>{subcategory2.name}</p>
            {subcategory2.subcategories.length ?
              <span onClick={() => handleClick(subcategory2)}>
              <BsArrowRight />
            </span>  
            :null
            }
          </div>
          {opened.includes(subcategory2.id) && (
            <Subcategories2 subcategory={subcategory2} close={close} />
          )}
        </div>
      ))}
    </>
  );
};

export default Subcategories2;
