import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronRight, FormatListBulleted } from '@material-ui/icons';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useOutsideClick from 'helpers/useOutsideClick';
import Masonry from 'react-masonry-css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';
const CustomAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: none;
    box-shadow: none;
    padding-right: 0;
    &:before {
      display: none;
    }
  }
  .MuiButtonBase-root {
    padding-right: 0;
  }
  .MuiButtonBase-root {
    min-height: 25.19px;
    height: 25.19px;
    padding-left: 0;
    border: none;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 25.19px;
    height: 25.19px;
    padding-right: 0;
  }
  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }
`;
const Menu = ({ categories }) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdowns = useRef(null);
  const { push } = useHistory();
  useOutsideClick(dropdowns, () => {
    if (isVisible) toggle(false);
  });
  const toggle = () => setIsVisible(!isVisible);
  const handleClick = item => {
    push(`/kategorije/${item.slug}`);
    toggle();
  };
  return (
    <div className="root_navigation_wrapper">
      <div className="root_navigation">
        <button onClick={toggle}>
          {/* <FormatListBulleted /> */}
          Sve Kategorije
          <ExpandMoreIcon />
        </button>
        {isVisible && categories.length ? (
          <div className="category_menu_inner" ref={dropdowns}>
            <div className="category_menu_outer">
              {categories.map(category => (
                <div key={category.id} className="category_menu_item_inner">
                  {category.id !== 116 ? (
                    <div>
                      <div
                        className="category_menu_item_outer"
                        onClick={() => handleClick(category)}
                      >
                        {category.name} <ChevronRight />
                      </div>
                      <div className="category_item_own_subcategories_inner">
                        <div className="category_item_own_subcategories_outer">
                          <Masonry
                            breakpointCols={3}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {category.subcategories.map(subcategory => (
                              <div
                                key={subcategory.id}
                                className="subcategory_item_wrap"
                              >
                                <h4 onClick={() => handleClick(subcategory)}>
                                  {subcategory.name}
                                </h4>
                                {subcategory.subcategories.map(subcategory2 => {
                                  if (subcategory2.subcategories.length) {
                                    return (
                                      <CustomAccordion key={subcategory2.id}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMore />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <p
                                            onClick={() =>
                                              handleClick(subcategory2)
                                            }
                                          >
                                            {subcategory2.name}
                                          </p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {subcategory2.subcategories.map(
                                            subcategory3 => (
                                              <div
                                                key={subcategory3.id}
                                                onClick={() =>
                                                  handleClick(subcategory3)
                                                }
                                              >
                                                <p>{subcategory3.name}</p>
                                              </div>
                                            )
                                          )}
                                        </AccordionDetails>
                                      </CustomAccordion>
                                    );
                                  }
                                  return (
                                    <div
                                      key={subcategory2.id}
                                      onClick={() => handleClick(subcategory2)}
                                    >
                                      <p>{subcategory2.name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                          </Masonry>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="category_menu_item_outer"
                        onClick={() => handleClick(category)}
                      >
                        {category.name} <ChevronRight />
                      </div>
                      <div className="category_item_own_subcategories_inner">
                        <div className="category_item_own_subcategories_outer">
                          <Masonry
                            breakpointCols={3}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {category.subcategories.map(subcategory => (
                              <div
                                key={subcategory.id}
                                className="subcategory_item_wrap"
                              >
                                <h4
                                  style={{ fontWeight: '900' }}
                                  onClick={() => handleClick(subcategory)}
                                >
                                  {subcategory.name}
                                </h4>
                                {subcategory.subcategories.map(subcategory2 => {
                                  if (subcategory2.subcategories.length) {
                                    return (
                                      <CustomAccordion key={subcategory2.id}>
                                        <div>
                                          <div>
                                            <p
                                              style={{ fontWeight: '700' }}
                                              onClick={() =>
                                                handleClick(subcategory2)
                                              }
                                            >
                                              {subcategory2.name}
                                            </p>
                                            {subcategory2.subcategories.map(
                                              subcategory4 => {
                                                return (
                                                  <p
                                                    onClick={() =>
                                                      handleClick(subcategory4)
                                                    }
                                                  >
                                                    {subcategory4.name}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </CustomAccordion>
                                    );
                                  }
                                  return (
                                    <div
                                      key={subcategory2.id}
                                      onClick={() => handleClick(subcategory2)}
                                    >
                                      <p>{subcategory2.name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                          </Masonry>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Menu;
