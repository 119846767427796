import produce from 'immer';
import types from './constants';

export const initialState = {
  favoriteItems: []
};

/* eslint-disable default-case */
const favoriteReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_FAVORITE_ITEMS:
        draft.favoriteItems = action.payload;
        break;
    }
  });

export default favoriteReducer;
