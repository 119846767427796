import React from 'react';
import CookieConsent from 'react-cookie-consent';

const AcceptCookie = () => (
  <CookieConsent buttonText="Slažem se">
    <p>
      Koristimo kolačiće da bismo vam obezbedili najbolje iskustvo. Korišćenjem
      naše web stranice pristajete na našu politiku kolačića.
    </p>
  </CookieConsent>
);

export default AcceptCookie;
