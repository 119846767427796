import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Calendar from 'cms/components/Calendar';
import { momentFormatDate } from 'helpers/momentFormatDate';
import messages from '../messages';

const DateRange = ({ params, setParams }) => {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (params.discount_from) setFrom(new Date(params.discount_from));
    else setFrom(new Date());

    if (params.discount_to) setTo(new Date(params.discount_to));
    else setTo(new Date());
  }, [params]);

  const handleFrom = date => {
    setFrom(date);
    setParams({ ...params, discount_from: momentFormatDate(date) });
  };

  const handleTo = date => {
    setTo(date);
    setParams({ ...params, discount_to: momentFormatDate(date) });
  };

  return (
    <div className="data_pickers">
      <Calendar
        name="discount_from"
        label={formatMessage(messages.discount_from)}
        value={from}
        setValue={handleFrom}
      />
      <Calendar
        name="discount_to"
        label={formatMessage(messages.discount_to)}
        value={to}
        setValue={handleTo}
      />
    </div>
  );
};

export default DateRange;
