import Yup from 'utils/validations';
import messages from '../messages';
import { number, string } from 'yup';

export const schemaPrice = Yup.object().shape({
  name: Yup.string()
    .min(2, messages.yup_validation_name_min)
    .max(255, messages.yup_validation_name_max)
    .required(messages.yup_validation_name_required)
});
