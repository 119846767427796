import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppBar from 'components/AppBar';
import HelperIconsNav from 'components/HelperIconsNav';
import SideBar from 'cms/SideBar';
import NavBar from 'cms/NavBar';
import Footer from 'components/Footer';
import Routes from './Routes';
import { makeSelectModules } from './selectors';
import { getItem } from 'utils/localStorage';

const Wrap = ({ user }) => {
  const [sideBarIsVisible, setSideBarIsVisible] = useState(false);
  const { pathname } = useLocation();
  const isAdminPanel = pathname.includes('admin');
  const selectModules = useSelector(makeSelectModules());
  const modules = getItem('modules') || selectModules;

  return (
    <>
      {!isAdminPanel ? (
        <>
          {modules.includes('Frontend') && (
            <>
              <AppBar />
              <HelperIconsNav />
            </>
          )}
        </>
      ) : (
        <>
          <SideBar active={sideBarIsVisible} setActive={setSideBarIsVisible} />
          <NavBar
            user={user}
            statusSidebar={sideBarIsVisible}
            toggleSideBar={setSideBarIsVisible}
          />
        </>
      )}
      <main className={isAdminPanel ? 'cms_content' : 'content'}>
        <Routes />
      </main>
      {!isAdminPanel && modules.includes('Frontend') && <Footer />}
    </>
  );
};

export default Wrap;
