import types from './constants';

export const fetchAuthenticatedUser = () => ({
  type: types.FETCH_AUTHENTICATED_USER_REQUEST
});

export const fetchAuthenticatedUserSuccess = user => ({
  type: types.FETCH_AUTHENTICATED_USER_SUCCESS,
  user
});

export const setUsersAddresses = payload => ({
  type: types.SET_USERS_ADDRESSES,
  payload
});

export const setUsersOrders = payload => ({
  type: types.SET_USERS_ORDERS,
  payload
});

export const logout = () => ({
  type: types.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const setToken = token => ({
  type: types.SET_TOKEN,
  token
});

export const getModules = () => ({
  type: types.GET_MODULES
});

export const setModules = payload => ({
  type: types.SET_MODULES,
  payload
});

export const getBanners = () => ({
  type: types.GET_BANNERS
});

export const setBanners = payload => ({
  type: types.SET_BANNERS,
  payload
});

export const getCartCookie = () => ({
  type: types.GET_CART_COOKIE
});

export const setCartCookie = payload => ({
  type: types.SET_CART_COOKIE,
  payload
});

export const getWishListCookie = () => ({
  type: types.GET_WISH_LIST_COOKIE
});

export const setWishListCookie = payload => ({
  type: types.SET_WISH_LIST_COOKIE,
  payload
});

export const transferCartOnAuth = () => ({
  type: types.TRANSFER_CART_ON_AUTH
});
