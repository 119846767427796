import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

const UsefulLinks = () => (
  <>
    <Link to={routes.TERMS_AND_CONDITIONS}>Uslovi korišćenja web shop-a</Link>
    <Link to={routes.PRIVACY_POLICY}>Politika privatnosti</Link>
    <Link to={routes.TERMS_AND_CONDITIONS}>Prava potrošača</Link>
    <Link to={routes.TERMS_AND_CONDITIONS}>Reklamacioni list</Link>
    <Link to={routes.TERMS_AND_CONDITIONS}>Raskid ugovora</Link>
    <Link to={routes.HELP}>Online naručivanje</Link>
    <Link to={routes.CREDITS}>Svi načini plaćanja</Link>
    <Link to={routes.TERMS_AND_PRICES_OF_DELIVERY}>Isporuka robe</Link>
    <Link to={routes.JOBS}>Posao u Jakov Sistemu</Link>
  </>
);

export default UsefulLinks;
