import types from './constants';

export const getNotifications = (user_id, params) => ({
  type: types.GET_NOTIFICATIONS,
  user_id,
  params
});

export const setNotifications = payload => ({
  type: types.SET_NOTIFICATIONS,
  payload
});

export const getUnreadNotifications = user_id => ({
  type: types.GET_UNREAD_NOTIFICATIONS,
  user_id
});

export const setUnreadNotifications = payload => ({
  type: types.SET_UNREAD_NOTIFICATIONS,
  payload
});

export const readNotification = (user_id, notification_id) => ({
  type: types.READ_NOTIFICATION,
  user_id,
  notification_id
});

export const readAllNotifications = user_id => ({
  type: types.READ_ALL_NOTIFICATIONS,
  user_id
});

export const deleteNotification = (user_id, notification_id) => ({
  type: types.DELETE_NOTIFICATION,
  user_id,
  notification_id
});

export const deleteAllNotifications = user_id => ({
  type: types.DELETE_ALL_NOTIFICATIONS,
  user_id
});
