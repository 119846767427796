import { takeLatest, call, put } from 'redux-saga/effects';

import request from 'utils/request';
import types from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';

export function* newsletterSubscribe({ email }) {
  try {
    const { data } = yield call(request, {
      url: 'newsletter/subscribe',
      method: 'post',
      data: { email }
    });

    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}

export default function* newsletterSubscribeSaga() {
  yield takeLatest(types.NEWSLETTER_SUBSCRIBE, newsletterSubscribe);
}
