import React from 'react';
import { FaShippingFast, FiPhoneCall } from 'react-icons/all';
import { getItem } from 'utils/localStorage';
import Prices from './Prices_backup';
import Quantity from './Quantity_backup';
import TicketCard from './TicketCard';
import Buttons from './Buttons_backup';
import Photo from 'assets/images/24rate.webp';
import OutOfStock from './OutOfStock';
import Banner from '../Banner';

const Details = ({ product, quantity, setQuantity, onStock }) => {
  const { name, sku } = product;
  const token = getItem('token');

  return (
    <div className="product_details">
      <h2 className="product_name">{name}</h2>
      <p className="product_sku">Šifra proizvoda: {sku}</p>
      {onStock ? (
        <>
          <div className="details_inner">
            <div className="left">
              {token && (
                <h4 className="registered_info">
                  Cene su sa ulogovanim popustom
                </h4>
              )}
              <Prices product={product} />
              <Quantity quantity={quantity} setQuantity={setQuantity} />
              {!token && <TicketCard />}
            </div>
            {token && (
              <h4 className="mobile_registered_info">
                Cene su sa ulogovanim popustom
              </h4>
            )}
            <Buttons
              product_id={product.id}
              quantity={quantity}
              setQuantity={setQuantity}
            />
            <div className="right">
              <div className="row">
                <FaShippingFast />
                <p>Isporuka na teritoriji cele Srbije</p>
              </div>
              <div className="row phones">
                <FiPhoneCall />
                <div>
                  <a href="tel: 018 41 55 230">018 41 55 230</a>
                  <span>/</span>
                  <a href="tel: 018 41 55 230">018 41 55 230</a>
                  <span>/</span>
                  <a href="tel: 018 41 55 230">018 41 55 230</a>
                </div>
              </div>
              <div className="installments_without_interest">
                <img src={Photo} alt="Jakov Sistem 24 rate bez kamate" />
                <p>Na 24 rate bez kamate</p>
              </div>
            </div>
          </div>
          <Buttons
            product_id={product.id}
            quantity={quantity}
            setQuantity={setQuantity}
          />
          <Banner product={product} />
        </>
      ) : (
        <OutOfStock product_id={product.id} />
      )}
    </div>
  );
};

export default Details;
