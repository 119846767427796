import { Button, Card, Link } from '@material-ui/core';
import React, { useState } from 'react';
import config from '../../../config';
import { HiLink, HiOutlineX } from 'react-icons/hi';

export default function DeleteModal({ selected, setModal, deletePage }) {
  return (
    <div className="delete_modal">
      <Card className="body">
        <h2>Izbriši Landing stranicu?</h2>
        <p>{selected?.name}</p>
        <Link
          className="link"
          href={window.location.origin + '/' + selected?.slug}
        >
          <div>
            <HiLink />
            {selected?.slug}
          </div>
        </Link>
        <br />
        <div className="buttons">
          <Button
            className="izbrisi"
            onClick={() => {
              deletePage(selected?.id);
              setModal(false);
            }}
          >
            Izbriši
          </Button>
          <Button
            className="nazad"
            onClick={() => {
              setModal(false);
            }}
          >
            Nazad
          </Button>
        </div>
      </Card>
    </div>
  );
}
