import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaSkype } from 'react-icons/fa';
import Image from 'components/Image/Loadable';
import CardsPhoto from 'assets/images/cards.png';
import variables from 'utils/variables';
import Newsletter from './Newsletter';
import routes from 'routes';
import ScrollToTop from 'ScrollToTop';

const BottomSide = () => {
  const pushToTop = () => {
    const body = document.querySelector('body');
    body.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="footer_bottom_side_wrap">
      <ScrollToTop />
      <div className="container">
        <div className="footer_bottom_side_holder">
          <Newsletter />
          <nav>
            <Link onClick={pushToTop} to={routes.ACTIONS}>
              Akcije
            </Link>
            <Link onClick={pushToTop} to={routes.CREDITS}>
              24 rate
            </Link>
            <Link onClick={pushToTop} to={routes.EQUIP_OFFICE}>
              Pravna lica
            </Link>
            <Link onClick={pushToTop} to={routes.NEWS}>
              Vesti
            </Link>
            <Link onClick={pushToTop} to={routes.SERVICE}>
              Servis
            </Link>
          </nav>
          <div className="footer_bottom_side_content">
            <div className="copy_rights">
              <p>Powered by Jakov Smart Solutions</p>
            </div>
            <ul className="list_of_links">
              <li>
                <a
                  href={variables.facebook_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="facebook link"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href={variables.instagram_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="instagram link"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href={variables.skype_link} aria-label="skype link">
                  <FaSkype />
                </a>
              </li>
            </ul>
            <div className="payment_details">
              <Image src={CardsPhoto} />
              <p>Plaćanje karticama moguće je u radnjama.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSide;
