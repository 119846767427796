import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { activeClass } from 'helpers/activeClass';

const Status = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  const toggle = name => {
    if (params[name]) {
      const { [name]: tmp, ...rest } = params;
      setParams(rest);
    } else {
      setParams({ ...params, [name]: 1 });
    }
  };

  return (
    <div className="group">
      <h3>{formatMessage(messages.status)}</h3>
      <div className="form_checkbox" onClick={() => toggle('active')}>
        <div className={activeClass('box', params.active === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.filter_active)}</label>
      </div>
      <div className="form_checkbox" onClick={() => toggle('inactive')}>
        <div className={activeClass('box', params.inactive === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.filter_inactive)}</label>
      </div>
    </div>
  );
};

export default Status;
