import types from './constants';

export const fetchCategories = () => ({
  type: types.FETCH_CATEGORIES
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const search = q => ({
  type: types.SEARCH,
  q
});

export const setSearchResults = payload => ({
  type: types.SET_SEARCH_RESULTS,
  payload
});

export const firstClick = () => ({
  type: types.FIRST_CLICK
});
