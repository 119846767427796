import { takeLatest, call, put, select } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import types from './constants';
import { setFavoriteItems } from './actions';
import {
  makeSelectCartCookie,
  makeSelectUser,
  makeSelectWishListCookie
} from 'containers/App/selectors';
import { setItems } from 'views/ShoppingCart/redux/actions';

const BASE_URL = 'cart/items';

const cookies = new Cookies();
const cookieFavoriteUserId = cookies.get('favorite_user_id');
const cookieUserId = cookies.get('user_id');

export function* fetchFavoriteItems() {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  let id = cookieFavoriteUserId || reduxWishListCookie;
  const cart_id = user ? user.id : id;

  try {
    const { data } = yield call(request, {
      url: 'cart',
      method: 'get',
      params: {
        instance: 'wishlist',
        cart_id: cart_id
      }
    });

    const activeTotal = data.items.reduce((total, item) => {
      if (item.product.active) {
        return total + item.price * item.quantity;
      }
      return total;
    }, 0);

    yield put(setFavoriteItems({ ...data, total: activeTotal }));
  } catch (error) {}
}

export function* addFavoriteItem({ payload }) {
  const { product_id, quantity } = payload;
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  let id = cookieFavoriteUserId || reduxWishListCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'post',
      data: {
        cart_id,
        product_id,
        quantity,
        instance: 'wishlist'
      }
    });

    yield put(enqueueSnackbar({ message: 'Proizvod dodat u listu želja!' }));
    yield put(setFavoriteItems(data.cart));
  } catch (error) {}
}

export function* updateFavoriteItem({ product_id, quantity }) {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  let id = cookieFavoriteUserId || reduxWishListCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'patch',
      data: {
        cart_id,
        instance: 'wishlist',
        product_id,
        quantity
      }
    });

    yield put(setFavoriteItems(data.cart));
  } catch (error) {}
}

export function* deleteFavoriteItem({ product_id }) {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  let id = cookieFavoriteUserId || reduxWishListCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'delete',
      data: {
        cart_id,
        product_id,
        instance: 'wishlist'
      }
    });

    yield put(setFavoriteItems(data.cart));
  } catch (error) {}
}

export function* clearFavoriteItems() {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  let id = cookieFavoriteUserId || reduxWishListCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    yield call(request, {
      url: 'cart',
      method: 'delete',
      data: {
        cart_id,
        instance: 'wishlist'
      }
    });

    yield put(setFavoriteItems({ cart: { total: 0, items: [] } }));
  } catch (error) {}
}

export function* passItemToCart({ product_id }) {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let idCart = cookieUserId || reduxWishListCookie;
  let idWishList = cookieFavoriteUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : idCart;
  const wishlist_id = user ? user.id.toString() : idWishList;

  try {
    const { data } = yield call(request, {
      url: 'wishlist-to-cart/single',
      method: 'post',
      data: {
        cart_id,
        wishlist_id,
        product_id
      }
    });

    yield put(setFavoriteItems(data.wishlist));
    yield put(setItems(data.cart));
  } catch (error) {}
}

export function* passAllToCart() {
  const user = yield select(makeSelectUser());
  const reduxWishListCookie = yield select(makeSelectWishListCookie());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let idCart = cookieUserId || reduxWishListCookie;
  let idWishList = cookieFavoriteUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : idCart;
  const wishlist_id = user ? user.id.toString() : idWishList;

  try {
    const { data } = yield call(request, {
      url: 'wishlist-to-cart',
      method: 'post',
      data: {
        cart_id,
        wishlist_id
      }
    });

    const activeItems = data.cart.items.filter(
      item => item.product.active === true
    );

    const totalActive = activeItems.reduce(
      (total, item) => total + item.price,
      0
    );

    const newWishList = data.cart.items.filter(
      item => item.product.active === false
    );

    const totalWishList = newWishList.reduce(
      (total, item) => total + item.price,
      0
    );

    yield put(setFavoriteItems({ total: totalWishList, items: newWishList }));
    yield put(setItems({ total: totalActive, items: activeItems }));
  } catch (error) {}
}

export default function* favoriteSaga() {
  yield takeLatest(types.GET_FAVORITE_ITEMS, fetchFavoriteItems);
  yield takeLatest(types.ADD_FAVORITE_ITEM, addFavoriteItem);
  yield takeLatest(types.UPDATE_FAVORITE_ITEM, updateFavoriteItem);
  yield takeLatest(types.DELETE_FAVORITE_ITEM, deleteFavoriteItem);
  yield takeLatest(types.CLEAR_FAVORITE_ITEMS, clearFavoriteItems);
  yield takeLatest(types.PASS_ITEM_TO_CART, passItemToCart);
  yield takeLatest(types.PASS_ALL_TO_CART, passAllToCart);
}
