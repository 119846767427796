import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { BsArrowRight } from 'react-icons/bs';

import { activeClass } from 'helpers/activeClass';
import Subcategories1 from './Subcategories1';
import { toggle } from 'helpers/beautifulDnd';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const MobileNavbar = ({ open, close, categories }) => {
  const [opened, setOpened] = useState([]);
  const { push } = useHistory();

  const handleClose = () => {
    close();
    const body = document.querySelector('body');
    const mobile_bar = document.querySelector('.mobile_bar');
    body.removeAttribute('style');
    mobile_bar.removeAttribute('style');
  };
  const handleClick = item => {
    push(`/kategorije/${item.slug}`);
    handleClose();
  };

  return (
    <>
      <div
        className={activeClass('mobile_navbar_mask', open)}
        onClick={close}
      />
      <div className={activeClass('mobile_navbar_wrapper', open)}>
        <div
          className="mobile_navbar_holder"
          onClick={e => e.stopPropagation()}
        >
          <div className="items">
            {categories.length
              ? categories.map(category => (
                  <div key={category.id}>
                    <div className="item_holder">
                      {category.id == opened ? (
                        <div>
                          <p onClick={() => handleClick(category)}>
                            {category.name}
                          </p>
                          <span
                            onClick={() =>
                              toggle(category.id, opened, setOpened)
                            }
                          >
                            <IoIosArrowUp />
                          </span>
                        </div>
                      ) : (
                        <div>
                          <p onClick={() => handleClick(category)}>
                            {category.name}
                          </p>
                          <span
                            onClick={() =>
                              toggle(category.id, opened, setOpened)
                            }
                          >
                            <IoIosArrowDown />
                          </span>
                        </div>
                      )}
                    </div>
                    {opened.includes(category.id) && (
                      <Subcategories1 category={category} close={close} />
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
