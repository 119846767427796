import types from './constants';

export const getAuthDiscounts = params => ({
  type: types.GET_AUTH_DISCOUNTS,
  params
});

export const setAuthDiscounts = payload => ({
  type: types.SET_AUTH_DISCOUNTS,
  payload
});

export const getAuthDiscount = id => ({
  type: types.GET_AUTH_DISCOUNT,
  id
});

export const setAuthDiscount = payload => ({
  type: types.SET_AUTH_DISCOUNT,
  payload
});

export const getAuthDiscountError = error => ({
  type: types.GET_AUTH_DISCOUNT_ERROR,
  error
});

export const clearAuthDiscountData = () => ({
  type: types.CLEAR_AUTH_DISCOUNT_DATA
});

export const createAuthDiscount = (payload, setErrors) => ({
  type: types.CREATE_AUTH_DISCOUNT_REQUEST,
  payload,
  meta: { setErrors }
});

export const createAuthDiscountSuccess = () => ({
  type: types.CREATE_AUTH_DISCOUNT_SUCCESS
});

export const createAuthDiscountError = error => ({
  type: types.CREATE_AUTH_DISCOUNT_ERROR,
  error
});

export const updateAuthDiscount = (id, payload, setErrors) => ({
  type: types.UPDATE_AUTH_DISCOUNT_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateAuthDiscountSuccess = () => ({
  type: types.UPDATE_AUTH_DISCOUNT_SUCCESS
});

export const updateAuthDiscountError = error => ({
  type: types.UPDATE_AUTH_DISCOUNT_ERROR,
  error
});

export const deleteAuthDiscount = id => ({
  type: types.DELETE_AUTH_DISCOUNT,
  id
});

export const deleteAuthDiscountSuccess = () => ({
  type: types.DELETE_AUTH_DISCOUNT_SUCCESS
});

export const setCurrentPage = payload => ({
  type: types.SET_CURRENT_PAGE,
  payload
});

export const setPerPage = payload => ({
  type: types.SET_PER_PAGE,
  payload
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});
