const KEY = '[SHOPPING_CART]';

const types = {
  GET_ITEMS: `${KEY} GET_ITEMS`,
  SET_ITEMS: `${KEY} SET_ITEMS`,

  ADD_ITEM: `${KEY} ADD_ITEM`,
  UPDATE_ITEM: `${KEY} UPDATE_ITEM`,
  DELETE_ITEM: `${KEY} DELETE_ITEM`,

  CLEAR_CART: `${KEY} CLEAR_CART`
};

export default types;
