import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';

const TicketCard = () => {
  return (
    <div className="ticket_card">
      <h2>Klik do popusta</h2>
      <p>
        Ulogujte se i ostvarite i do <strong>20% popusta</strong>
      </p>
      <Link to={routes.REGISTER}>Otvori nalog</Link>
    </div>
  );
};

export default TicketCard;
