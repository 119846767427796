import { takeLatest, call, put, select } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import types from './constants';
import { setItems } from './actions';
import { makeSelectUser, makeSelectCartCookie } from 'containers/App/selectors';

const BASE_URL = 'cart/items';

const cookies = new Cookies();
const cookieUserId = cookies.get('user_id');

export function* fetchItems() {
  const user = yield select(makeSelectUser());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let id = cookieUserId || reduxCartCookie;
  const cart_id = user ? user.id : id;

  try {
    const { data } = yield call(request, {
      url: 'cart',
      method: 'get',
      params: {
        instance: 'cart',
        cart_id: cart_id
      }
    });
    yield put(setItems(data));
  } catch (error) {}
}

export function* handleAddItem({ payload }) {
  const { product_id, quantity } = payload;
  const user = yield select(makeSelectUser());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let id = cookieUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'post',
      data: {
        cart_id,
        product_id,
        quantity,
        instance: 'cart'
      }
    });

    yield put(enqueueSnackbar({ message: data.message }));
    yield put(setItems(data.cart));
  } catch (error) {}
}

export function* handleUpdateItem({ product_id, quantity }) {
  const user = yield select(makeSelectUser());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let id = cookieUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'patch',
      data: {
        cart_id,
        instance: 'cart',
        product_id,
        quantity
      }
    });

    yield put(setItems(data.cart));
  } catch (error) {}
}

export function* handleDeleteItem({ product_id }) {
  const user = yield select(makeSelectUser());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let id = cookieUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    const { data } = yield call(request, {
      url: BASE_URL,
      method: 'delete',
      data: {
        cart_id,
        product_id,
        instance: 'cart'
      }
    });

    yield put(setItems(data.cart));
  } catch (error) {}
}

export function* clearCart() {
  const user = yield select(makeSelectUser());
  const reduxCartCookie = yield select(makeSelectCartCookie());
  let id = cookieUserId || reduxCartCookie;
  const cart_id = user ? user.id.toString() : id;

  try {
    yield call(request, {
      url: 'cart',
      method: 'delete',
      data: {
        cart_id,
        instance: 'cart'
      }
    });

    yield put(setItems({ cart: { total: 0, items: [] } }));
  } catch (error) {}
}

export default function* shoppingCartSaga() {
  yield takeLatest(types.GET_ITEMS, fetchItems);
  yield takeLatest(types.ADD_ITEM, handleAddItem);
  yield takeLatest(types.UPDATE_ITEM, handleUpdateItem);
  yield takeLatest(types.DELETE_ITEM, handleDeleteItem);
  yield takeLatest(types.CLEAR_CART, clearCart);
}
