const API_CLIENT_TYPES = '[CMS_API_CLIENT_TYPES]';

const types = {
  GET_CATEGORIES: `${API_CLIENT_TYPES} GET_CATEGORIES`,
  GET_DATA: `${API_CLIENT_TYPES} GET_DATA`,
  SET_DATA: `${API_CLIENT_TYPES} SET_DATA`,
  SET_CATEGORIES: `${API_CLIENT_TYPES} SET_CATEGORIES`,

  ADD_DATA_REQUEST: `${API_CLIENT_TYPES} ADD_DATA_REQUEST`,
  ADD_DATA_SUCCESS: `${API_CLIENT_TYPES} ADD_DATA_SUCCESS`,
  ADD_DATA_ERROR: `${API_CLIENT_TYPES} ADD_DATA_ERROR`,

  UPDATE_DATA_REQUEST: `${API_CLIENT_TYPES} UPDATE_DATA_REQUEST`,
  UPDATE_DATA_SUCCESS: `${API_CLIENT_TYPES} UPDATE_DATA_SUCCESS`,
  UPDATE_DATA_ERROR: `${API_CLIENT_TYPES} UPDATE_DATA_ERROR`,

  SET_ACTIVE: `${API_CLIENT_TYPES} SET_ACTIVE`
};

export default types;
