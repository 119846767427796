import React, { useState } from 'react';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

const FormikField = ({
  type,
  name,
  label,
  errors,
  touched,
  focus = false,
  readOnly = false,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  return (
    <div className="input_holder">
      <div className="form_field">
        <Field
          type={type}
          name={name}
          autoFocus={focus}
          readOnly={readOnly}
          {...rest}
        />
        <label htmlFor={name}>
          {typeof label === 'object' ? formatMessage(label) : label}
        </label>
      </div>
      {errors && errors[name] && touched[name] && (
        <p className="error_msg">
          {typeof errors[name] === 'object'
            ? formatMessage(errors[name])
            : errors[name]}
        </p>
      )}
    </div>
  );
};

export default FormikField;
