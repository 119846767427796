import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import {
  setBrands,
  setSuppliers,
  setCategories,
  setDiscountsTypes,
  setAttributes,
  setManufacturers,
  setStickerForProducts
} from './actions';
import types from './constants';

export function* fetchBrands({ payload, paginate }) {
  try {
    const res = yield call(request, {
      url: `admin/brands?paginate=${paginate}&search=${payload}`,
      method: 'get'
    });
    localStorage.setItem('brands', JSON.stringify(res.data));
    yield put(setBrands(res.data));
  } catch (error) {}
}

export function* fetchManufactures({ payload, paginate }) {
  try {
    const res = yield call(request, {
      url: `admin/attributes/24/values?paginate=${paginate}&search=${payload}`,
      method: 'get'
    });
    localStorage.setItem('manufacturers', JSON.stringify(res.data));
    yield put(setManufacturers(res.data));
  } catch (error) {}
}

export function* fetchSuppliers({ payload }) {
  try {
    const data = localStorage.getItem('suppliers');
    if (data) {
      yield put(setSuppliers(JSON.parse(data)));
    }
    const res = yield call(request, {
      url: `admin/suppliers?paginate=1000&search=${payload}`,
      method: 'get'
    });
    localStorage.setItem('suppliers', JSON.stringify(res.data));
    yield put(setSuppliers(res.data));
  } catch (error) {}
}

export function* fetchCategories({ payload }) {
  try {
    const data = localStorage.getItem('categories');
    if (data) {
      yield put(setCategories(JSON.parse(data)));
    }
    const res = yield call(request, {
      url: `admin/categories?paginate=1000&search=${payload}`,
      method: 'get'
    });
    localStorage.setItem('categories', JSON.stringify(res.data));

    yield put(setCategories(res.data));
  } catch (error) {}
}
export function* fetchCategoriesWithSub({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/categories?paginate=1000&search=${payload}&include=all_subcategories`,
      method: 'get'
    });

    yield put(setCategories(res.data));
  } catch (error) {}
}

export function* fetchDiscountsTypes() {
  try {
    const res = yield call(request, {
      url: `admin/discount-types`,
      method: 'get'
    });
    yield put(setDiscountsTypes(res.data));
  } catch (error) {}
}

export function* fetchStickers() {
  try {
    const res = yield call(request, {
      url: `admin/catalog-sticker-types`,
      method: 'get'
    });
    yield put(setStickerForProducts(res.data));
  } catch (error) {}
}

export function* fetchAttributes({ payload }) {
  try {
    const res = yield call(request, {
      url: `admin/attributes?paginate=1000&search=${payload}`,
      method: 'get'
    });
    yield put(setAttributes(res.data));
  } catch (error) {}
}

export default function* selectsSaga() {
  yield takeLatest(types.FETCH_BRANDS, fetchBrands);
  yield takeLatest(types.FETCH_MANUFACTURERS, fetchManufactures);
  yield takeLatest(types.FETCH_SUPPLIERS, fetchSuppliers);
  yield takeLatest(types.FETCH_CATEGORIES, fetchCategories);
  yield takeLatest(types.FETCH_CATEGORIES_WITH_SUB, fetchCategoriesWithSub);
  yield takeLatest(types.FETCH_DISCOUNTS_TYPES, fetchDiscountsTypes);
  yield takeLatest(types.FETCH_STICKER_FOR_PRODUCTS, fetchStickers);
  yield takeLatest(types.FETCH_ATTRIBUTES, fetchAttributes);
}
