import React from 'react';
import Spinner from 'assets/images/Spinner.gif';

const Loader = () => (
  <div className="root_loader">
    <img src={Spinner} alt="Jakov Sitem" />
  </div>
);

export default Loader;
