import React, { useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { inlineBG } from 'helpers/inlineBackground';
import useOutsideClick from 'helpers/useOutsideClick';
import Dropdown from './Dropdown';
import Language from './Language';
import Notifications from './Notifications';
import UserAvatar from 'assets/images/users/default.png';
import ShowNotificationModal from 'cms/Notifications/Modal';

const NavBar = ({ user, statusSidebar, toggleSideBar }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const dropdown = useRef(null);

  useOutsideClick(dropdown, () => {
    if (isVisible) toggle();
  });

  const toggle = () => setIsVisible(!isVisible);

  return (
    <>
      {selected && (
        <ShowNotificationModal
          data={selected}
          close={() => setSelected(null)}
        />
      )}
      <div className="cms_nav_bar_wrapper">
        <div className="cms_nav_bar_holder">
          <IconButton
            className="icon_button menu_bar"
            size="small"
            onClick={() => toggleSideBar(!statusSidebar)}
          >
            <Menu />
          </IconButton>
          <div className="align_row">
            <Notifications setSelected={setSelected} />
            <Language />
            <div className="info">
              <h3>{user && user.name}</h3>
              <div
                onClick={toggle}
                className="avatar"
                style={inlineBG(
                  user && user.image_thumb ? user.image_thumb : UserAvatar
                )}
              />
              {isVisible && <Dropdown dropdown={dropdown} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
