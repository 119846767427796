import React from 'react';
import { useDispatch } from 'react-redux';
import { addFavoriteItem } from 'views/WishList/redux/actions';
import {
  ButtonIncDec,
  HeartIcon,
  QuantityWrapper,
  QuantityWrapperBody
} from './styles';
const Quantity = ({ quantity, setQuantity, product_id, isMobile }) => {
  const dispatch = useDispatch();
  const handleDecrement = () => quantity > 1 && setQuantity(quantity - 1);
  const handleIncrement = () => setQuantity(quantity + 1);
  const addToWishList = () =>
    dispatch(addFavoriteItem({ product_id, quantity }));
  return (
    <QuantityWrapperBody className="quantity_row">
      <p className="text">Izaberite količinu</p>
      <QuantityWrapper className="quantity">
        <ButtonIncDec className="button" onClick={handleDecrement}>
          -
        </ButtonIncDec>
        <div className="selected">{quantity}</div>
        <ButtonIncDec className="button" onClick={handleIncrement}>
          +
        </ButtonIncDec>
      </QuantityWrapper>

      {/* <HeartIcon onClick={addToWishList} /> */}
    </QuantityWrapperBody>
  );
};
export default Quantity;
