const KEY = '[APP_BAR]';

const types = {
  FETCH_CATEGORIES: `${KEY} FETCH_CATEGORIES`,
  SET_CATEGORIES: `${KEY} SET_CATEGORIES`,

  SET_SEARCH_RESULTS: `${KEY} SET_SEARCH_RESULTS`,
  SEARCH: `${KEY} SEARCH`,
  FIRST_CLICK: `${KEY} FIRST_CLICK`
};

export default types;
