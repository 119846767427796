import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAppBar = state => state.appBar || initialState;

const makeSelectCategories = () =>
  createSelector(selectAppBar, substate => substate.appBarCategories);

const makeSelectSearchResults = () =>
  createSelector(selectAppBar, substate => substate.searchResults);

export { makeSelectCategories, makeSelectSearchResults };
