import React, { useRef } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';

import { photoBreakpoints } from 'helpers/photoBreakpoints';
import { formatCurrency } from 'utils/formatCurrency';
import useOutsideClick from 'helpers/useOutsideClick';
import Image from 'components/Image/Loadable';
import routes from 'routes';
import ImagePlaceholder from 'assets/images/mini_logo.webp';

const ShoppingCartDropdown = ({
  isVisible,
  toggle,
  data,
  handleUpdate,
  handleDelete
}) => {
  const dropdown = useRef(null);

  useOutsideClick(dropdown, () => {
    if (isVisible) toggle(false);
  });

  const handleIncrement = ({ id, quantity }) => handleUpdate(id, quantity + 1);
  const handleDecrement = ({ id, quantity }) => {
    if (quantity > 0) handleUpdate(id, quantity - 1);
  };
  const handleChange = (e, id) => {
    let value = e.target.value;
    handleUpdate(id, value);
  };

  return (
    <div
      className="shopping_cart_dropdown_inner"
      ref={dropdown}
      onClick={e => e.stopPropagation()}
    >
      <div className="shopping_cart_dropdown_outer">
        <div className="header">
          <h3>Korpa</h3>
        </div>
        <div className="holder">
          {data.items.length ? (
            <>
              {_.sortBy(data.items, ['id']).map(item => (
                <div className="item" key={item.id}>
                  <div className="content">
                    <div className="name_and_quantity">
                      <div className="photo">
                        {item.product.main_image ? (
                          <Image
                            src={photoBreakpoints(item.product.main_image)}
                          />
                        ) : (
                          <Image
                            src={ImagePlaceholder}
                            className="img_placeholder"
                          />
                        )}
                      </div>
                      <Link
                        to={`/kategorije/${item.product.mainCategory[0].slug}/proizvodi/${item.product.slug}`}
                        onClick={toggle}
                      >
                        {item.name.length < 40
                          ? item.name
                          : item.name.slice(0, 40) + '...'}
                      </Link>
                    </div>
                    <div className="input">
                      <div onClick={() => handleDecrement(item)}>-</div>
                      <input
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={e => handleChange(e, item.id)}
                      />
                      <div onClick={() => handleIncrement(item)}>+</div>
                    </div>
                    <p className="price">
                      {formatCurrency(item.price)}
                      {/* <span>RSD</span> */}
                    </p>
                    <Close
                      onClick={() => handleDelete(item.id)}
                      className="remove"
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className="no_result">Nema proizvoda u korpi.</p>
          )}
        </div>
        <div className="footer">
          <div className="row">
            <p>Ukupno:</p>
            <span>{formatCurrency(data.total)}</span>
          </div>
          <div className="links">
            <Link onClick={toggle} to={routes.SHOPPING_CART}>
              Pregled korpe
            </Link>
            <Link onClick={toggle} to={routes.COMPLETION_PURCHASE}>
              Završite kupovinu
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartDropdown;
