import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { productAvailableAgain } from '../redux/actions';

const OutOfStock = ({ product_id }) => {
  const { web_product_slug } = useParams();
  const dispatch = useDispatch();

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      product_id,
      product_url: window.location.href,
      email: values.email
    };

    dispatch(productAvailableAgain(web_product_slug, payload, resetForm));
  };

  return (
    <div className="product_out_of_stock">
      <h3>Proizvod nije na stanju.</h3>
      <p>
        Unesite e-mail adresu na kojoj želite da budete obavešteni kada proizvod
        bude dostupan.
      </p>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, { resetForm }) =>
          handleSubmit(values, { resetForm })
        }
      >
        <Form>
          <Field type="email" name="email" placeholder="E-email" required />
          <button type="submit">Pošalji</button>
        </Form>
      </Formik>
    </div>
  );
};

export default OutOfStock;
