import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { BsArrowRight } from 'react-icons/bs';
import Subcategories2 from './Subcategories2';
import { toggle } from 'helpers/beautifulDnd';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Subcategories1 = ({ category, close }) => {
  const [opened, setOpened] = useState([]);
  const { push } = useHistory();

  const handleClick = item => {
    push(`/kategorije/${item.slug}`);
    close();
    const body = document.querySelector('body');
    const mobile_bar = document.querySelector('.mobile_bar');
    body.removeAttribute('style');
    mobile_bar.removeAttribute('style');
  };

  return (
    <>
      {category.subcategories.map(subcategory => (
        <div key={subcategory.id}>
          <div className="item_holder level_2">
            {subcategory.id == opened ? (
              <div>
                {console.log(subcategory)}
                <p onClick={() => handleClick(subcategory)}>
                  {subcategory.name}
                </p>
                <span onClick={() => toggle(subcategory.id, opened, setOpened)}>
                  <IoIosArrowUp />
                </span>
              </div>
            ) : (
              <div>
                <p onClick={() => handleClick(subcategory)}>
                  {subcategory.name}
                </p>
                <span onClick={() => toggle(subcategory.id, opened, setOpened)}>
                  <IoIosArrowDown />
                </span>
              </div>
            )}
            {/* <p>{subcategory.name}</p>
            <span onClick={() => handleClick(subcategory)}>
              <AiOutlineArrowDown />
            </span> */}
          </div>
          {opened.includes(subcategory.id) && (
            <Subcategories2 subcategory={subcategory} close={close} />
          )}
        </div>
      ))}
    </>
  );
};

export default Subcategories1;
