const classNames = require('classnames');

export const activeClass = (defClass, isActive) =>
  classNames(defClass, {
    active: isActive
  });

export const activeLink = (location, path) =>
  classNames({
    active: location === path
  });

export const activeLinkIncludes = (location, path) =>
  classNames({
    active: location.includes(path)
  });
