import { defineMessages } from 'react-intl';

export const scope = 'cms_selects';

export default defineMessages({
  choose_action_type: {
    id: `${scope}.choose_action_type`,
    defaultMessage: 'Izaberi tip akcije'
  },
  choose_sticker_type: {
    id: `${scope}.choose_sticker_type`,
    defaultMessage: 'Izaberi tip nalepnice'
  },
  action_type: {
    id: `${scope}.action_type`,
    defaultMessage: 'Tip Akcije'
  },
  search_placeholder: {
    id: `${scope}.search_placeholder`,
    defaultMessage: 'Pretraži'
  },
  no_result: {
    id: `${scope}.no_result`,
    defaultMessage: 'Nema rezultata o pretragi.'
  },
  choose_supplier: {
    id: `${scope}.choose_supplier`,
    defaultMessage: 'Izaberi Dobavljača'
  },
  choose_brand: {
    id: `${scope}.choose_brand`,
    defaultMessage: 'Izaberi brend'
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Dobavljači'
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Dobavljač'
  },
  choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Izaberi'
  },
  choose_categories: {
    id: `${scope}.choose_categories`,
    defaultMessage: 'Izaberi Kategorije'
  },
  categories: {
    id: `${scope}.categories`,
    defaultMessage: 'Kategorije'
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Kategorija'
  },
  choose_brand: {
    id: `${scope}.choose_brand`,
    defaultMessage: 'Izaberi Brend'
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: 'Brend'
  },
  choose_attribute: {
    id: `${scope}.choose_attribute`,
    defaultMessage: 'Izaberi Atribut'
  },
  attribute: {
    id: `${scope}.attribute`,
    defaultMessage: 'Atribut'
  },
  choose_attribute_value: {
    id: `${scope}.choose_attribute_value`,
    defaultMessage: 'Izaberi Vrednost atributa'
  }
});
