import React from 'react';
import { useDispatch } from 'react-redux';
import { FiHeart } from 'react-icons/all';

import { addFavoriteItem } from 'views/WishList/redux/actions';

const FavoriteIcon = ({ product_id, quantity }) => {
  const dispatch = useDispatch();

  const addToWishList = () =>
    dispatch(addFavoriteItem({ product_id, quantity }));

  return <FiHeart className="favorite_icon" onClick={addToWishList} />;
};

export default FavoriteIcon;
