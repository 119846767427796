import React from 'react';
import Image from 'components/Image/Loadable';
import { formatCurrency } from 'utils/formatCurrency';
import { Link } from '@material-ui/core';
import PopularProducts from './Products/Popular';
import SearchedProducts from './Products/SearchResult';
import { useEffect } from 'react';

const Products = ({ data, closeModal }) => {
  useEffect(() => {
    console.log(data);
  });
  return (
    <>
      {Object.keys(data.search).length ? (
        <>
          <div className="product_wrapper">
            <SearchedProducts data={data.search} />
          </div>
        </>
      ) : (
        <p className="products_empty">Nema rezultata o pretrazi.</p>
      )}
    </>
  );
};

export default Products;
