import React from 'react';
import CheckboxCore from '@material-ui/core/Checkbox';

const Checkbox = ({ checked, onClick }) => (
  <CheckboxCore
    color="primary"
    size="small"
    checked={checked}
    onClick={onClick}
  />
);

export default Checkbox;
