import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDeclaration } from '../redux/actions';
import { makeSelectDeclaration } from '../redux/selectors';

const Item = ({ label, value }) => (
  <div className="row">
    <div className="cell">{label}</div>
    <div className="cell">{value}</div>
  </div>
);

const Declaration = ({ product }) => {
  const dispatch = useDispatch();
  const data = useSelector(makeSelectDeclaration());

  useEffect(() => {
    if (product.mainCategory && !data) {
      dispatch(getDeclaration(product.mainCategory[0].slug));
    }
  }, [dispatch, product, data]);

  return (
    <div className="declaration_tab">
      {data && product && (
        <div className="declaration_table">
          <Item label="Model" value={product.name} />
          <Item label="Naziv i vrsta robe" value={data.name} />
          <Item label="Uvoznik" value={product.supplier && product.supplier.name} />
          <Item label="Zemlja porekla" value={product.country_of_origin || data.country_of_origin} />
          <Item label="Prava potrošača" value={data.consumer_rights} />
        </div>
      )}
    </div>
  );
};

export default Declaration;
