import TableCell from 'cms/components/Table/TableCell';
import React, { useState } from 'react';
import { FaCheck, FaLink } from 'react-icons/fa';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const CopyCell = ({ url }) => {
  const [copy, setCopy] = useState(true);
  const handleClick = async event => {
    setCopy(false);
    await delay(1500);
    setCopy(true);
  };
  return (
    <TableCell
      title="Klikni da kopiraš"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        navigator.clipboard.writeText(url);
        handleClick();
      }}
    >
      <p
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        children="a"
      >
        {' '}
        {copy ? <FaLink /> : <FaCheck />} {copy ? url : 'Kopirano'}
      </p>
    </TableCell>
  );
};

export default CopyCell;
