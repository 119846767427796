import React, { useState, useEffect } from 'react';
import { getItem } from 'utils/localStorage';

const Prices = ({ product }) => {
  const [activePrice, setActivePrice] = useState(false);
  const { retail_price, discount, authenticated_price, web_price } = product;
  const saving_price = retail_price - activePrice;

  useEffect(() => {
    const token = getItem('token');

    if (discount) {
      setActivePrice(discount.price);
    } else {
      if (token) setActivePrice(authenticated_price);
      else setActivePrice(web_price);
    }
  }, [discount, authenticated_price, web_price]);

  return (
    <div className="product_prices">
      <div className="price retail_price">
        <p>MP cena:</p>
        <h3>{retail_price.toFixed(2)}</h3>
        <span>RSD</span>
      </div>
      <div className="align">
        <div className="price web_price">
          <p>Web cena:</p>
          <h3>{activePrice && activePrice.toFixed(2)}</h3>
          <span>RSD</span>
        </div>
        <p className="pdv">***PDV je uključen u cenu</p>
      </div>
      <div className="price saving_price">
        <p>Ušteda:</p>
        <h3>{saving_price.toFixed(2)}</h3>
        <span>RSD</span>
      </div>
    </div>
  );
};

export default Prices;
