import types from './constants';

export const getFavoriteItems = () => ({
  type: types.GET_FAVORITE_ITEMS
});

export const setFavoriteItems = payload => ({
  type: types.SET_FAVORITE_ITEMS,
  payload
});

export const addFavoriteItem = payload => ({
  type: types.ADD_FAVORITE_ITEM,
  payload
});

export const updateFavoriteItem = (product_id, quantity) => ({
  type: types.UPDATE_FAVORITE_ITEM,
  product_id,
  quantity
});

export const deleteFavoriteItem = product_id => ({
  type: types.DELETE_FAVORITE_ITEM,
  product_id
});

export const clearFavoriteItems = () => ({
  type: types.CLEAR_FAVORITE_ITEMS
});

export const passItemToCart = product_id => ({
  type: types.PASS_ITEM_TO_CART,
  product_id
});

export const passAllToCart = () => ({
  type: types.PASS_ALL_TO_CART
});
