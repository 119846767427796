import React, { useEffect } from 'react'
import {  useHistory } from 'react-router-dom'
const ScrollToTop=()=> {
    const history = useHistory()

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth', 
      });
    }, [history]);
  
    return null;
  }

  export default ScrollToTop