/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Choose from './components/Choose';
import img1 from '../../assets/images/cms_LandingPage/landing_page_1.jpeg';
import img2 from '../../assets/images/cms_LandingPage/landing_page_2.jpeg';
import img3 from '../../assets/images/cms_LandingPage/landing_page_3.jpeg';
import Editor from './Editor';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './redux/reducer';
import saga from './redux/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLandingPage, getModels } from './redux/actions';
import {
  makeSelectCurrentLandingPage,
  makeSelectModels
} from './redux/selectors';

const key = 'landing_page';

const EditLandingPage = ({ newBool, id }) => {
  const [option, setOption] = useState(0);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const page = useSelector(makeSelectCurrentLandingPage());

  useEffect(() => {
    if (!id) return;
    dispatch(getLandingPage(id));
  }, []);

  useEffect(() => {
    console.log(page);
    setOption(page?.landing_page_model?.id);
  }, [page]);

  return (
    <>
      <Editor option={option} newBool={newBool} page={page} />
    </>
  );
};

export default EditLandingPage;
