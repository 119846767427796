import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import {
  getNotifications,
  setNotifications,
  getUnreadNotifications,
  setUnreadNotifications
} from './actions';
import types from './constants';

const BASE_URL = 'admin/users';

export function* fetchNotifications({ user_id, params }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications`,
      method: 'get',
      params
    });

    yield put(setNotifications(res));
  } catch (error) {}
}

export function* fetchUnreadNotifications({ user_id }) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications/with-count`,
      method: 'get'
    });

    yield put(setUnreadNotifications(data));
  } catch (error) {}
}

export function* markNotificationAsRead({ user_id, notification_id }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications/${notification_id}`,
      method: 'patch'
    });

    yield put(getNotifications(user_id));
    yield put(getUnreadNotifications(user_id));
  } catch (error) {}
}

export function* markNotificationAsReadAll({ user_id }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications/read-all`,
      method: 'patch'
    });

    yield put(getNotifications(user_id));
    yield put(getUnreadNotifications(user_id));
  } catch (error) {}
}

export function* deleteNotification({ user_id, notification_id }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications/${notification_id}`,
      method: 'delete'
    });

    yield put(getNotifications(user_id));
    yield put(getUnreadNotifications(user_id));
  } catch (error) {}
}

export function* deleteAllNotifications({ user_id }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${user_id}/notifications/delete-all`,
      method: 'delete'
    });

    yield put(getNotifications(user_id));
    yield put(getUnreadNotifications(user_id));
  } catch (error) {}
}

export default function* notificationsSaga() {
  yield takeLatest(types.GET_NOTIFICATIONS, fetchNotifications);
  yield takeLatest(types.GET_UNREAD_NOTIFICATIONS, fetchUnreadNotifications);
  yield takeLatest(types.READ_NOTIFICATION, markNotificationAsRead);
  yield takeLatest(types.READ_ALL_NOTIFICATIONS, markNotificationAsReadAll);
  yield takeLatest(types.DELETE_NOTIFICATION, deleteNotification);
  yield takeLatest(types.DELETE_ALL_NOTIFICATIONS, deleteAllNotifications);
}
