import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectSearchResults } from 'components/AppBar/redux/selectors';

import Categories from './Categories';
import Products from './Products';
import Popular from './Popular';
import Banners from './Banners';
import { isArray } from 'lodash';

const Results = ({ open, close }) => {
  const data = useSelector(makeSelectSearchResults());

  return (
    <>
      {open && (
        <div className="results_dropdown_inner" onClick={close}>
          <div
            className="results_dropdown_outer"
            onClick={e => e.stopPropagation()}
          >
            {data && (
              <>
                <div className="left">
                  {data?.['results']?.['category']?.['items'] && (
                    <Categories
                      title={'Kategorije'}
                      data={data['results']['category']['items']}
                      closeModal={close}
                    />
                  )}

                  {data?.['results']?.['popular']?.['items']?.length > 0 && (
                    <Popular
                      title={'Popularne pretrage'}
                      data={data?.['results']?.['popular']?.['items']}
                      closeModal={close}
                    />
                  )}

                  {data?.['banners'] && (
                    <Banners data={data?.['banners']} closeModal={close} />
                  )}
                </div>
                <div className="right">
                  {data?.['banners'] && (
                    <Banners data={data?.['banners']} closeModal={close} />
                  )}
                  <Products
                    data={{
                      search: data?.['results']?.['product']?.['items']
                    }}
                    closeModal={close}
                  />{' '}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Results;
