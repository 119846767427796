import React, { useState, useEffect, useCallback } from 'react';
import { ExpandLess } from '@material-ui/icons';

const HelperIconsNav = () => {
  const [isVisisbleButton, setIsVisisbleButton] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 500) setIsVisisbleButton(true);
    else setIsVisisbleButton(false);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const pushToTop = () => {
    const body = document.querySelector('body');
    body.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isVisisbleButton && (
        <div className="scroll_to_top" onClick={pushToTop}>
          <ExpandLess />
        </div>
      )}
    </>
  );
};

export default HelperIconsNav;
