import { Link } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

const Banners = ({ data, closeModal, title }) => {
  const history = useHistory();
  const handleLink = el => {
    closeModal();
    history.push(`/pretraga-proizvoda/${el}`);
  };
  return (
    <>
      {data && (
        <div className="banners">
          {data?.['top'] &&
            data?.['top'].map(item => {
              return (
                <div
                  className="banner"
                  dangerouslySetInnerHTML={{
                    __html: item?.html
                  }}
                ></div>
              );
            })}
          {data?.['bottom'] &&
            data?.['bottom'].map(item => {
              return (
                <div
                  className="banner"
                  dangerouslySetInnerHTML={{
                    __html: item?.html
                  }}
                ></div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default Banners;
