import types from './constants';

// Get
export const getMargins = payload => ({
  type: types.GET_MARGINS,
  payload
});

export const setMargins = payload => ({
  type: types.SET_MARGINS,
  payload
});

// Get Single
export const getMargin = payload => ({
  type: types.GET_MARGIN,
  payload
});

export const setMargin = payload => ({
  type: types.SET_MARGIN,
  payload
});

export const clearMargin = () => ({
  type: types.CLEAR_MARGIN
});
export const setParams = payload => ({
  type: types.SET_PARAMS,
  payload
});
export const getMarginError = error => ({
  type: types.GET_MARGIN_ERROR,
  error
});

// Add
export const addMargin = (payload, setErrors) => ({
  type: types.ADD_MARGIN_REQUEST,
  payload,
  meta: { setErrors }
});

export const addMarginSuccess = () => ({
  type: types.ADD_MARGIN_SUCCESS
});

export const addMarginError = error => ({
  type: types.ADD_MARGIN_ERROR,
  error
});

// Update
export const updateMargin = (id, payload, setErrors) => ({
  type: types.UPDATE_MARGIN_REQUEST,
  id,
  payload,
  meta: { setErrors }
});

export const updateMarginSuccess = () => ({
  type: types.UPDATE_MARGIN_SUCCESS
});

export const updateMarginError = error => ({
  type: types.UPDATE_MARGIN_ERROR,
  error
});

// Delete
export const deleteMargin = id => ({
  type: types.DELETE_MARGIN_REQUEST,
  id
});

export const deleteMarginSuccess = () => ({
  type: types.DELETE_MARGIN_SUCCESS
});

// Params
export const setCurrentPage = payload => ({
  type: types.SET_CURRENT_PAGE,
  payload
});

export const setPerPage = payload => ({
  type: types.SET_PER_PAGE,
  payload
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});
