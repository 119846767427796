import produce from 'immer';
import types from './constants';

export const initialState = {
  appBarCategories: [],
  searchResults: null
};

/* eslint-disable default-case */
const appBarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_CATEGORIES:
        draft.appBarCategories = action.payload;
        break;
      case types.SET_SEARCH_RESULTS:
        draft.searchResults = action.payload;
        break;
    }
  });

export default appBarReducer;
