import React from 'react';
import NavLink from './NavLink';
import Dropdown from './Dropdown';
import messages from './messages';
import { invoices } from './groups';
import { RiFileList3Line } from 'react-icons/all';

const InvoicesDropdown = ({ closeSidebar }) => (
  <Dropdown icon={<RiFileList3Line />} label={messages.invoices}>
    {invoices.map((item, index) => (
      <NavLink
        key={index}
        name={item.name}
        icon={item.icon}
        route={item.url}
        role={item.role}
        module={item.module}
        closeSidebar={closeSidebar}
      />
    ))}
  </Dropdown>
);

export default InvoicesDropdown;
