import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from '../messages';

const DangerModal = ({ open, close, msg }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{formatMessage(msg)}</DialogTitle>
      <DialogActions>
        <Button className="button default" onClick={close}>
          {formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DangerModal;
