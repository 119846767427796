import types from './constants';

export const getProduct = slug => ({
  type: types.GET_PRODUCT,
  slug
});

export const setProduct = payload => ({
  type: types.SET_PRODUCT,
  payload
});

export const getProductError = error => ({
  type: types.GET_PRODUCT_ERROR,
  error
});

export const ratingProduct = (product_slug, payload, closeModal) => ({
  type: types.RATING_PRODUCT,
  product_slug,
  payload,
  closeModal
});

export const askQuestion = (slug, payload, resetForm) => ({
  type: types.ASK_QUESTION,
  slug,
  payload,
  meta: { resetForm }
});

export const getDeclaration = slug => ({
  type: types.GET_DECLARATION,
  slug
});

export const setDeclaration = payload => ({
  type: types.SET_DECLARATION,
  payload
});

export const productAvailableAgain = (slug, payload, resetForm) => ({
  type: types.PRODUCT_AVAILABLE_AGAIN,
  slug,
  payload,
  meta: {
    resetForm
  }
});

export const sendOffer = payload => ({
  type: types.SEND_OFFER,
  payload
});
