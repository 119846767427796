import { Button } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { HiLink } from 'react-icons/hi';

export default function InputLink({ label, setValue }) {
  const [toggle, setToggle] = useState(false);

  return (
    <p className="input_link">
      <p onClick={() => setToggle(!toggle)}>
        {label} <HiLink className="icon" />
      </p>
      <div className="link_form" style={toggle ? {} : { visibility: 'hidden' }}>
        <HiLink className="icon" />
        <input type="link" onChange={setValue} />
        <Button className="button" onClick={() => setToggle(false)}>
          Izmeni
        </Button>
      </div>
    </p>
  );
}
