import React, { useState } from 'react';
import { activeClass } from 'helpers/activeClass';
import UsefulLinksColumn from './UsefulLinksColumn';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const UsefulLinks = () => {
  const [visible, setVisible] = useState(false);

  return (
    <aside className="column useful_links">
      <div className="title_holder">
        {visible ? (
          <div>
            <h2 className="web">Korisni Linkovi</h2>
            <h2
              onClick={() => setVisible(prevState => !prevState)}
              className="mobile"
            >
              Korisni Linkovi <IoIosArrowUp />
            </h2>
            <div className="line" />
          </div>
        ) : (
          <div>
            <h2 className="web">Korisni Linkovi</h2>
            <h2
              onClick={() => setVisible(prevState => !prevState)}
              className="mobile"
            >
              Korisni Linkovi <IoIosArrowDown />
            </h2>
            <div className="line" />
          </div>
        )}
      </div>
      <div className="web_accordian">
        <UsefulLinksColumn />
      </div>
      <div className={activeClass('mobile_accordian', visible)}>
        <UsefulLinksColumn />
      </div>
    </aside>
  );
};

export default UsefulLinks;
