/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { HiOutlinePlusCircle, HiOutlineX } from 'react-icons/hi';
import Products from './Products';
import { Modal } from '@material-ui/core';
import ProductsDropdown from 'cms/Banners/CreateBanner/ProductsDropdown';

export default function Preview({ data, changeData, edit }) {
  const [products, setProducts] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [initial, setInitial] = useState(false);

  const removeProduct = index => {
    let it = products[index];
    if (it) {
      changeProducts(products.filter(el => el?.id !== it?.id));
    }
  };

  useEffect(() => {
    setProducts(data);
  }, [data]);

  function changeProducts(array) {
    setProducts(array);
    changeData(array);
  }

  return (
    <div className="body_preview">
      <Products products={products} remove={removeProduct} />

      <div className="add" onClick={() => setToggle(true)}>
        <HiOutlinePlusCircle className="icon" />
      </div>

      {toggle && (
        <div className="add_modal">
          <div className="form">
            <HiOutlineX
              className="close"
              onClick={() => {
                setToggle(false);
              }}
            />

            <div className="choose">
              <ProductsDropdown value={products} setValue={changeProducts} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
