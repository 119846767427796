import React from 'react';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import messages from '../messages';

const Buttons = ({ setValues, setParams, close }) => {
  const { formatMessage } = useIntl();

  const resetForm = () => {
    setValues({});
    setParams({});
  };

  return (
    <div
      className="fixed_buttons mt-10"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Button
        style={{ width: '30%' }}
        className="button default"
        onClick={close}
        fullWidth
      >
        {formatMessage(messages.back)}
      </Button>
      <Button
        style={{ width: '30%' }}
        className="button danger"
        onClick={resetForm}
      >
        {formatMessage(messages.remove)}
      </Button>
      <Button style={{ width: '30%' }} type="submit" className="button">
        {formatMessage(messages.apply)}
      </Button>
    </div>
  );
};

export default Buttons;
