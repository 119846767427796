import { defineMessages } from 'react-intl';

export const scope = 'global';

export default defineMessages({
  server_error: {
    id: `${scope}.server_error`,
    defaultMessage: 'Došlo je do greške na serveru pokušajte ponovo.'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Izbriši'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Izmeni'
  },
  back_link: {
    id: `${scope}.back_link`,
    defaultMessage: 'Nazad'
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Polje je obavezno'
  },
  unauthorized: {
    id: `${scope}.unauthorized`,
    defaultMessage: 'Unauthorized'
  }
});
