import { Button } from '@material-ui/core';
import React, { useState } from 'react';

const Modal = ({ open, client, setOpen }) => {
  return (
    <>
      {open ? (
        <div className="api_client_modal">
          <div>
            <Button className="close_button" onClick={() => setOpen(false)}>
              X
            </Button>
            <p>
              <snap>#{client.id}</snap>
              <b>{client.name}</b>
              <snap
                style={
                  client.active ? { color: 'lightgreen' } : { color: 'gray' }
                }
              >
                {client.active ? 'Aktivan' : 'Neaktivan'}
              </snap>
            </p>
            <div>
              {client?.categoriesAndPercentage.map((item, index) => {
                return (
                  <p>
                    {item.name}
                    <span className="percentage"> {item.percentage}%</span>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Modal;
