import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@material-ui/core';
import { useEffect } from 'react';

function CategorySelect({ categories, setCategories, value }) {
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    try {
      if (value) {
        const initialSelectedCategories = categories.filter(category =>
          value.includes(category.id)
        );
        setSelectedCategories(initialSelectedCategories);
      }
    } catch (err) {
      console.log(err);
    }
  }, [categories, value]);

  const [searchTerm, setSearchTerm] = useState('');
  const [toggle, setToggle] = useState(false);

  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function changeCategories(array) {
    console.log(array);
    setSelectedCategories(array);
    setCategories(array?.map(el => el?.id));
  }

  return (
    <div className="choose">
      <div className="selected">
        {selectedCategories.map(category => (
          <div
            key={category.id}
            className="selected_item"
            style={{
              display: 'inline-block',
              border: '1px solid black',
              padding: '5px',
              margin: '5px'
            }}
          >
            {category.name}
            <div
              className="remove"
              onClick={() =>
                changeCategories(
                  selectedCategories.filter(el => el?.id !== category?.id)
                )
              }
              style={{
                display: 'inline-block',
                marginLeft: '5px',
                cursor: 'pointer'
              }}
            >
              X
            </div>
          </div>
        ))}
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setToggle(true)}
        style={{ backgroundColor: 'black' }}
      >
        Dodaj kategorije
      </Button>

      {toggle && (
        <div className="add_modal">
          <div className="form">
            <div className="choose">
              <div className="search">
                <TextField
                  label="Search Categories"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>

              <div className="options" style={{ cursor: 'pointer' }}>
                {filteredCategories.map(category => (
                  <div
                    key={category.id}
                    onClick={() => {
                      if (
                        !selectedCategories.find(el => el?.id === category?.id)
                      ) {
                        changeCategories([...selectedCategories, category]);
                      }
                    }}
                    style={
                      selectedCategories.find(el => el?.id === category?.id)
                        ? { backgroundColor: 'black', color: 'white' }
                        : {}
                    }
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            </div>

            <div className="actions">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setToggle(false)}
                style={{ backgroundColor: 'black' }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CategorySelect;
