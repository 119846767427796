import React from 'react';
import { Close } from '@material-ui/icons';
import Search from '../MiddleBar/Search';
import { CgMenu } from 'react-icons/cg';

const MobileSearchDialog = ({ close, setSearchResults, toggleNavbar }) => {
  const closeAll = () => {
    setSearchResults(false);
    close();
  };

  return (
    <div className="mobile_search_dialog_wrap">
      <div className="mobile_search_dialog_holder">
        {/* <Close className="close_icon" onClick={closeAll} /> */}
        <CgMenu className="menu" onClick={toggleNavbar} />
        <Search setSearchResults={setSearchResults} />
      </div>
    </div>
  );
};

export default MobileSearchDialog;
