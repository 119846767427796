/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Image from 'components/Image/Loadable';
import { activeClass } from 'helpers/activeClass';
import { photoBreakpoints } from 'helpers/photoBreakpoints';
import ImagePlaceholder from 'assets/images/mini_logo.webp';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import PreviewModal from './PreviewModal';
import { ImageWrapper, SearchIcon } from './styles';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import AtionImg from 'assets/images/akcija.webp';
import AgentImg from 'assets/images/agent.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useIsMobileDevice from 'helpers/isMobileDevice';

SwiperCore.use([Navigation]);

const CustomImg = styled(Image)`
  width: ${props => props.width || '50px'};
`;

const Photos = ({ images, alt, discount }) => {
  const [activePhoto, setActivePhoto] = useState(null);

  const storageUrl = `https://api.ecommerce.smartofficemanager.com/storage/`;

  const isMobileDevice = useIsMobileDevice();

  const discountImg = discount?.sticker
    ? `${storageUrl}${discount.sticker}`
    : null;
  {
    /**
console.log(
    '🚀 ~ file: Photos.js ~ line 13 ~ Photos ~ activePhoto',
    activePhoto
  ); */
  }

  useEffect(() => {
    console.log(activePhoto);
  }, [activePhoto]);
  useEffect(() => {
    setActivePhoto(images.find(img => img.main));
  }, [images]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);

  const showPrevImage = () => {
    if (activeIndex > 0) setActiveIndex(current => current - 1);
    else setActiveIndex(images.length - 1);
  };

  const showNextImage = () => {
    if (images.length - 1 > activeIndex) {
      setActiveIndex(current => current + 1);
      setActivePhoto(images[activeIndex + 1]);
    } else {
      setActiveIndex(0);
      setActivePhoto(images[0]);
    }
  };
  const handlePreview = () => {
    setToggleModal(true);
  };
  const handleClose = () => {
    setToggleModal(false);
  };

  return (
    <div className="product_photos">
      <div className="other_photos">
        <Swiper
          direction={isMobileDevice ? 'horizontal' : 'vertical'}
          // navigation
          slidesPerView={3}
          spaceBetween={10}
        >
          {activePhoto && images.length
            ? images.map(img => {
                return (
                  <SwiperSlide key={img.id}>
                    <div className="image-holder">
                      <Image
                        title={alt}
                        alt={alt}
                        //src={photoBreakpoints(img)}
                        src={img.large}
                        onClick={() => {
                          setActivePhoto(img);
                        }}
                      />
                    </div>
                  </SwiperSlide>
                  // <div
                  //   key={img.id}
                  //   className={activeClass('photo', img === activePhoto)}
                  // >
                  //   <Helmet>
                  //     <meta property="og:image" content={img.large} />
                  //     <meta property="og:image:width" content="860" />
                  //     <meta property="og:image:height" content="609" />
                  //   </Helmet>
                  //   <Image
                  //     title={alt}
                  //     alt={alt}
                  //     src={photoBreakpoints(img)}
                  //     onClick={() => {
                  //       setActivePhoto(img);
                  //     }}
                  //   />
                  // </div>
                );
              })
            : null}
        </Swiper>
      </div>
      <div className="active_photo">
        {/* <BsChevronLeft className="arrow left" onClick={showPrevImage} />
        <BsChevronRight className="arrow right" onClick={showNextImage} /> */}
        <SearchIcon onClick={handlePreview} />
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '5px',
            margin: 'auto'
          }}
        >
          {discount && !discount.type ? (
            <CustomImg
              width={isMobileDevice ? '65px' : '85px'}
              src={AtionImg}
              className="discount_icon"
            />
          ) : null}
          {discountImg && (
            <CustomImg
              width={isMobileDevice ? '65px' : '85px'}
              src={discountImg}
              className="discount_icon"
            />
          )}
        </div>
        {activePhoto ? (
          <ImageWrapper key={JSON.stringify(activePhoto)}>
            <Image
              title={alt}
              alt={alt}
              className="mobile_photo"
              onClick={handlePreview}
              src={
                window.innerWidth > 1024
                  ? photoBreakpoints(activePhoto)
                  : activePhoto.small
              }
            />
          </ImageWrapper>
        ) : (
          <div className="img_placeholder">
            <Image src={ImagePlaceholder} />
          </div>
        )}
      </div>

      {toggleModal && (
        <PreviewModal
          handleClose={handleClose}
          img={activePhoto}
          images={images}
          setActivePhoto={setActivePhoto}
        />
      )}
      <div className="call_center">
        <div className="call_center_left">
          <Image src={AgentImg} alt="Call Centar Agent"></Image>
        </div>
        <div className="call_center_right">
          <p className="heading">Call centar podrška</p>
          <p>Imate pitanje u vezi sa ovim proizvodom?</p>

          <p className="bottom">
            Pitajte <span style={{ color: 'blue' }}>online</span> ili nas
            pozovite <br />
            <span>
              <a href="tel:+381184155230">018 41 55 230</a>,{' '}
              <a href="tel:+381184155230">018 41 55 230</a>,{' '}
              <a href="tel:+381184155230">018 41 55 230</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Photos;
