import React from 'react';
import moment from 'moment';

const Answers = ({ questions }) => (
  <div className="comments_inner">
    {questions.length ? (
      <div className="answers_inner">
        {questions.map(item => (
          <div className="answer_group" key={item.id}>
            <div className="answer_item">
              <div className="row">
                <p className="name">{item.name}</p>
                <p className="date">{moment(item.created_at).format('LL')}</p>
              </div>
              <div className="answer">
                <p>{item.question}</p>
              </div>
            </div>
            <div className="sub_answer">
              <p>{item.answer}</p>
              <div className="row">
                <p className="name">{item.user.name}</p>
                <p className="date">{moment(item.created_at).format('LL')}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="empty_list">Trenutno nema pitanja o ovom proizvodu</div>
    )}
  </div>
);

export default Answers;
