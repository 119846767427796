import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectVisibleOnsite = state => state.visibleOnSite || initialState;

const makeSelectProducts = () =>
  createSelector(selectVisibleOnsite, substate => substate.products);

const makeSelectParams = () =>
  createSelector(selectVisibleOnsite, substate => substate.params);

export { makeSelectProducts, makeSelectParams };
