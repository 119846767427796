import { Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import FirstEditor from './components/FirstEditor';
import SecondEditor from './components/SecondEditor';
import ThirdEditor from './components/ThirdEditor';

export default function Editor({ option, newBool, page }) {
  return (
    <Card
      className="landing_page_editor"
      style={option === 0 ? { visibility: 'hidden' } : {}}
    >
      {page ? (
        <>
          {option === 1 && <FirstEditor newBool={newBool} page={page} />}
          {option === 2 && <SecondEditor newBool={newBool} page={page} />}
          {option === 3 && <ThirdEditor newBool={newBool} page={page} />}
        </>
      ) : (
        <>
          {' '}
          {option === 2 && <FirstEditor newBool={newBool} page={page} />}
          {option === 1 && <SecondEditor newBool={newBool} page={page} />}
          {option === 3 && <ThirdEditor newBool={newBool} page={page} />}
        </>
      )}
    </Card>
  );
}
