import React from 'react';
import DangerModal from '../../DangerModal';
import messages from '../../../messages';

const UpdateAttributes = ({
  params,
  open,
  close,
  openFilterMenu,
  setCategoriesSelectVisible
}) => {
  const handleClose = () => {
    if (!params.category) {
      openFilterMenu();
      setCategoriesSelectVisible(true);
    }
    close();
  };

  return (
    <DangerModal
      open={open}
      close={handleClose}
      msg={messages.mass_attr_validation}
    />
  );
};

export default UpdateAttributes;
