import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, ...rest }) => (
  <img src={src} alt={alt} loading="lazy" {...rest} />
);

Image.defaultProps = {
  alt: 'Jakov Sitem'
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

export default Image;
