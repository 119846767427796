import React from 'react';
import { useIntl } from 'react-intl';
import Checkbox from 'cms/components/Checkbox';
import TableCell from './TableCell';
import DeletedModal from '../DeletedModal';

const TableHelper = ({
  tableName,
  tableHeaders,
  children,
  modalText,
  open,
  close,
  handleDelete,
  checkboxValue,
  setCheckboxValue
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={`table_holder table-${tableName}`}>
      <div className="header">
        {checkboxValue !== undefined && (
          <TableCell>
            <Checkbox checked={checkboxValue} onClick={setCheckboxValue} />
          </TableCell>
        )}
        {tableHeaders.map((item, i) => (
          <TableCell key={i}>
            {typeof item === 'object' ? formatMessage(item) : item}
          </TableCell>
        ))}
      </div>
      <div className="content">{children}</div>
      {modalText && (
        <DeletedModal
          label={modalText}
          open={open}
          close={close}
          remove={handleDelete}
        />
      )}
    </div>
  );
};

export default TableHelper;
