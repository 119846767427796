import React from 'react';
import {
  GoPackage,
  BsListUl,
  BiError,
  BiSitemap,
  BiImport
} from 'react-icons/all';

const Icons = ({ notification_type }) => {
  const renderIcon = () => {
    switch (notification_type) {
      case 'PricelistUploaded':
        return (
          <div className="icon success">
            <GoPackage />
          </div>
        );
      case 'CategoriesUploaded':
        return (
          <div className="icon success">
            <BsListUl />
          </div>
        );
      case 'CategoriesNotMapped':
        return (
          <div className="icon success">
            <BiSitemap />
          </div>
        );
      case 'ImportFinished':
        return (
          <div className="icon success">
            <BiImport />
          </div>
        );
      default:
        return (
          <div className="icon error">
            <BiError />
          </div>
        );
    }
  };

  return renderIcon();
};

export default Icons;
