import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const PageTitle = ({ name }) => {
  const { pathname } = useLocation();
  const isAdminPanel = pathname.includes('admin-panel') && '- Admin Panel';

  return (
    <Helmet>
      <title>
        {name} {isAdminPanel} | Jakov Sistem
      </title>
    </Helmet>
  );
};
export default PageTitle;
