import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { makeSelectModules, makeSelectUser } from 'containers/App/selectors';
import { classes } from './classes';
import { getItem } from 'utils/localStorage';

import * as users from 'cms/Users/redux/actions';
import * as products from 'cms/Products/redux/actions';
import * as visibleOnSite from 'cms/VisibleOnSite/redux/actions';
import * as categories from 'cms/Categories/redux/actions';
import * as recommendedPrice from 'cms/RecommendedPrice/redux/actions';
import * as orders from 'cms/Orders/redux/actions';
import * as webMargins from 'cms/WebMargins/redux/actions';
import * as authDiscounts from 'cms/AuthDiscounts/redux/actions';
import * as news from 'cms/News/redux/actions';
import * as banners from 'cms/Banners/redux/actions';
import * as ratings from 'cms/Ratings/redux/actions';

const NavLink = ({ route, icon, name, module, closeSidebar, role }) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const modules = useSelector(makeSelectModules());
  const user = useSelector(makeSelectUser());
  const activeModules = getItem('modules') || modules;

  const handleClick = () => {
    closeSidebar();

    dispatch(users.deleteParams());
    dispatch(products.deleteParams());
    dispatch(visibleOnSite.deleteParams());
    dispatch(categories.clearParams());
    dispatch(recommendedPrice.deleteParams());
    dispatch(orders.removeParams());
    dispatch(webMargins.deleteParams());
    dispatch(authDiscounts.deleteParams());
    dispatch(news.deleteParams());
    dispatch(banners.deleteParams());
    dispatch(ratings.setParams({}));
  };

  const Item = () => (
    <li className={classes(pathname, route)}>
      <Link to={route} onClick={handleClick}>
        {icon}
        <span>{formatMessage(name)}</span>
      </Link>
    </li>
  );

  const rendering = () => {
    if (module) {
      if (activeModules && activeModules.includes(module)) {
        if (user && user.roles.includes('super-admin')) {
          return <Item />;
        } else {
          if (!role) return <Item />;
          else {
            if (user && user.permissions.includes(role)) {
              return <Item />;
            }
          }
        }
      }
    } else {
      if (user && user.roles.includes('super-admin')) {
        return <Item />;
      } else {
        if (!role) return <Item />;
        else {
          if (user && user.permissions.includes(role)) {
            return <Item />;
          }
        }
      }
    }
  };

  return <>{rendering()}</>;
};

export default NavLink;
