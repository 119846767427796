import { Link } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from 'routes';

const Categories = ({ data, closeModal, title }) => {
  const history = useHistory();
  const handleLink = el => {
    closeModal();
    history.push(`/pretraga-proizvoda/${el}`);
  };
  return (
    <>
      {data && (
        <div className="group categories">
          <h3>{title}</h3>
          {data.map(item => {
            return (
              <p
                key={item?.id}
                className="text"
                dangerouslySetInnerHTML={{
                  __html: item?.highlighted_name
                    ? item?.highlighted_name
                    : item?.name
                }}
                onClick={() => {
                  handleLink(item?.name_urlencoded);
                }}
              ></p>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Categories;
