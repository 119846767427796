import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import routes from 'routes';
import { makeSelectUser } from 'containers/App/selectors';

const Dropdown = ({ close, logout }) => {
  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector(makeSelectUser());

  const handleLogout = () => {
    logout();
    close();
  };

  useEffect(() => {
    if (user) {
      let found = user.roles.find(el => el !== 'customer');

      if (found) setIsVisible(true);
      else setIsVisible(false);
    }
  }, [user]);

  return (
    <div className="is_auth_dropdown">
      {isVisible && (
        <Link to={routes.CMS_PRODUCTS} onClick={close}>
          Admin Panel
        </Link>
      )}
      <Link to={routes.MY_ACCOUNT_ACCOUNT_DETAILS} onClick={close}>
        Detalji naloga
      </Link>
      <Link to={routes.MY_ACCOUNT_ORDERS} onClick={close}>
        Porudžbine
      </Link>
      <Link to={routes.MY_ACCOUNT_ADDRESSES} onClick={close}>
        Adrese
      </Link>
      <button onClick={handleLogout}>Odjavi se</button>
    </div>
  );
};

export default Dropdown;
