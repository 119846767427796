/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { HiOutlinePlusCircle, HiPencil } from 'react-icons/hi';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Card, Checkbox, FormControlLabel } from '@material-ui/core';
import InputLink from './editor/InputLink';
import Preview from './editor/Preview';

import reducer from '../redux/reducer';
import saga from '../redux/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  submitLandingPage,
  updateLandingPage
} from '../redux/actions';
import { useEffect } from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import config from '../../../config';
import { makeSelectCategories, makeSelectSuccess } from '../redux/selectors';
import { toInteger } from 'lodash';
import CategorySelect from './editor/Categories';

const key = 'landing_page';

export default function SecondEditor({ newBool, page }) {
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const cats = useSelector(makeSelectCategories());

  const success = useSelector(makeSelectSuccess());

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  useEffect(() => {
    try {
      if (page?.categories_ids) {
        setCategories(page?.categories_ids?.map(el => el.id));
      }
    } catch {}
  }, [page]);

  useEffect(() => {
    if (success) {
      newBool(false);
    }
  }, [success]);

  return (
    <div className="form_third">
      <Formik
        initialValues={
          page && {
            name: page?.name,
            image: page?.banner,
            top_description:
              page?.top_description &&
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(page.top_description))
              ),
            bottom_description:
              page?.bottom_description &&
              EditorState.createWithContent(
                convertFromRaw(JSON.parse(page.bottom_description))
              )
          }
        }
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          try {
            const formData = new FormData();

            formData.append('name', values?.name);
            image && formData.append('banner', image);
            formData.append('landing_page_model_id', 3);
            values?.top_description &&
              formData.append(
                'top_description',
                JSON.stringify(
                  convertToRaw(values.top_description.getCurrentContent())
                )
              );
            values?.bottom_description &&
              formData.append(
                'bottom_description',
                JSON.stringify(
                  convertToRaw(values.bottom_description.getCurrentContent())
                )
              );

            categories
              ?.map(obj => obj)
              .forEach((id, index) => {
                formData.append(`categories_ids[${index}]`, id);
              });

            dispatch(
              !page
                ? submitLandingPage(formData)
                : updateLandingPage(page?.id, formData)
            );
          } catch {}
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <p>
              <snap>Početna strana </snap>/{' '}
              <input
                type="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <HiPencil color="#0787ea" />
            </p>

            <div className="content">
              <div className="header">
                <div className="dropzone">
                  {(image || page) && (
                    <img
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : !page
                          ? URL.createObjectURL(image)
                          : config.api.cdn + '/storage/' + values.image
                      }
                      alt="header_img"
                    />
                  )}
                  <input
                    className="image_drop"
                    type="file"
                    name="image"
                    title="Izaberi sliku"
                    onChange={data => {
                      setFieldValue('image', data?.target?.result);
                      setImage(data?.target?.files?.[0]);
                    }}
                  />
                  <label>
                    <p>Izaberi sliku</p>
                  </label>
                </div>
                <div className="editor_header">
                  <Editor
                    editorState={values.top_description}
                    onEditorStateChange={val =>
                      setFieldValue('top_description', val)
                    }
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    placeholder="Opis"
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'list',
                        'textAlign',
                        'history',
                        'link'
                      ]
                    }}
                  />
                </div>
              </div>

              <div className="categories">
                <CategorySelect
                  categories={cats}
                  setCategories={setCategories}
                  value={categories}
                />
              </div>
              <div className="body">
                <Editor
                  editorState={values.bottom_description}
                  onEditorStateChange={val =>
                    setFieldValue('bottom_description', val)
                  }
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  placeholder="Opis"
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'list',
                      'textAlign',
                      'history',
                      'link'
                    ]
                  }}
                />
              </div>
            </div>

            <button type="submit">Napravi</button>
          </form>
        )}
      </Formik>
    </div>
  );
}
