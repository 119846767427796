import React from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { toggle } from 'helpers/beautifulDnd';

const MobileTableExpandButton = ({ id, opened, setOpened }) => {
  const contain = opened.includes(id);
  const classes = contain ? 'icon_danger_color' : 'icon_success_color';
  const handleExpandToggle = e => {
    e.stopPropagation();
    toggle(id, opened, setOpened);
  };

  return (
    <div className="expand_button">
      <IconButton
        className={`icon_button ${classes}`}
        onClick={handleExpandToggle}
      >
        {contain ? <Remove /> : <Add />}
      </IconButton>
    </div>
  );
};

export default MobileTableExpandButton;
