const KEY = '[FAVORITE]';

const types = {
  GET_FAVORITE_ITEMS: `${KEY} GET_FAVORITE_ITEMS`,
  SET_FAVORITE_ITEMS: `${KEY} SET_FAVORITE_ITEMS`,

  ADD_FAVORITE_ITEM: `${KEY} ADD_FAVORITE_ITEM`,
  UPDATE_FAVORITE_ITEM: `${KEY} UPDATE_FAVORITE_ITEM`,
  DELETE_FAVORITE_ITEM: `${KEY} DELETE_FAVORITE_ITEM`,
  CLEAR_FAVORITE_ITEMS: `${KEY} CLEAR_FAVORITE_ITEMS`,

  PASS_ITEM_TO_CART: `${KEY} PASS_ITEM_TO_CART`,
  PASS_ALL_TO_CART: `${KEY} PASS_ALL_TO_CART`
};

export default types;
