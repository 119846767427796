import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdShoppingCart } from 'react-icons/all';

import { updateItem, deleteItem } from 'views/ShoppingCart/redux/actions';
import { makeSelectItems } from 'views/ShoppingCart/redux/selectors';
import ShoppingCartDropdown from './ShoppingCartDropdown';

const CartPart = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(current => !current);
  const dispatch = useDispatch();
  const data = useSelector(makeSelectItems());

  const handleDelete = useCallback((...args) => dispatch(deleteItem(...args)), [
    dispatch
  ]);
  const handleUpdate = useCallback((...args) => dispatch(updateItem(...args)), [
    dispatch
  ]);

  return (
    <div className="cart_part" onClick={toggle}>
      <div className="row">
        <div className="icon_holder">
          <MdShoppingCart />

          {data.items && data.items.length ? (
            <span>{data.items.length}</span>
          ) : null}
        </div>
      </div>
      <p>Korpa</p>
      {isVisible && data.items && (
        <ShoppingCartDropdown
          isVisible={isVisible}
          toggle={toggle}
          data={data}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default CartPart;
