import { defineMessages } from 'react-intl';

export const scope = 'components';

export default defineMessages({
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Ukupno'
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'proizvoda'
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'dobavljača'
  },
  clients: {
    id: `${scope}.clients`,
    defaultMessage: 'klijenata'
  }
});
