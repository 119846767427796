import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import routes from 'routes';

const Breadcrumb = ({ active, children }) => (
  <div className="breadcrumb_component">
    <div className="container">
      <nav className="breadcrumb_wrapper">
        <div>
          <Link to={routes.HOME}>
            Početna <span>{`  /  `}</span>
          </Link>
        </div>
        {children}
        <p className="active">{active}</p>
      </nav>
    </div>
  </div>
);

export default Breadcrumb;
