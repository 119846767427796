import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Close } from '@material-ui/icons';
import { activeClass } from 'helpers/activeClass';
import Image from 'components/Image/Loadable';
import { photoBreakpoints } from 'helpers/photoBreakpoints';
import { FaStopwatch } from 'react-icons/fa';

const zoom = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`;
const CustomImage = styled.img`
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  margin: auto;
  display: block;
  margin-top: 30px;
  animation-name: ${zoom};
  animation-duration: 0.6s;
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;
const ImageModal = styled.div`
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
`;
const Caption = styled.div`
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
  animation-name: ${zoom};
  animation-duration: 0.6s;
`;

const CustomCloseIcon = styled(Close)`
  width: 35px;
  color: white;
  position: absolute;
  right: 30px;
`;

const PreviewModal = ({ img, handleClose, images, setActivePhoto }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <ImageModal>
        <CustomCloseIcon onClick={handleClose} />
        <CustomImage
          style={loaded ? {} : { visibility: 'hidden' }}
          src={img.large}
          alt="Jakov Sitem"
          onLoad={() => {
            setLoaded(true);
            console.log('image loaded');
          }}
        />

        <FaStopwatch
          color="white"
          style={
            !loaded
              ? { margin: 'auto', width: '100%', position: 'absolute' }
              : { visibility: 'hidden' }
          }
        />
        <div className="other_photos_modal">
          {images.length
            ? images.map(image => {
                return (
                  <div
                    key={image.id}
                    className={activeClass('photo', image === img)}
                  >
                    <Image
                      src={photoBreakpoints(image)}
                      onClick={() => {
                        setLoaded(false);
                        setActivePhoto(image);
                      }}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </ImageModal>
    </>
  );
};

export default PreviewModal;
