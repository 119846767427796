import types from './constants';

export const getNews = params => ({
  type: types.GET_NEWS,
  params
});

export const setNews = payload => ({
  type: types.SET_NEWS,
  payload
});

export const getSingleNews = news_id => ({
  type: types.GET_SINGLE_NEWS,
  news_id
});

export const setSingleNews = payload => ({
  type: types.SET_SINGLE_NEWS,
  payload
});

export const getSingleNewsError = error => ({
  type: types.GET_SINGLE_NEWS_ERROR,
  error
});

export const createNews = (payload, setErrors) => ({
  type: types.CREATE_NEWS,
  payload,
  meta: { setErrors }
});

export const updateNews = (news_id, payload, setErrors) => ({
  type: types.UPDATE_NEWS,
  news_id,
  payload,
  meta: { setErrors }
});

export const deleteNews = (news_id, params) => ({
  type: types.DELETE_NEWS,
  news_id,
  params
});

export const updateImage = (news_id, image) => ({
  type: types.UPDATE_IMAGE,
  news_id,
  image
});

export const setParams = params => ({
  type: types.SET_PARAMS,
  params
});

export const deleteParams = () => ({
  type: types.DELETE_PARAMS
});
