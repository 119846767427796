import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Cookies from 'universal-cookie';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import {
  fetchAuthenticatedUser,
  getModules,
  getBanners,
  setCartCookie,
  setWishListCookie
} from './actions';
import { makeSelectToken, makeSelectUser } from './selectors';
import { getItem } from 'utils/localStorage';
import Notifier from 'containers/Notifier';
import Wrap from './Wrap';
import { generateId } from 'helpers/generateId';

import shoppingCartReducer from 'views/ShoppingCart/redux/reducer';
import shoppingCartSaga from 'views/ShoppingCart/redux/saga';
import { getItems } from 'views/ShoppingCart/redux/actions';

import wishListReducer from 'views/WishList/redux/reducer';
import wishListSaga from 'views/WishList/redux/saga';
import { getFavoriteItems } from 'views/WishList/redux/actions';
import {
  getNotifications,
  getUnreadNotifications
} from 'cms/Notifications/redux/actions';

import notificationsReducer from 'cms/Notifications/redux/reducer';
import notificationsSaga from 'cms/Notifications/redux/saga';
import echo_instance from 'utils/EchoInstance';
import AcceptCookie from 'components/AcceptCookie';

const key = 'app';
const shoppingCartKey = 'shoppingCart';
const wishListKey = 'favorite';
const notificationsKey = 'cms_notifications';

window.io = require('socket.io-client');

const App = () => {
  useInjectSaga({ key, saga });

  useInjectReducer({ key: notificationsKey, reducer: notificationsReducer });
  useInjectSaga({ key: notificationsKey, saga: notificationsSaga });

  useInjectReducer({ key: shoppingCartKey, reducer: shoppingCartReducer });
  useInjectSaga({ key: shoppingCartKey, saga: shoppingCartSaga });

  useInjectReducer({ key: wishListKey, reducer: wishListReducer });
  useInjectSaga({ key: wishListKey, saga: wishListSaga });

  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const { pathname } = useLocation();

  useEffect(() => {
    if (token && user) {
      echo_instance(token)
        .private(`App.User.${user.id}`)
        .notification(() => {
          const audio = document.getElementById('notification_audio');
          if (audio) audio.play();
          dispatch(getNotifications(user.id, {}));
          dispatch(getUnreadNotifications(user.id));
        });
    }
  }, [token, user, dispatch]);

  useEffect(() => {
    if (token) dispatch(fetchAuthenticatedUser());
  }, [token, dispatch]);

  useEffect(() => {
    if (!token && !pathname.includes('admin')) {
      const cookies = new Cookies();
      const cookieUserId = cookies.get('user_id');
      const cookieFavoriteUserId = cookies.get('favorite_user_id');

      if (!cookieUserId) {
        const user_id = generateId();
        cookies.set('user_id', user_id);
        dispatch(setCartCookie(user_id));
      }
      if (!cookieFavoriteUserId) {
        const favorite_user_id = generateId();
        cookies.set('favorite_user_id', favorite_user_id);
        dispatch(setWishListCookie(favorite_user_id));
      }

      dispatch(getItems());
      dispatch(getFavoriteItems());
    }
  }, [token, pathname, dispatch]);

  useEffect(() => {
    if (!getItem('modules')) dispatch(getModules());
  }, [dispatch]);

  useEffect(() => {
    if (!pathname.includes('admin')) dispatch(getBanners());
  }, [dispatch, pathname]);

  return (
    <HelmetProvider>
      <SnackbarProvider maxSnack={10}>
        <Helmet>
          <title>Jakov – Vaš dom</title>

          {pathname === '/' && (
            <>
              <link rel="canonical" href="https://www.jakov.rs/" />
              <meta
                name="description"
                content="Kupite Online sigurno i lako! U ponudi laptop i desktop računari, televizori, bela tehnika, mali kućni aparati, telefoni, alati, sport. Jakov – Vaš dom"
              />
              <meta
                property="og:title"
                content="Jakov – Vaš dom"
              />
              <meta
                name="og:description"
                content="Kupite Online sigurno i lako! U ponudi laptop i desktop računari, televizori, bela tehnika, mali kućni aparati, telefoni, alati, sport. Jakov – Vaš dom"
              />     
              <meta property="og:url" content={window.location.href} />

            </>
          )}
        </Helmet>
        <Wrap user={user} />
        <Notifier />
        <AcceptCookie />
      </SnackbarProvider>
    </HelmetProvider>
  );
};

export default App;
