import React from 'react';
import { useIntl } from 'react-intl';
import { FaCheck } from 'react-icons/fa';
import { activeClass } from 'helpers/activeClass';
import Range from './Range';
import DiscountTypes from './DiscountTypes';
import DateRange from './DateRange';
import messages from '../messages';

const Discounts = ({ params, setParams }) => {
  const { formatMessage } = useIntl();

  const toggleActive = () => {
    if (params.discount) {
      const { discount: tmp, ...rest } = params;
      setParams(rest);
    } else {
      setParams({ ...params, discount: 1 });
    }
  };

  return (
    <div className="group discounts_group">
      <h3>{formatMessage(messages.discounts)}</h3>
      <div className="form_checkbox" onClick={toggleActive}>
        <div className={activeClass('box', params.discount === 1)}>
          <span>
            <FaCheck />
          </span>
        </div>
        <label>{formatMessage(messages.included)}</label>
      </div>
      <DateRange params={params} setParams={setParams} />
      <DiscountTypes params={params} setParams={setParams} />
      <Range
        params={params}
        setParams={setParams}
        name="discount_price"
        discount={true}
      />
    </div>
  );
};

export default Discounts;
