import types from './constants';

export const fetchBrands = (payload, paginate = 10) => ({
  type: types.FETCH_BRANDS,
  payload,
  paginate
});

export const setBrands = payload => ({
  type: types.SET_BRANDS,
  payload
});

export const fetchManufacturers = (payload, paginate = 10) => ({
  type: types.FETCH_MANUFACTURERS,
  payload,
  paginate
});

export const setManufacturers = payload => ({
  type: types.SET_MANUFACTURERS,
  payload
});

export const fetchSuppliers = payload => ({
  type: types.FETCH_SUPPLIERS,
  payload
});

export const setSuppliers = payload => ({
  type: types.SET_SUPPLIERS,
  payload
});

export const fetchCategories = payload => ({
  type: types.FETCH_CATEGORIES,
  payload
});

export const fetchCategoriesWithSub = payload => ({
  type: types.FETCH_CATEGORIES_WITH_SUB,
  payload
});

export const setCategories = payload => ({
  type: types.SET_CATEGORIES,
  payload
});

export const fetchDiscountsTypes = () => ({
  type: types.FETCH_DISCOUNTS_TYPES
});

export const fetchStickerForProducts = () => ({
  type: types.FETCH_STICKER_FOR_PRODUCTS
});

export const setDiscountsTypes = payload => ({
  type: types.SET_DISCOUNTS_TYPES,
  payload
});

export const setStickerForProducts = payload => ({
  type: types.SET_STICKER_FOR_PRODUCTS,
  payload
});
export const fetchAttributes = payload => ({
  type: types.FETCH_ATTRIBUTES,
  payload
});

export const setAttributes = payload => ({
  type: types.SET_ATTRIBUTES,
  payload
});
