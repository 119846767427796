import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaShippingFast,
  GoTools,
  GrMapLocation,
  BsArrowRepeat
} from 'react-icons/all';
import routes from 'routes';
import Image from 'components/Image';
import DeliveryImage from '../../../assets/images/delivery.png';
import CreditImage from '../../../assets/images/credit.png';
import VatImage from '../../../assets/images/VAT.png';
import ServiceImage from '../../../assets/images/service-home-icon.png';

const IconsNav = () => (
  <section className="icons_nav_wrap">
    <div className="container">
      <div className="icons_nav_holder">
        <Link to={routes.TERMS_AND_PRICES_OF_DELIVERY} className="item">
          <Image src={DeliveryImage} alt="Delivery Image"></Image>
          <div className="details">
            <h3>Besplatna Isporuka na teritoriji Srbije</h3>
          </div>
        </Link>
        <Link
          to={`${routes.TERMS_AND_PRICES_OF_DELIVERY}#shipment_tracking`}
          className="item"
        >
          <Image src={CreditImage} alt="Credit Image"></Image>
          <div className="details">
            <h3>Prati svoju proudžbinu</h3>
          </div>
        </Link>
        <Link
          to={`${routes.TERMS_AND_CONDITIONS}#buyers_right`}
          className="item"
        >
          <Image src={VatImage} alt="Vat Image"></Image>
          <div className="details">
            <h3>Šta ako želim da vratim proizvod?</h3>
          </div>
        </Link>
        <Link to={`${routes.TERMS_AND_CONDITIONS}#delivery`} className="item">
          <Image src={ServiceImage} alt="Service Image"></Image>
          <div className="details">
            <h3>Šta u slučaju oštećenja robe?</h3>
          </div>
        </Link>
      </div>
    </div>
  </section>
);

export default IconsNav;
