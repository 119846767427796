import React from 'react';
import { sortBy } from 'lodash';

const Specifications = ({ onStock, attributes }) => (
  <div className="specifications">
    {onStock && attributes && attributes.length ? (
      <div className="list">
        {sortBy(attributes, ['order']).map(({ attribute, value }) => (
          <div className="item" key={attribute.id}>
            <div className="label">{attribute.name}</div>
            <div className="value">{value.value}</div>
          </div>
        ))}
      </div>
    ) : (
      <p className="list_is_empty">Ovaj uređaj trenutno nema specifikacije.</p>
    )}
  </div>
);

export default Specifications;
