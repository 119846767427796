import { Button, Typography } from '@material-ui/core';
import FormikField from 'components/FormikField';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

const Edit = ({ open, client, setOpen, update, categories }) => {
  const [initalObject, setInitialObject] = useState({});
  const handleSubmit = (values, { setErrors, resetForm }) => {
    const payload = {
      categories: { ...values }
    };

    console.log(payload);
    update(client.id, payload);
    setOpen(false);
  };

  useEffect(() => {
    let obj = {};

    if (!client?.categoriesAndPercentage) return;
    client.categoriesAndPercentage.forEach(cat => {
      obj[cat.id] = cat.percentage;
    });

    setInitialObject(obj);
  }, [client]);

  return (
    <>
      {open ? (
        <div className="api_client_edit">
          <div>
            <Button className="close_button" onClick={() => setOpen(false)}>
              X
            </Button>
            <p className="title">
              <snap>#{client.id}</snap>
              <b>{client.name}</b>
              <snap
                style={
                  client.active ? { color: 'lightgreen' } : { color: 'gray' }
                }
              >
                {client.active ? 'Aktivan' : 'Neaktivan'}
              </snap>
            </p>
            <Formik
              initialValues={initalObject}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setErrors, resetForm }) => {
                handleSubmit({ ...values }, { setErrors, resetForm });
              }}
              enableReinitialize={true}
            >
              {({ errors, touched, values, setFieldValue }) => {
                return (
                  <Form className="api_form">
                    <div style={{ width: '100%', maxHeight: '50vh' }}>
                      {categories.map((cat, index) => {
                        return (
                          <div className="api_field">
                            <span>%</span>
                            <FormikField
                              name={cat.id}
                              errors={errors}
                              touched={touched}
                              type="number"
                              className="api_percentage"
                              placeholder="0"
                            />
                            <p>{cat.name}</p>
                          </div>
                        );
                      })}

                      <Button className="button" type="submit">
                        Izmeni
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Edit;
