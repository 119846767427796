const CryptoJS = require('crypto-js');

export const generateId = () => {
  let time = new Date().getTime();
  let randomNumber = getRandomArbitrary(100000000000000, 9999999999999999);
  let generated = `${time}cart${randomNumber}`;

  let hash = CryptoJS.SHA256(generated);
  let total = hash.toString(CryptoJS.enc.Base64);

  return total;
};

const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;
