import types from './constants';

export const getSuppliers = payload => {
  return ({
  type: types.GET_SUPPLIERS,
  payload
})};


export const setSuppliers = payload => {
  return ({
  type: types.SET_SUPPLIERS,
  payload
})};


